
import Vue from 'vue';

import { MapOf, mapReduce, FAGlyphs, FAGlyphsArray, isLightColor, randomRgb, wildcard2regex, FAGlyphsArraySorted, sortedPresetColors } from '@/Util';

import VueSlider from 'vue-slider-component';
import DSDDColorPicker from '@/components/ColorPicker.vue';
// import MapComponent from '@/components/Map.vue';
import MapSettings from '@/components/MapSettings.vue';
import {Point} from '@/components/Map.vue';
import SelectPicker, { Option } from '@/components/SelectPicker.vue';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';

import Keyword from './Keyword.vue';
import { ResultKeyword, ResultVisual } from '../../scopes/SearchScopeTypes';
import { mapVisuals, saveUndoVisuals, getUndoVisuals } from '@/scopes/SearchScopeVisuals';

type RenderData = ResultKeyword&ResultVisual&{inBottom: boolean, active: boolean|undefined}&(typeof FAGlyphsArray)[number];

export default Vue.extend({
	components: {
		Keyword,
		VueSlider,
		// Map: MapComponent,
		MapSettings,
		SelectPicker,
		DSDDColorPicker
	},
	props: {
		...SearchScopeChildProps,
	},
	data: () => ({
		showFilteredWords: true,

		// Filters
		filterVisibilityState: null as null|boolean,
		filterGroupState: null as null|boolean,
		filterVariantState: null as null|boolean,
		regexMode: 'wildcard'  as 'regex'|'wildcard',
		showOnlyWordsMatchingRegex: '',
		showOnlyWordsMatchingConceptRegex: '',
		showOnlyWordsWithOccurances: [1, 1000],

		// Interface/selection
		wordsInBottom: {} as {[wordId: string]: boolean},

		// Assignment
		newGroup: '',
		newColor: sortedPresetColors[0],
		showColorPicker: false,
		newIcon: 'circle-solid',
		newIconSize: 1.0,

		iscopying: false,

		// drag
		draggedItem: null as null|{
			keyword: RenderData|null,
			group: string|null,
			draggedFromBottom: boolean
		},
	}),
	computed: {
		// tslint:disable-next-line
		// NOTE: don't just use Object.values() on this.resultVisuals! Visuals map may contain entries without corresponding keyword. Also order of arrays must be equal!
		visualArray(): ResultVisual[] {
			const vs = this.resultVisuals!;
			return this.resultKeywordsArray ? this.resultKeywordsArray.map(kw => vs[kw.id]) : [];
		},
		/** Do not do highlighting in our preview map */
		visualsWithoutHighlight(): MapOf<ResultVisual> {
			const r: MapOf<ResultVisual> = {};
			for (const k in this.resultVisuals) {
				r[k] = {...this.resultVisuals[k], isHighlighted: false };
			}
			return r;
		},
		renderGroups(): {
			top: Array<{group: string|null, renderData: RenderData[]}>,
			bottom: Array<{group: string|null, renderData: RenderData[]}>,
			activeInTop: RenderData[],
			activeInBottom: RenderData[]
		} {
			// output
			const top = new Map<string|null, RenderData[]>();
			const bottom = new Map<string|null, RenderData[]>();
			const activeInTop: RenderData[] = [];
			const activeInBottom: RenderData[] = [];

			// input
			const visuals = this.resultVisuals!;
			const keywords = this.resultKeywords!;
			const inBottoms = this.wordsInBottom;
			const actives = this.keywordsMatchingFilters;

			(this.resultKeywordsByGroup || []).forEach(g => {
				g.keywords.forEach(keywordId => {
					const keyword = keywords[keywordId];
					const visual = visuals[keywordId];
					const icon = FAGlyphs[visual.icon];

					const inBottom = inBottoms[keywordId];
					const active = actives[keywordId];
					const group = visual.group;

					const renderData = {...keyword, ...visual, inBottom, active, ...icon};

					// words in bottom: always show
					// active words (those matched by filters): always show
					// showFilteredWords?: always show
					if (inBottom || active || this.showFilteredWords) {
						const m = inBottom ? bottom : top;
						const e = m.has(group) ? m.get(group)! : (m.set(group, []) && m.get(group)!);
						e.push(renderData);
					}
					if (inBottom) { activeInBottom.push(renderData); } // tslint:disable-next-line
					else if (active) { activeInTop.push(renderData); }
				});
			});

			const topEntries = [...top.entries()].map(([group, renderData]) => ({group, renderData}));
			const bottomEntries = [...bottom.entries()].map(([group, renderData]) => ({group, renderData}));

			// sort returned groups so that the null group is at the back
			return {
				top: topEntries.sort((a, b) => a.group === null ? 1 : b.group === null ? -1 : 0),
				bottom: bottomEntries.sort((a, b) => a.group === null ? 1 : b.group === null ? -1 : 0),
				activeInTop,
				activeInBottom
			};
		},

		// ==== Some input for setting values ====
		newColorIsLight(): boolean { return isLightColor(this.newColor); },

		// ==== Some input variables required to render available options in filters ====
		presetColors(): string[] { return sortedPresetColors; },
		allIcons(): Option[] {
			return FAGlyphsArraySorted.map(v => ({
				label: `<span class="fa fa-fw ${v.class}"></span> ${v.name}`,
				value: v.name
			}));
		},
		distinctOccurancesForSlider(): number[]|undefined { return this.resultKeywords && [...new Set(Object.values(this.resultKeywords).map(kw => kw.count))].sort((a, b) => a - b); },
		minOccurances(): number { return this.distinctOccurancesForSlider ? this.distinctOccurancesForSlider[0] : 1; },
		maxOccurances(): number { return this.distinctOccurancesForSlider ? this.distinctOccurancesForSlider[this.distinctOccurancesForSlider.length - 1] : 1; },
		usedGroups(): string[] {
			return this.resultKeywordsByGroup ? this.resultKeywordsByGroup.map(g => g.group).filter((g): g is string => !!g).sort() : [];
		},
		conceptRegexOptions(): string[] {
			const allDisplays = new Set((this.resultConceptsArray || []).map(e => e.display));
			const re = this.regexMode === 'wildcard' ? /[\?\*]/g : /[\[\\\^\$\.\|\?\*\+\(\)]/g;
			return [...allDisplays].map(d => d.toLowerCase().replace(re, '\\$&')).sort();
		},
		keywordRegexOptions(): string[] {
			const allDisplays = new Set((this.resultKeywordsArray || []).map(e => e.keyword_display));
			const re = this.regexMode === 'wildcard' ? /[\?\*]/g : /[\[\\\^\$\.\|\?\*\+\(\)]/g;
			return [...allDisplays].map(d => d.toLowerCase().replace(re, '\\$&')).sort();
		},

		// ==== Filter output ====

		/** The regexp if valid, false if cannot be parsed, undefined if no entered regex */
		compiledRegex(): RegExp|false|undefined {
			if (!this.showOnlyWordsMatchingRegex) { return undefined; }
			try {
				return new RegExp(this.regexMode === 'regex' ? this.showOnlyWordsMatchingRegex : wildcard2regex(this.showOnlyWordsMatchingRegex), 'i');
			} catch (e) { return false; }
		},
		compiledConceptRegex(): RegExp|false|undefined {
			if (!this.showOnlyWordsMatchingConceptRegex) { return undefined; }
			try {
				return new RegExp(this.regexMode === 'regex' ? this.showOnlyWordsMatchingConceptRegex : wildcard2regex(this.showOnlyWordsMatchingConceptRegex), 'i');
			} catch (e) { return false; }
		},
		keywordsMatchingGroupState(): undefined|boolean[] {
			if (this.filterGroupState != null) {
				return this.visualArray.map(e => !!e.group === this.filterGroupState);
			}
		},
		keywordsMatchingVisibilityState(): undefined|boolean[] {
			if (this.filterVisibilityState != null) {
				return this.visualArray.map(e => e.isVisible === this.filterVisibilityState);
			}
		},
		keywordsMatchingVariantState(): undefined|boolean[] {
			if (this.filterVariantState != null) {
				return (this.resultKeywordsArray || []).map(e => !!e.variants.length === this.filterVariantState);
			}
		},
		keywordsMatchingRegex(): undefined|boolean[] {
			if (this.compiledRegex) {
				const re = this.compiledRegex;
				return (this.resultKeywordsArray || []).map(e => re.test(e.keyword_display));
			}
		},
		keywordsMatchingConceptRegex(): undefined|boolean[] {
			if (this.compiledConceptRegex) {
				const re = this.compiledConceptRegex;
				return (this.resultKeywordsArray || []).map(e => re.test(e.concept_display));
			}
		},
		keywordsWithOccurances(): undefined|boolean[] {
			let [min, max] = this.showOnlyWordsWithOccurances;
			if (max < min) { const tmp = min; min = max; max = tmp; }
			if ((min > this.minOccurances || max < this.maxOccurances) && this.hasResults) {
				return (this.resultKeywordsArray || []).map(e => e.count >= min && e.count <= max);
			}
		},
		/**
		 * Several arrays of booleans.
		 * the boolean at index n indicates whether keyword n matches the filter for that array
		 * Say an array is for filter "filterGroupState", index 7 indicates whether or not resultKeywordsArray[7] matches the filter.
		 */
		activeFilters(): boolean[][] {
			return [
				this.keywordsMatchingGroupState,
				this.keywordsMatchingVisibilityState,
				this.keywordsMatchingVariantState,
				this.keywordsMatchingRegex,
				this.keywordsMatchingConceptRegex,
				this.keywordsWithOccurances
			].filter(v => v) as boolean[][]; // remove inactive filters, they return undefined instead of an array
		},
		/** List also contains keywords in bottom to prevent recalculating filters every time one word swaps sides */
		keywordsMatchingFilters(): MapOf<boolean> {
			const filters = this.activeFilters;
			if (filters.length) {
				return (this.resultKeywordsArray || []).reduce<MapOf<boolean>>((map, data, i) => {
					map[data.id] = filters.every((filterValues: boolean[]) => filterValues[i]);
					return map;
				}, {});
			} else {
				// Initially all keywords are active
				return (this.resultKeywordsArray || []).reduce<MapOf<boolean>>((map, data) => {
					map[data.id] = true;
					return map;
				}, {});
			}
		},

		previewPoints(): Point[] {
			if (!this.resultPointsArray) {
				return [];
			}

			const vs = this.resultVisuals!;
			const kws = this.resultKeywords!;
			const points = this.resultPointsArray;
			return points.reduce<Point[]>((acc, p) => {
				const kw = kws[p.keyword_id];
				const v = vs[p.keyword_id];

				if (v.isVisible) {
					acc.push({
						lng: p.lng,
						lat: p.lat,
						color: v.color,
						word: `${p.place}: ${kw.display} ${p.variant ? ` (${p.variant})` : ''}`, // hmm

						glyph: FAGlyphs[v.icon].glyph,
						font: FAGlyphs[v.icon].font,
						size: v.iconSize,
						highlight: false, // this is a preview map... don't do that here
						opacity: 1
					});
				}
				return acc;
			}, []);
		},
	},

	methods: {
		// invalidateSize() { (this.$refs.map as any).invalidateSize(); },

		moveKeywords(v: Array<ResultVisual&{inBottom: boolean}>, toBottom: boolean) {
			if (toBottom) {
				// store current settings for all words we didn't already have in the bottom
				const toStore = v.filter(e => !e.inBottom);
				saveUndoVisuals(this.searchWords, this.searchFilters, mapReduce(toStore, 'keyword_id'));
			}
			v.forEach(d => Vue.set(this.wordsInBottom, d.keyword_id, toBottom));
		},
		undoKeywords(v: RenderData[]) {
			const toRestore = getUndoVisuals(this.searchWords, this.searchFilters, v.map(e => e.id));
			this.$emit('visualsChanged', toRestore);
		},

		emitWithChanges(changed: Partial<ResultVisual>, items?: ResultVisual[]) {
			items = items || this.visualArray.filter(d => this.wordsInBottom[d.keyword_id]);
			const r = mapReduce(items, 'keyword_id', item => ({...item, ...changed}));
			this.$emit('visualsChanged', r);
		},
		handleAssignColor() { this.emitWithChanges({color: this.newColor}); },
		handleAssignIcon() { this.emitWithChanges({icon: this.newIcon || 'circle'}); },
		handleAssignIconSize() { this.emitWithChanges({iconSize: this.newIconSize}); },
		handleAssignGroup() { this.emitWithChanges({group: this.newGroup || null}); },
		handleHide() { this.emitWithChanges({isVisible: false}); },
		handleShow() { this.emitWithChanges({isVisible: true}); },
		handleRandomizeIcons() {
			const getRandom = () => this.allIcons[ Math.ceil(this.allIcons.length * Math.random()) - 1].value;
			this.emitWithChanges(Object.defineProperty({}, 'icon', { get: getRandom, enumerable: true }));
		},
		handleRandomizeColors() {
			const getRandom = () => this.presetColors[ Math.ceil(this.presetColors.length * Math.random()) - 1];
			this.emitWithChanges(Object.defineProperty({}, 'color', { get: getRandom, enumerable: true }));
		},
		handleClearFilters() {
			this.filterGroupState = null;
			this.filterVisibilityState = null;
			this.filterVariantState = null;
			this.showOnlyWordsMatchingRegex = '';
			this.showOnlyWordsWithOccurances = [this.minOccurances, this.maxOccurances];
			this.showOnlyWordsMatchingConceptRegex = '';
		},

		async handleReset() {
			if (!this.renderGroups.activeInBottom.length) {
				return;
			}
			const confirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t('groups.action_reset_all_warning_message').toString(), {
				okVariant: 'danger',
				title: this.$t('groups.action_reset_all_warning_title').toString(),
				okTitle: this.$t('groups.action_reset_all_warning_ok').toString(),
				cancelTitle: this.$t('groups.action_reset_all_warning_cancel').toString(),
			});

			if (confirmed) {
				const unaffected = mapReduce(this.visualArray.filter(a => !this.wordsInBottom[a.keyword_id]), 'keyword_id');
				this.$emit('visualsChanged', Object.assign(mapVisuals(this.resultKeywords!), unaffected));
			}
		},

		/** User started dragging a group or an individual keyword, register what is being dragged, and where the drag originated */
		handleDragstart(item: RenderData|string, draggedFromBottom: boolean) {
			this.draggedItem = {
				keyword: typeof item === 'string' ? null : item,
				group: typeof item === 'string' ? item : null,
				draggedFromBottom
			};
		},
		handleDrop(droppedInGroup: string|null, droppedInBottom: boolean) {
			const draggedItem = this.draggedItem;
			this.draggedItem = null;
			if (!draggedItem) { return; }

			const draggedAcrossSides = droppedInBottom !== draggedItem.draggedFromBottom;
			const draggedAcrossGroups = draggedItem.keyword
				? draggedItem.keyword.group !== droppedInGroup
				// When dragging a group, don't remove all keywords from the group whenever the user just wants to swap sides (or otherwise dropped in empty space)
				: droppedInGroup != null && droppedInGroup !== draggedItem.group;

			if (!draggedAcrossSides && !draggedAcrossGroups) { return; }

			// Dragging a keyword
			if (draggedItem.keyword) {
				// Use moveKeywords instead of directly setting, so we store the current word settings for undoing
				if (draggedAcrossSides) { this.moveKeywords([draggedItem.keyword], droppedInBottom); }
				// Only now apply group, so undo will also roll back the group assignment
				if (draggedAcrossGroups) { this.emitWithChanges({group: droppedInGroup}, [this.resultVisuals![draggedItem.keyword.id]]); }
			}

			// Dragging a group
			if (draggedItem.group) {
				const wordsInBottom = this.wordsInBottom;
				// Get all keywords in the group, on the side where the drag started (note: wordsInBottom often contains undefined for a word to signify false)
				const draggedKeywords = this.visualArray.filter(e => e.group === draggedItem!.group && !!wordsInBottom[e.keyword_id] === draggedItem!.draggedFromBottom);
				// Use moveKeywords instead of directly setting, so we store the current word settings for undoing
				if (draggedAcrossSides) { this.moveKeywords(draggedKeywords.map(e => ({...e, inBottom: !!wordsInBottom[e.keyword_id]})), droppedInBottom); }
				// Only now apply group, so undo will also roll back the group assignment
				if (draggedAcrossGroups) { this.emitWithChanges({group: droppedInGroup}, draggedKeywords); }
			}
		},

		copy(keyword: RenderData) {
			this.newGroup = keyword.group || '';
			this.newColor = keyword.color;
			this.newIcon = keyword.icon;
			this.newIconSize = keyword.iconSize;

			this.emitWithChanges({
				icon: this.newIcon,
				iconSize: this.newIconSize,
				group: this.newGroup || null, // empty string must degenerate into null
				color: this.newColor
			});
			// this.handleAssignGroup();
			// this.handleAssignColor();
			// this.handleAssignIcon();
			// this.handleAssignIconSize();

			this.iscopying = false;
		},
		randomRgb,
	},
	watch: {
		resultKeywords: {
			immediate: true,
			handler() {
				this.showColorPicker = false;
				this.wordsInBottom = {};
				this.draggedItem = null;
				this.handleClearFilters();
			}
		},
	},
});

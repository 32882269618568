// object-hash typings don't work with ts 3.9
// @ts-ignore
import ObjectHash from 'object-hash';
import { ResultKeyword, ResultVisual } from './SearchScopeTypes';
import { MapOf, mapReduce, FAGlyphsArray, presetColors } from '@/Util';

let activeVisualsKey: string|undefined;
let activeVisuals: MapOf<ResultVisual>|undefined;
// This allows the user to edit visuals in one browser tab/window and have them update live in another!
window.addEventListener('storage', e => {
	if (e.key === activeVisualsKey) {
		const storedVisuals = JSON.parse(localStorage.getItem(e.key) || '{}');
		activeVisuals = Object.assign(activeVisuals!, storedVisuals);
	}
});


function getStoreId(type: 'visuals'|'visuals/undo', terms: string[], filters: MapOf<string[]>) {
	return `${type}/${ObjectHash(terms.length ? terms.map(t => t.toLowerCase()).sort() : filters)}`;
}

function storeAccess(id: string) {
	const newState = (JSON.parse(localStorage.getItem('visuals/access') || '[]') as string[]).filter(s => s !== id).concat(id);
	localStorage.setItem('visuals/access', JSON.stringify(newState));
}

function clearOldestRecords() {
	const accessTimes = JSON.parse(localStorage.getItem('visuals/access') || '[]') as string[];
	const toRemove = accessTimes.splice(0, Math.floor(accessTimes.length / 10));
	toRemove.forEach(id => localStorage.removeItem(id));
	localStorage.setItem('visuals/access', JSON.stringify(accessTimes));
}

/**
 * NOTE: may return visual settings for results not in the result set.
 * In the past, perhaps visuals for a two-way search were stored, and this search may be from a one-way.
 * In this case, visual data from results in the two way result set not in the one way result set are loaded as well!
 */
export function getVisuals(terms: string[] = [], filters: MapOf<string[]> = {}, keywords: MapOf<ResultKeyword>): MapOf<ResultVisual> {
	const id = getStoreId('visuals', terms, filters);
	const rawVisuals = mapVisuals(keywords);

	const storedVisuals = JSON.parse(localStorage.getItem(id) || '{}');
	storeAccess(id);

	activeVisualsKey = id;
	activeVisuals = Object.assign(rawVisuals, storedVisuals);
	return activeVisuals!;
}

export function saveVisuals(terms: string[] = [], filters: MapOf<string[]> = {}, visuals: MapOf<ResultVisual>) {
	const id = getStoreId('visuals', terms, filters);
	try {
		localStorage.setItem(id, JSON.stringify(visuals));
		storeAccess(id);
	} catch (e) {
		clearOldestRecords();
		localStorage.setItem(id, JSON.stringify(visuals));
		storeAccess(id);
	}
}

export function saveUndoVisuals(terms: string[] = [], filters: MapOf<string[]> = {}, visuals: MapOf<ResultVisual>) {
	const id = getStoreId('visuals/undo', terms, filters);
	try {
		const withExistingBackups = Object.assign(JSON.parse(localStorage.getItem(id) || '{}'), visuals);
		localStorage.setItem(id, JSON.stringify(withExistingBackups));
		storeAccess(id);
	} catch (e) {
		clearOldestRecords();
		const withExistingBackups = Object.assign(JSON.parse(localStorage.getItem(id) || '{}'), visuals);
		localStorage.setItem(id, JSON.stringify(withExistingBackups));
		storeAccess(id);
	}
}

export function getUndoVisuals(terms: string[] = [], filters: MapOf<string[]> = {}, ids: string[]): MapOf<ResultVisual> {
	const id = getStoreId('visuals/undo', terms, filters);
	const storedVisuals = JSON.parse(localStorage.getItem(id) || '{}');

	const toReturn: MapOf<ResultVisual> = {};
	ids.forEach(visualId => toReturn[visualId] = storedVisuals[visualId]);
	storeAccess(id);
	return toReturn;
}

export function clearVisuals() {
	localStorage.clear();
}

const randomicons = FAGlyphsArray;
export function mapVisuals(keywords: MapOf<ResultKeyword>): MapOf<ResultVisual> {
	const arr = Object
	.values(keywords)
	.sort((a, b) => // sort the keywords by occurance count (if known) - sort alphabetically then
		a.count !== b.count
			? b.count - a.count
			: a.display.localeCompare(b.display)
	);

	return mapReduce(arr, 'id', (keyword, i) => ({
		keyword_id: keyword.id,
		icon: randomicons[i % randomicons.length].name,
		iconSize: 1.0,
		color: presetColors[i % presetColors.length],
		group: null,
		isVisible: true,
		isHighlighted: false,
	}));
}

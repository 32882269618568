
import Vue from 'vue';
import * as L from 'leaflet';
import 'leaflet-area-select';
import alea from 'seedrandom';

import { FAGlyphs } from '@/Util';

import 'leaflet/dist/leaflet.css';

import {CustomMarker} from './leaflet-extensions';

export type Point = {
	lng: number;
	lat: number;
	color: string;
	word: string;
	glyph: string;
	/** Font family for the glyph */
	font: string;
	highlight?: boolean;
	opacity?: number;
	/* icon size */
	size: number;
};

type InternalPoint = Point&{
	/** Render a line towards this position, used for points in unpacked clusters  */
	centroid?: L.LatLng,
	/** Render a background circle with this color */
	backgroundColor?: string;
};

type MapInfo = {
	map: L.Map;
	rect: L.Rectangle;
	iconLayer: L.LayerGroup<L.Path>;
	highlightLayer: L.LayerGroup<L.Path>;
};

export default Vue.extend({
	props: {
		points: {
			type: Array as () => Point[],
			default: () => [] as Point[]
		},
		bounds: Object as () => L.LatLngBounds,

		clusterEnabled: { type: Boolean, default: true },
		clusterCollapseEnabled: { type: Boolean, default: false },
		clusterSpread: { type: Number, default: 1 },
		/** Reduce occurances of the same word on the same location to 1, and show a count instead */
		clusterIdenticalPoints: { type: Boolean, default: true }
	},
	data: () => ({
		zoomLevel: 11,
		mapData: undefined as any as MapInfo, // filled during mounted(),

		highlightIntervalHandle: undefined as undefined|number,
		highlightStepInterval: 667, // ms
		highlightStep: 0,
		highlightSteps: [
			{size: 1.5, color: '#F00', glyph:  FAGlyphs['map-marker-alt'].glyph, font: FAGlyphs['map-marker-alt'].font, backgroundColor: undefined},
			{size: 1, color: undefined, glyph: undefined, font: undefined, backgroundColor: undefined}
		],

		cachedMarkers: [] as readonly CustomMarker[],
	}),
	computed: {
		center(): L.LatLng { return this.bounds.getCenter(); },
		markerSize(): number { return Math.max(5, Math.pow(Math.max(0, this.zoomLevel - 5), 1.7)); },

		_clusters(): {singularPoints: InternalPoint[], clusters: Array<{points: InternalPoint[], originalCount: number}>} {
			/** [key: location]: { [key: word]: point[] } */
			const separatedPoints: Map<string, Map<string, InternalPoint[]>> = new Map();

			// First group all points on two levels:
			for (const p of this.points) {
				// 1. By location primarily
				const key = p.lat.toFixed(4) + p.lng.toFixed(4);
				if (!separatedPoints.has(key)) { separatedPoints.set(key, new Map()); }
				const pointsAtPos = separatedPoints.get(key)!;

				// 2. And by their word contents secondly
				if (!pointsAtPos.has(p.word)) { pointsAtPos.set(p.word, [p]); }
				else { pointsAtPos.get(p.word)!.push(p); }
			}

			// output variables
			const singularPoints: InternalPoint[] = [];
			const clusters: Array<{points: InternalPoint[], originalCount: number}> = [];

			// Go over all location groups, and collapse identical points (if clusterIdenticalPoints is true)
			// After collapsing points, output the resulting point(s) as either a singularPoint or a cluster entry
			// so that the rendering code can render the appropriate symbol or cluster
			Array
			.from(separatedPoints.values())
			.map(e => Array.from(e.values()))
			.forEach((allPointsAtThisLocationByWord) => {
				if (this.clusterIdenticalPoints && this.clusterEnabled) {
					for (let i = 0; i < allPointsAtThisLocationByWord.length ; ++i) { // tslint:disable-line
						const identicalPoints: InternalPoint[] = allPointsAtThisLocationByWord[i];
						const count = identicalPoints.length;
						// all points identical at this point, length always >= 1. So just take the first one
						// NOTE: make a copy, or we'd be editing the value of one of our props (when reassigning .word)!
						const pointToKeep = Object.assign({}, identicalPoints[0]);
						pointToKeep.word = pointToKeep.word + ` (${count.toLocaleString()}x)`; // append the count to the label of this point

						(allPointsAtThisLocationByWord as any)[i] = pointToKeep; // dirty but efficient - re-use the array
					}
					// now then, we have condensed all points at this location.
					// check how many are left, and push into the clusters or push into the singularPoints
					if (allPointsAtThisLocationByWord.length === 1) {
						// note: we reused the array, above, so it contains points instead of point[] now
						singularPoints.push((allPointsAtThisLocationByWord as any as InternalPoint[])[0]); 
					} else {
						clusters.push({
							points: allPointsAtThisLocationByWord as any as InternalPoint[], // note: we reused the array, above
							originalCount: allPointsAtThisLocationByWord.length
						});
					}
				} else {
					// No collapsing, check whether we're dealing with one ore more than one point
					// and push into the appropriate array
					if (allPointsAtThisLocationByWord.length > 1 || allPointsAtThisLocationByWord[0].length > 1) {
						const flat = allPointsAtThisLocationByWord.flat();
						clusters.push({
							points: flat,
							originalCount: flat.length
						});
					} else {
						singularPoints.push(allPointsAtThisLocationByWord[0][0]);
					}
				}
			});

			return {singularPoints, clusters};
		},

		_highlightedPoints(): readonly InternalPoint[] { return this._points.filter(p => p.highlight); },
		_points(): readonly InternalPoint[] {
			const {singularPoints, clusters} = this._clusters;
			if (clusters.length && !this.mapData) {
				return []; // we need the map to exist because we need its projection settings to convert pixel offsets in lat/lng coords - re-run after mount
			}

			if (!this.clusterEnabled) {
				return singularPoints.concat(clusters.map(c => c.points).flat());
			}

			const baseSize = this.markerSize;
			const map = this.mapData.map;
			const zoom = this.zoomLevel;
			const startExpandingAtZoom = 6;
			// Scale cluster dithering range based on zoom level
			// The radius in which we spread points in a cluster gets larger as the user zooms in (larges as in in pixels, it gets smaller in coordinate space)
			// These are rather arbitrary values that do about what we want.
			// Just multiply the surface area of the circle in which we spread the points based on the zoom level (higher zoom [1-20] = more zoomed in = larger circle)
			const circleSurfaceMultiplier =  Math.pow(Math.max(1, zoom - startExpandingAtZoom) / (20 - startExpandingAtZoom), 1.3) * 1.5 * this.clusterSpread;
			// First expand small groups, then larger and larger as the user zooms in.
			// This just converts every zoom level into an arbitrary number (larger when the zoom is higher), groups smaller than this number are expanded.
			const maxGroupSizeToExpand = Math.max(2, Math.pow(Math.max(1, this.zoomLevel - startExpandingAtZoom), 1.7));

			// unpack clusters
			// Store points inide clusters, and points representing a "collapsed" cluster in this array
			// Since we the map renders the array linearly from front to back, points at the end render "on top"
			// Which is nice, as that way the central markers for a cluster always show up nicely on top (instead of partially obscured as is wont to happen when many points exist)
			const flattenedClusters: InternalPoint[] = [];

			// use a repeatable rng, so points end up in the same location every time.
			// (note: only works for the same zoom level, or when all clusters have been expanded already - but creating a new rng for every group is slow)
			// Though we could also just pregenerate n numbers where n = largest group size, and then reuse the same number for every group.
			const arng = alea('DSDD!');
			clusters.forEach((c) => {
				const expandCluster = (c.points.length <= maxGroupSizeToExpand) || !this.clusterCollapseEnabled;
				// zoomed in far enough to uncluster this one
				const centerOfCluster = L.latLng(c.points[0].lat, c.points[0].lng); // since all points are at the same location, just use the first point for this
				if (expandCluster) {
					const surface = c.points.reduce((sum, p) => p.size*p.size + sum, 5 /* always have some base level of surface area */) * baseSize * baseSize * circleSurfaceMultiplier;
					const radius = Math.sqrt(surface / Math.PI);

					// In screenspace
					const {x: centerOfClusterX, y: centerOfClusterY} = map.project(centerOfCluster, zoom);

					// Randomly distribute points in this cluster within the circle defined by radius and centre
					// tslint:disable-next-line
					for (let pi = 0; pi < c.points.length; ++pi) {
						const p = c.points[pi];

						// Credit: https://stackoverflow.com/a/5838991
						let a = arng();
						let b = arng();
						if (b < a) { const _ = b; b = a; a = _; }
						// In screenspace
						const xOffset = b*radius*Math.cos(2*Math.PI*a/b);
						const yOffset = b*radius*Math.sin(2*Math.PI*a/b);

						// Go back from screenspace to coordinate space
						const latlng = map.unproject([xOffset + centerOfClusterX, yOffset + centerOfClusterY], zoom);
						// write out object cloning - it's a couple times faster than Object.assign and is a relatively large bottleneck otherwise
						flattenedClusters.push({
							lng: latlng.lng,
							lat: latlng.lat,
							centroid: centerOfCluster,
							color: p.color,
							word: p.word,
							glyph: p.glyph,
							font: p.font,
							size: p.size,
							highlight: p.highlight,
							opacity: p.opacity,
							backgroundColor: p.backgroundColor,
						});
					}
					return;
				}

				// Alright, we're still rendering one point instead of the expanded cluster
				// check if this point should have a "generic" icon and color (because sub-points are heterogenous)
				// or - if all sub-points are the same - we should render the original symbol and color to represent this cluster
				const {uniform: renderAsOne} = c.points.reduce<{uniform: boolean, prev?: Point}>(({uniform, prev}, cur) => {
					uniform = prev ? (
						uniform &&
						prev.glyph === cur.glyph &&
						prev.color === cur.color &&
						prev.size === cur.size &&
						prev.highlight === cur.highlight
					) : true;
					return {uniform, prev: cur};
				}, {
					uniform: true,
					prev: undefined
				});

				const popup = c.points
				.map(p => `<span class="fas" style="text-shadow: 0.05rem 0.05rem rgba(0,0,0,0.5); color: ${p.color}; font-family: ${p.font};">${p.glyph}</span> ${p.word}`).join('<br>');

				if (renderAsOne)  { // add a single point to represent this cluster, using its original symbol and color settings
					flattenedClusters.push({
						lat: centerOfCluster.lat,
						lng: centerOfCluster.lng,
						centroid: centerOfCluster,
						word: popup,

						color: c.points[0].color,
						glyph: c.points[0].glyph,
						font: c.points[0].font,
						size: c.points[0].size,
						highlight: c.points[0].highlight,
						opacity: c.points[0].opacity,
						backgroundColor: c.points[0].backgroundColor,
					});
				} else { // add a point showing the amount of points in this cluster as text
					// show as generic placeholder because what to show is indeterminate
					flattenedClusters.push({
						color: '#666',
						backgroundColor: '#eef',
						lat: centerOfCluster.lat,
						lng: centerOfCluster.lng,
						glyph: c.originalCount.toString(10),
						font: c.points[0].font,
						size: 0.3 + 0.5 * Math.log2(c.originalCount),
						opacity: 0.98,
						word: popup,
						highlight: c.points.some(p => p.highlight)
					});
				}
			});

			// add cluster points to the back so they render on top
			return Object.freeze(singularPoints.concat(flattenedClusters));
		},
	},
	methods: {
		invalidateSize() { this.mapData.map.invalidateSize(); },
		/** Called just before map rerenders after ending zoom event - otherwise old stale points are rendered for like a single frame. */
		removeAndCacheMarkers() {
			const {iconLayer, highlightLayer} = this.mapData;
			this.cachedMarkers = Object.freeze([...iconLayer.getLayers(), ...highlightLayer.getLayers()]) as CustomMarker[];

			iconLayer.clearLayers().remove();
			highlightLayer.clearLayers().remove();
		},
		updatePoints() {
			const {map, iconLayer, highlightLayer} = this.mapData;
			const points = this._points;
			const baseSize = this.markerSize;

			// when we're not redrawing because of zoom, we still need to remove the old markerts
			const markers = this.cachedMarkers.concat(...iconLayer.getLayers() as any, ...highlightLayer.getLayers() as any);
			// in case of zoom these are already empty, that's fine.
			iconLayer.clearLayers().remove();
			highlightLayer.clearLayers().remove();

			let hasHighlight = false;
			for (let i = 0; i < points.length; ++i) {
				const point = points[i];
				let marker = markers[i];

				const latlng = new L.LatLng(point.lat, point.lng);
				if (!marker) {
					marker = new CustomMarker({
						color: point.color,
						latlng,
						glyph: point.glyph,
						font: point.font,
						size: baseSize * point.size,
						opacity: point.opacity != null ? point.opacity : 1,
						interactive: true,
						centroid: point.centroid,
						backgroundColor: point.backgroundColor,
					});
					marker.bindPopup(point.word);
				} else {
					marker.options.color = point.color;
					marker.options.glyph = point.glyph;
					marker.options.font = point.font;
					marker.options.latlng = latlng;
					marker.options.size = baseSize * point.size;
					marker.options.opacity = point.opacity != null ? point.opacity : 1;
					marker.options.centroid = point.centroid;
					marker.options.backgroundColor = point.backgroundColor;
					marker.setPopupContent(point.word);
				}

				if (point.highlight) {
					hasHighlight = true;
					marker.options.glyph = this.highlightSteps[0].glyph || marker.options.glyph;
					marker.options.font = this.highlightSteps[0].font || marker.options.font;
					marker.options.color = this.highlightSteps[0].color || marker.options.color;
					marker.options.size *= this.highlightSteps[0].size || marker.options.size;
					highlightLayer.addLayer(marker);
				} else {
					iconLayer.addLayer(marker);
				}
			}

			// remove any unused markers, so we don't hold on to memory indefinitely
			this.cachedMarkers = Object.freeze([]);

			map.addLayer(iconLayer);
			map.addLayer(highlightLayer);

			if (hasHighlight && !this.highlightIntervalHandle) {
				this.highlightStep = 0;
				this.highlightIntervalHandle = setInterval(() => this.updateHighlightedPoints(), this.highlightStepInterval);
			} else if (this.highlightIntervalHandle && !hasHighlight) {
				clearInterval(this.highlightIntervalHandle);
				this.highlightIntervalHandle = undefined;
				this.highlightStep = 0;
			}
		},

		updateBounds() {
			const {map, rect} = this.mapData;
			const bounds = this.bounds;

			map.fitBounds(bounds, { padding: [0, 0] }); // this reset the zoom level
			if (this._points.length) {
				rect.setBounds(bounds);
				rect.addTo(map);
				rect.redraw();
			}
		},

		updateHighlightedPoints() {
			this.highlightStep = (this.highlightStep + 1) % this.highlightSteps.length;
			const markers = this.mapData.highlightLayer.getLayers() as CustomMarker[];
			const values = this.highlightSteps[this.highlightStep];


			const baseSize = this.markerSize;
			this._highlightedPoints.forEach((p, i) => {
				const m = markers[i];
				m.options.color = values.color || p.color;
				m.options.size = baseSize * p.size * values.size;
				m.options.glyph = values.glyph || p.glyph;
				m.options.font = values.font || p.font;
				m.options.backgroundColor = 'backgroundColor' in values ? values.backgroundColor : p.backgroundColor;
			});

			this.mapData.highlightLayer.remove();
			this.mapData.highlightLayer.addTo(this.mapData.map);
		},
	},
	mounted() {
		const el = this.$refs.map as HTMLElement;

		const baseMapNoPlaceNames = new L.TileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
			minZoom: 1,
			maxZoom: 20,
			attribution: `Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors`,
			// gives issues with our export code in firefox. Somehow exports on retina screens become really pixelated because the 
			// image downscaling of map tiles in a canvas doesn't seem to work correctly.
			detectRetina: false 
		});

		// const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		// const thunderForestPioneer = 'https://{s}.tile.thunderforest.com/pioneer/{z}/{x}/{y}.png';
		// always download this in 2x quality to get best possible exports
		const placeNames = new L.TileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}@2x.png', {
			minZoom: 1,
			maxZoom: 20,
			attribution: `Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors`,
			// See leaflet-extensions.ts
			// prevent this map from downloading higher quality layer if user overrides the detail level
			// because that would cause placenames to become too small to be usable.
			// @ts-ignore
			prevent_detail_override: true
		});

		const { rect, map, iconLayer, highlightLayer } = this.mapData = Object.freeze({
			map: new L.Map(el, { preferCanvas: true, selectArea: true, zoomDelta: 0.25, zoomSnap: 0.25, wheelPxPerZoomLevel: 100 }),
			rect: new L.Rectangle(this.bounds, {
				color: '#8080ff',
				weight: 1,
				fill: false,
			}),
			iconLayer: new L.LayerGroup(),
			highlightLayer: new L.LayerGroup(),
		});
		this.$emit('map-changed', map);

		const layerControl = L.control.layers();

		map.selectArea.setControlKey(true);
		map.addControl(layerControl);
		map.addLayer(baseMapNoPlaceNames);
		map.addLayer(placeNames);
		map.addLayer(iconLayer);
		map.addLayer(highlightLayer);

		// Only do this after adding to the map or the state will be out of sync
		layerControl.addOverlay(rect, this.$t('map.map_layer_area_border').toString());

		// map.dragging.enable();
		map.zoomControl.setPosition('topright');
		map.setZoom(this.zoomLevel);
		map.on('zoomend', () => this.removeAndCacheMarkers());
		map.on('zoomend', e => this.zoomLevel = e.sourceTarget.getZoom());
		map.on('areaselected', e => this.$emit('areaselected', e.bounds));
		Vue.nextTick(() => requestAnimationFrame(() => map.invalidateSize()));

		// We add this in mounted() because adding them under watch triggers too soon and crashes because the map does not exist yet.
		// And leaving off immediate skips the first update.
		this.$watch(function() { return {a: this.zoomLevel, b: this._points}; }, this.updatePoints, {immediate: true});
		this.$watch('bounds', this.updateBounds, {immediate: true});

		if (window.matchMedia) { window.matchMedia('print').removeListener(this.invalidateSize); }

	},
	beforeDestroy() {
		this.$emit('map-changed', null);

		const map = this.mapData.map;
		map.stop();
		map.fireEvent('beforeUnload');
		map.remove();
		map.off(); // only after destroying, give destroy/shutdown listeners a change to run

		if (this.highlightIntervalHandle) { clearInterval(this.highlightIntervalHandle); this.highlightIntervalHandle = undefined; }
		if (window.matchMedia) { window.matchMedia('print').removeListener(this.invalidateSize); }
	},
});

import { DirectiveFunction, VueConstructor } from 'vue';

// % operator is actually remainder, not modulo! See https://medium.com/javascript-in-plain-english/going-around-in-circles-with-modulus-in-javascript-9e4534ac47b1
function mod(a: number, b: number) { return ((a % b) + b) % b; }

class Context {
	public static instances = new Map<string, Context>();

	public static get(id: string): Context {
		if (!Context.instances.has(id)) {
			Context.instances.set(id, new Context(id));
		}

		return Context.instances.get(id)!;
	}

	public items: HTMLElement[] = [];
	public master?: HTMLElement;
	private pendingLeave?: number;

	private readonly masterEvents = [] as Array<[string, any]>;
	private readonly itemEvents = [] as Array<[string, any]>;
	constructor(public readonly id: string) {
		this.masterEvents.push(
			['keydown', this.handleMasterKeypress.bind(this)],
			['focusout', this.handleLeaveList.bind(this)],
			['focusin', this.handleEnterList.bind(this)],
		);
		this.itemEvents.push(
			['keydown', this.handleEntryKeypress.bind(this)],
			['focusout', this.handleLeaveList.bind(this)],
			['focusin', this.handleEnterList.bind(this)],
		);
	}

	public attach(el: HTMLElement, isMaster?: boolean) {
		if (isMaster && this.master === el) {
			throw new Error(`Only one master element allowed per focus list (ID '${this.id}')`);
		}

		if (isMaster) {
			this.master = el;
			this.masterEvents.forEach(([event, handler]) => el.addEventListener(event, handler));
		} else {
			this.items.push(el);
			this.itemEvents.forEach(([event, handler]) => el.addEventListener(event, handler));
		}

		if (this.items.length) {
			// tslint:disable-next-line
			this.items.sort((a, b) => a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1);
		}
	}

	public detach(el: HTMLElement, isMaster?: boolean) {
		if (isMaster) {
			this.master = undefined;
			this.masterEvents.forEach(([event, handler]) => el.addEventListener(event, handler));
		} else {
			this.items = this.items.filter(item => item !== el);
			this.itemEvents.forEach(([event, handler]) => el.removeEventListener(event, handler));
		}

		if (this.items.length === 0 && this.master == null) {
			Context.instances.delete(this.id);
		}

		if (this.items.length) {
			// tslint:disable-next-line
			this.items.sort((a, b) => a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1);
		}
	}

	public enter(index?: number) {
		const item = index != null && this.items[index];
		if (item) {
			item.focus();
		}
	}

	public shiftIndex(offset: number|'last'|'first') {
		if (this.items.length === 0) { return; }

		const prevIndex = this.items.findIndex(item => item === document.activeElement);
		let newIndex: number;

		if (offset === 'first') { newIndex = 0; }
		else if (offset === 'last') { newIndex = this.items.length - 1; }
		else if (prevIndex >= 0) { newIndex = mod(prevIndex + offset, this.items.length); }
		else { newIndex = mod(offset < 0 ? offset : offset - 1, this.items.length); }

		this.enter(newIndex);
	}

	private handleMasterKeypress(e: KeyboardEvent) {
		switch (e.keyCode) {
			case 35: this.shiftIndex('last'); e.preventDefault(); return; // end
			case 36: this.shiftIndex('first'); e.preventDefault(); return; // home
			case 38: this.shiftIndex(-1); e.preventDefault(); return; // arrow up
			case 40: this.shiftIndex(1); e.preventDefault(); return; // arrow down

			// case 9:
			// tab key not on master, should go to the next naturally focusable element in the page
		}
	}
	private handleEntryKeypress(e: KeyboardEvent) {
		switch (e.keyCode) {
			case 35: this.shiftIndex('last'); e.preventDefault(); return; // end
			case 36: this.shiftIndex('first'); e.preventDefault(); return; // home
			case 37:
			case 38: this.shiftIndex(-1); e.preventDefault(); return; // arrow left/up
			case 39:
			case 40: this.shiftIndex(1); e.preventDefault(); return; // arrow right/down

			case 27: this.returnFocusToMaster(); e.preventDefault(); return; // escape
			case 9: this.shiftIndex(e.shiftKey ? -1 : 1); e.preventDefault(); return; // tab
		}
	}

	private handleEnterList(_e: FocusEvent) {
		if (this.pendingLeave != null) {
			cancelAnimationFrame(this.pendingLeave);
			this.pendingLeave = undefined;
		} else {
			this.fireEnterEvent();
		}
	}

	private handleLeaveList(_e: FocusEvent) {
		if (this.pendingLeave != null) {
			cancelAnimationFrame(this.pendingLeave);
			this.pendingLeave = undefined;
		}
		this.pendingLeave = requestAnimationFrame(() => { this.pendingLeave = undefined; this.fireLeaveEvent(); });
	}

	private fireLeaveEvent() {
		if (this.master) {
			const e = new CustomEvent('v-focus-list-leave', {
				bubbles: true,
				detail: { id: this.id }
			});
			this.master.dispatchEvent(e);
		}
	}

	private fireEnterEvent() {
		if (this.master) {
			const e = new CustomEvent('v-focus-list-enter', {
				bubbles: true,
				detail: { id: this.id }
			});
			this.master.dispatchEvent(e);
		}
	}

	private returnFocusToMaster() {
		if (this.master) {
			this.master.focus();
		} else {
			(document.activeElement! as HTMLElement).blur();
		}
	}
}

const bind: DirectiveFunction = (el, binding) => {
	const listId = binding.arg;
	if (!listId) {
		throw new Error('v-focus-list: list name must be supplied using argument (v-focus-list:target-list-name)');
	}

	const context = Context.get(listId);
	context.attach(el, binding.modifiers.master);
};

const unbind: DirectiveFunction = (el, binding) => {
	const listId = binding.arg;
	if (!listId) {
		throw new Error('v-focus-list: list name must be supplied using argument (v-focus-list:target-list-name)');
	}
	const context = Context.get(listId);
	context.detach(el, binding.modifiers.master);
};

export default {
	install(vue: VueConstructor) {
		vue.directive('focus-list', {
			bind,
			unbind,
			update(el, binding, a, b) {
				if (binding.arg !== binding.oldArg) {
					const oldListId = binding.oldArg as string;
					const oldContext = Context.get(oldListId);
					oldContext.detach(el, binding.modifiers.master);

					bind(el, binding, a, b);
				}
			}
		});
	}
};

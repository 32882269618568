
import Vue from 'vue';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';
import { ResultVisual, ResultKeyword } from '@/scopes/SearchScopeTypes';
import { FAGlyphs, Glyph } from '@/Util';

// TODO print instellingen (print kleuren ja/nee, print symbool grootte ja/nee) - zoiets

export default Vue.extend({
	props: {
		...SearchScopeChildProps,
		colors: { type: Boolean, default: true },
		sizes: { type: Boolean, default: false },
	},
	computed: {
		displayFilters(): undefined|Array<[string, string[]]> {
			const e = Object.entries(this.searchFilters || {}).filter(f => f[1].length);
			if (this.searchArea) {
				e.push(['area', [
					`lengtegraad ${this.searchArea.getSouth().toFixed(4)}-${this.searchArea.getNorth().toFixed(4)}`,
					`breedtegraad ${this.searchArea.getWest().toFixed(4)}-${this.searchArea.getEast().toFixed(4)}`
				]]);
			}
			return e.length ? e : undefined;
		},
		displayWords(): undefined|string[] {
			return this.searchWords && this.searchWords.length ? this.searchWords : undefined;
		},
		legend(): undefined|Array<{
			group: string|null,
			words: Array<Array<ResultVisual&ResultKeyword&Glyph>>
		}> {
			if (!this.resultKeywordsByGroup) { return undefined; }

			const keywords = this.resultKeywords!;
			const visuals = this.resultVisuals!;
			const mapped = this.resultKeywordsByGroup.reduce<Array<{
				group: null|string,
				words: Array<Array<ResultVisual&ResultKeyword&Glyph>>
			}>>((acc, d) => {
				const group = d.group;
				const words = d.keywords.map(id => ({
					...keywords[id],
					...visuals[id],
					...FAGlyphs[visuals[id].icon]
				})).filter(kw => kw.isVisible);

				if (words.length) {
					acc.push({ group, words: [words.slice(0, Math.ceil(words.length / 2)), words.slice(Math.ceil(words.length / 2))] });
				}

				return acc;
			}, []);

			return mapped;
		}
	}
});


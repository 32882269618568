export type Mat44 = [[number, number, number, number], [number, number, number, number], [number, number, number, number], [number, number, number, number]];

/** Multiply two matrices */
export function mult(m1: Mat44, m2: Mat44): Mat44 {
	var result: Mat44 = [[], [], [], []] as any;
	for (var i = 0; i < m1.length; i++) {
		for (var j = 0; j < m2[0].length; j++) {
			var sum = 0;
			for (var k = 0; k < m1[0].length; k++) {
				sum += m1[i][k] * m2[k][j];
			}
			result[i][j] = sum;
		}
	}
	return result;
} 

/** Given a matrix, computes another matrix that exactly undoes the transformation */
export function invert(m: Mat44): Mat44 {
	const [[M11, M12, M13, M14], [M21, M22, M23, M24], [M31, M32, M33, M34], [M41, M42, M43, M44]] = m;

	const det = 
		(M11 * M22 * M33 * M44 ) + (M11 * M23 * M34 * M42 ) + (M11 * M24 * M32 * M43 ) 
	- (M11 * M24 * M33 * M42 ) - (M11 * M23 * M32 * M44 ) - (M11 * M22 * M34 * M43 ) 
	- (M12 * M21 * M33 * M44 ) - (M13 * M21 * M34 * M42 ) - (M14 * M21 * M32 * M43 )
	+ (M14 * M21 * M33 * M42 ) + (M13 * M21 * M32 * M44 ) + (M12 * M21 * M34 * M43 )
	+ (M12 * M23 * M31 * M44 ) + (M13 * M24 * M31 * M42 ) + (M14 * M22 * M31 * M43 )
	- (M14 * M23 * M31 * M42 ) - (M13 * M22 * M31 * M44 ) - (M12 * M24 * M31 * M43 )
	- (M12 * M23 * M34 * M41 ) - (M13 * M24 * M32 * M41 ) - (M14 * M22 * M33 * M41 )
	+ (M14 * M23 * M32 * M41 ) + (M13 * M22 * M34 * M41 ) + (M12 * M24 * M33 * M41 );	

	return [[
		(M22*M33*M44 + M23*M34*M42 + M24*M32*M43 - M24*M33*M42 - M23*M32*M44 - M22*M34*M43)/det,
		(-M12*M33*M44 - M13*M34*M42 - M14*M32*M43 + M14*M33*M42 + M13*M32*M44 + M12*M34*M43)/det,
		(M12*M23*M44 + M13*M24*M42 + M14*M22*M43 - M14*M23*M42 - M13*M22*M44 - M12*M24*M43)/det,
		(-M12*M23*M34 - M13*M24*M32 - M14*M22*M33 + M14*M23*M32 + M13*M22*M34 + M12*M24*M33)/det,
	], [
		(-M21*M33*M44 - M23*M34*M41 - M24*M31*M43 + M24*M33*M41 + M23*M31*M44 + M21*M34*M43)/det,
		(M11*M33*M44 + M13*M34*M41 + M14*M31*M43 - M14*M33*M41 - M13*M31*M44 - M11*M34*M43)/det,
		(-M11*M23*M44 - M13*M24*M41 - M14*M21*M43 + M14*M23*M41 + M13*M21*M44 + M11*M24*M43)/det,
		(M11*M23*M34 + M13*M24*M31 + M14*M21*M33 - M14*M23*M31 - M13*M21*M34 - M11*M24*M33)/det,
	], [
		(M21*M32*M44 + M22*M34*M41 + M24*M31*M42 - M24*M32*M41 - M22*M31*M44 - M21*M34*M42)/det,
		(-M11*M32*M44 - M12*M34*M41 - M14*M31*M42 + M14*M32*M41 + M12*M31*M44 + M11*M34*M42)/det,
		(M11*M22*M44 + M12*M24*M41 + M14*M21*M42 - M14*M22*M41 - M12*M21*M44 - M11*M24*M42)/det,
		(-M11*M22*M34 - M12*M24*M31 - M14*M21*M32 + M14*M22*M31 + M12*M21*M34 + M11*M24*M32)/det,
	], [
		(-M21*M32*M43 - M22*M33*M41 - M23*M31*M42 + M23*M32*M41 + M22*M31*M43 + M21*M33*M42)/det,
		(M11*M32*M43 + M12*M33*M41 + M13*M31*M42 - M13*M32*M41 - M12*M31*M43 - M11*M33*M42)/det,
		(-M11*M22*M43 - M12*M23*M41 - M13*M21*M42 + M13*M22*M41 + M12*M21*M43 + M11*M23*M42)/det,
		(M11*M22*M33 + M12*M23*M31 + M13*M21*M32 - M13*M22*M31 - M12*M21*M33 - M11*M23*M32)/det,
	]];
}

/** Decompose matrix into readable values */
export function decompose(m: Mat44): {scaleX: number, scaleY: number, scaleZ: number, translateX: number, translateY: number, translateZ: number, rotateX: number, rotateY: number, rotateZ: number} {
	const scaleX = Math.sqrt(m[0][0] * m[0][0] + m[0][1] * m[0][1] + m[0][2] * m[0][2]);
	const scaleY = Math.sqrt(m[1][0] * m[1][0] + m[1][1] * m[1][1] + m[1][2] * m[1][2]);
	const scaleZ = Math.sqrt(m[2][0] * m[2][0] + m[2][1] * m[2][1] + m[2][2] * m[2][2]);
	const translateX = m[3][0];
	const translateY = m[3][1];
	const translateZ = m[3][2];
	const rotateX = Math.atan2(m[2][1], m[2][2]);
	const rotateY = Math.atan2(-m[2][0], Math.sqrt(m[2][1] * m[2][1] + m[2][2] * m[2][2]));
	const rotateZ = Math.atan2(m[1][0], m[0][0]);
	return {scaleX, scaleY, scaleZ, translateX, translateY, translateZ, rotateX, rotateY, rotateZ};
};

/** Get an element's css transform as a matrix */
export function localMatrix(el: HTMLElement): Mat44 {
	const s = getComputedStyle(el).transform.match(/-?\d+\.?\d*/g);
	if (!s) return [
		[1,0,0,0],
		[0,1,0,0],
		[0,0,1,0],
		[0,0,0,1],
	];
	const trans = s.map(n => Number(n));
	// Note: matrix(a, b, c, d, tx, ty) is a shorthand for matrix3d(a, b, 0, 0, c, d, 0, 0, 0, 0, 1, 0, tx, ty, 0, 1).
	if (trans.length === 6) {
		// return the expanded matrix
		/*
		NOTE: this appears rotated in sourcecode but is correct
		when printing you'd use 
			00, 10, 20, 30
			10, 11, 21, 31
			20, 21, 22, 32
			30, 31, 32, 33
		*/
		return [
			[trans[0], trans[1], 0, 0],
			[trans[2], trans[3], 0, 0],
			[0,0,1,0],
			[trans[4], trans[5], 0, 1]
		]
	}
	return [
		[trans[0], trans[1], trans[2], trans[3]], 
		[trans[4], trans[5], trans[6], trans[7]],
		[trans[8], trans[9], trans[10], trans[11]],
		[trans[12], trans[13], trans[14], trans[15]]
	];
}

/** Get screen space transform (i.e. transform compounded with all parent transformations) */
export function matrix(el: HTMLElement, stopAt?: HTMLElement): Mat44 {
	let trans = localMatrix(el);
	while (el.parentElement && el != stopAt) {
		trans = mult(trans, localMatrix(el.parentElement));
		el = el.parentElement;
	}
	return trans;
}

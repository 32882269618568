

import Vue from 'vue';
import { MapOf, FAGlyphs, Glyph } from '@/Util';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';

import Group from './Group.vue';
import { ResultVisual, ResultKeyword } from '../../scopes/SearchScopeTypes';

export default Vue.extend({
	components: {
		Group,
	},
	props:  {
		...SearchScopeChildProps
	},
	computed: {
		// NOTE: Preserve global group sorting
		groupsWithKeywords(): Array<{group: null|string, data: Array<ResultKeyword&ResultVisual&Glyph>}> {
			if (!this.resultKeywordsByGroup) { return []; }

			const visuals = this.resultVisuals!;
			const keywords = this.resultKeywords!;

			return this.resultKeywordsByGroup.map(g => ({
				...g,
				group: g.group != null ? g.group : this.$t(`groups.default_group_name.${this.searchDirection}`).toString(),
				data: g.keywords.map(keywordId => ({...visuals[keywordId], ...keywords[keywordId], ...FAGlyphs[visuals[keywordId].icon]})),
			}));
		},
	},
	methods: {
		toggleHidden(groupId: string|null, newState: boolean): void {
			const group = this.groupsWithKeywords.find(g => g.group === groupId)!;
			const vs = this.resultVisuals!;
			this.$emit('visualsChanged', group.data.reduce<MapOf<ResultVisual>>((map, {id}) => {
				const visual = vs[id];
				map[id] = { ...visual, isVisible: newState };
				return map;
			}, {}));
		},
		toggleHighlight(groupId: string, newState: boolean): void {
			const group = this.groupsWithKeywords.find(g => g.group === groupId)!;
			const vs = this.resultVisuals!;
			this.$emit('visualsChanged', group.data.reduce<MapOf<ResultVisual>>((map, {id}) => {
				const visual = vs[id];
				map[id] = { ...visual, isHighlighted: newState };
				return map;
			}, {}));
		},
		toggleKeywordHighlight(keywordId: string): void {
			this.$emit('visualsChanged', { [keywordId]: {...this.resultVisuals![keywordId], isHighlighted: !this.resultVisuals![keywordId].isHighlighted}});
		},
		toggleKeywordVisible(keywordId: string): void {
			this.$emit('visualsChanged', { [keywordId]: {...this.resultVisuals![keywordId], isVisible: !this.resultVisuals![keywordId].isVisible}});
		}
	}
});


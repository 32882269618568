import Vue from 'vue';
import VueI18n from 'vue-i18n';

export const messages = {
	nl: {
		tooltips: {
			searching: {
				dir: {
0: `Je zoekt dialectwoorden voor een woord in de standaardtaal: tik het begrip waarvoor je dialectwoorden zoekt in de zoekbalk.
Als je zoekopdracht geen resultaten oplevert, kan je een synoniem proberen, bijvoorbeeld 'gierig persoon' in plaats van 'gierigaard'.`,

1: `Je zoekt de betekenis van een dialectwoord: tik het dialectwoord dat je zoekt in de zoekbalk.
Schrijf het woord niet zoals je het uitspreekt in het dialect, maar volgens de Nederlandse spellingregels:
'stroateluupere' wordt dus 'strateloper' of 'straatloper', en 'sjtee-uul' wordt 'steenuil'.`
				}
			},
			facets: {
				introduction:
`Je kunt een zoekactie verfijnen met de filters ‘woordenboek’, ‘land’, ‘thema’, ‘provincie’ en ‘plaats’.
Het cijfer achter de filteropties geeft het aantal verschillende woorden voor die categorie weer. Je bent niet verplicht om die opties te gebruiken.`,
				topic_group:
`Je kan zoeken binnen een bepaald thema door één of meerdere thema’s uit de keuzelijst te kiezen.`,
				country:
`Je kan zoeken binnen een bepaald land door één of meerdere landen aan te vinken.`,
				dictionary:
`Je kan zoeken binnen een bepaald woordenboek door één of meerdere woordenboeken uit de keuzelijst te kiezen.`				,
				province:
`Je kan zoeken binnen een bepaalde provincie door één of meerdere provincies uit de keuzelijst te kiezen.
Frans-Vlaanderen en het Brussels Hoofdstedelijk Gewest zijn strikt genomen geen provincies, maar staan toch in deze lijst.`,
				place:
`Je kan zoeken binnen een bepaalde plaatsdoor één of meerdere plaatsen uit de keuzelijst te kiezen..`,
			},
		},

		facets: {
			label: {
				topic_group: `Thema`,
				country: `Land`,
				area: `Zoekgebied`,
				dictionary: `Woordenboek`,
				province: `Provincie`,
				place: `Plaats`,
				words: `Woorden`
			},
			select_description: {
				topic_group: `Selecteer een of meer thema's`,
				country: `Selecteer een of meer landen`,
				dictionary: `Selecteer een of meer woordenboeken`,
				province: `Selecteer een of meer provincies`,
				place: `Selecteer een of meer plaatsnamen`
			},

			no_options_found: `Geen gegevens voor deze zoekopdracht.`,
			loading_failed_retry_help: 'Kan filter lijsten niet laden - klik om opnieuw te proberen',
		},

		searching: {
			dir: {
				0: 'Dialectwoorden voor...',
				1: 'Betekenissen van...',
			},
			im_searching_for: 'Ik zoek...',
			im_searching_in: 'Filters',

			error: 'Fout tijdens zoeken',
			result_counts: '{0} voor {1} gevonden.',
			result_counts_simple: '0 resultaten gevonden | 1 resultaat gevonden | {count} resultaten gevonden',
			clear_results: 'Resultaten verwijderen',

			no_results: `Geen gegevens voor deze zoekopdracht.`
		},

		results: {
			view_map_for_dir: {
				0: 'Bekijk dialectkaart',
				1: 'Bekijk betekeniskaart'
			},
			view_map_for_dir_help: {
				0: 'Klik hier om de gevonden resultaten op de dialectkaart te zien.',
				1: 'Klik hier om de gevonden resultaten op de betekeniskaart te zien.',
			},
			view_map_for_dir_unavailable: {
				0: 'Dialectkaart is alleen beschikbaar wanneer u slechts naar een enkel woord zoekt',
				1: 'Betekeniskaart is alleen beschikbaar wanneer u slechts naar een enkel woord zoekt',
			},
			view_map_for_concept: `Toon alle dialectwoorden voor concept '{0}' op de kaart`,
			view_map_for_concept_with_filters_label: `Concept (met huidige filters)`,
			view_map_for_concept_with_filters_help: `Toon alle dialectwoorden voor concept '{0}' op de kaart, en gebruik ook de huidige filters`,

			view_map_for_keyword: `Toon alle voorkomens van het dialectwoord '{0}' op de kaart`,

			detailed: 'Gedetailleerde resultaten',
			detailed_help: 'Klik hier om de gevonden resultaten in tabelvorm te zien.',

			download: 'Resultaten downloaden',
			download_help:
`Klik hier om de gevonden resultaten te downloaden voor eigen onderzoek. In deze resultaten zijn ook Kloekecodes opgenomen.
Wens je nog meer detailinformatie, stuur dan een mail naar servicedesk@ivdnt.org.`,
			download_format_placeholder: 'Downloadformaat',
			download_error: 'Fout tijdens downloaden: {0}',
			download_not_enabled: 'Het downloaden van data is uitgeschakeld door de administrator',

			definition: 'Definitie',
			original_definitions: 'Oorspronkelijke definitie | Oorspronkelijke definities',

			header_number_of_occurances: 'Aantal',
			header_keyword: 'Dialectwoord',
			header_variants: 'Varianten',
			header_places: 'Opgetekend in',
			header_map: 'Toon op kaart',

			visible_columns: 'Getoonde kolommen',

			load_previous_result_part_label: 'Vorige deel',
			load_previous_result_part_help: 'Laad het voorgaande de deel van de resultaten',
			load_next_result_part_label: 'Volgende deel',
			load_next_result_part_help: 'Laad het volgende deel van de resultaten'
		},

		groups: {
			top_words: {
				0: 'Dialectwoorden',
				1: 'Concepten',
			},
			bottom_words: {
				0: 'Geselecteerde Dialectwoorden',
				1: 'Geselecteerde Concepten',
			},

			filter_off_help: 'Deze filter uitschakelen',

			filter_header_at_top: 'Maak selectie',
			filter_header_at_top_help:
`Doe dit door bijvoorbeeld een trefwoord te selecteren (klik één of meer trefwoorden aan) en te bewerken in het bewerkingsveld eronder,
of door een aantal trefwoorden te selecteren via de zoekcriteria hieronder.
De geselecteerde trefwoorden kan je vervolgens eventueel als één groep trefwoorden bewerken.`,

			// Op het moment niet in de interface zichtbaar, maar bewaren voor de toekomst
			hide_filtered_words: 'Verberg niet-relevante woorden',
			hide_filtered_words_help: 'Verberg alle woorden die niet voldoen aan onderstaande filters.\nDeze instelling heeft alleen invloed op weergave in de selectievakken hiernaast, niet op het wel/niet tonen van woorden op de kaart.',

			filter_word_in_group: 'Woord in groep',
			filter_word_in_group_help:
`Als je al een groep hebt aangemaakt, kan je hier aangeven of de
gegroepeerde woorden bij een volgende selectie meedoen of niet.`,
			filter_word_in_group_yes_help: 'Selecteer alleen die woorden die in een groep zijn ingedeeld',
			filter_word_in_group_no_help: 'Selecteer alleen die woorden die niet in een groep zijn ingedeeld',
			filter_word_in_group_off_help: '@:groups.filter_off_help',

			filter_visible: 'Woord is zichtbaar',
			filter_visible_help:
`Als je woorden onzichtbaar hebt gemaakt (via het oogje of door dubbelklikken in de
legenda van de kaart, of in het bewerkveld hieronder), kan je hier aangeven of deze
bij een volgende selectie meedoen of niet.`,
			filter_visible_yes_help: `Selecteer alleen die woorden die zichtbaar zijn op de kaart.`,
			filter_visible_no_help: `Selecteer alleen die woorden die verborgen zijn op de kaart.`,
			filter_visible_off_help: '@:groups.filter_off_help',

			// Op het moment niet in de interface zichtbaar, maar bewaren voor de toekomst
			filter_variants: 'Heeft dialectvarianten',
			filter_variants_help: `Een dialectvariant is een anders uitgesproken versie, maar in essentie hetzelfde woord`,
			filter_variants_yes_help: `Selecteer alleen die woorden die dialectvarianten hebben`,
			filter_variants_no_help: `Selecteer alleen die woorden die geen dialectvarianten hebben`,
			filter_variants_off_help: '@:groups.filter_off_help',

			filter_concept_contains: 'Concept bevat',
			filter_concept_contains_help:
`In dit veld geef je aan welke woorden je wil selecteren voor verdere bewerking.
Je kan bijvoorbeeld een deel van een woord selecteren (bv. 'piep'), en dan zullen alle concepten met 'piep' erin, geselecteerd worden voor
eventuele verdere bewerking in het werkveld onderaan.`,
			filter_concept_contains_off_help: '@:groups.filter_off_help',

			filter_keyword_contains: 'Dialectwoord bevat',
			filter_keyword_contains_help:
`In dit veld geef je aan welke woorden je wil selecteren voor verdere bewerking.
Je kan bijvoorbeeld een deel van een woord selecteren (bv. 'piep'), en dan zullen alle trefwoorden met 'piep' erin, geselecteerd worden voor
eventuele verdere bewerking in het werkveld onderaan.`,
			filter_keyword_contains_off_help: '@:groups.filter_off_help',

			filter_number_of_occurances: 'Selecteren op aantal',
			filter_number_of_occurances_help:
`Met de schuifbalk kun je op basis van hoe vaak een trefwoord is aangetroffen, kiezen welke woorden je wil laten zien op de kaart.
Door de linkerknop naar rechts te schuiven geef je de minimumfrequentie aan van de te karteren woorden.
Door de rechterknop naar links te verschuiven geef je de maximumfrequentie aan van de te karteren woorden.`,
			filter_number_of_occurances_off_help: '@:groups.filter_off_help',

			filter_clear_all_label: 'Voorselectie ongedaan maken',
			filter_clear_all_help: 'Alle filters uitschakelen',

			action_set_group_label: 'Naam groep',
			action_set_group_help:
`Als je verschillende trefwoorden samen op de kaart wil tonen met één symbool kan je een nieuwe naam kiezen.
Stel dat je meerlaar en merelaar samen wil laten zien, dan plaats je die samen in een groep met een nieuwe ‘meerlaar, merelaar’.
Door op het vinkje te klikken, voeg je de woorden toe.
Je kunt trefwoorden weer uit de groep halen door ze te slepen met de muis, of door dit veld leeg te laten.`,
			action_set_group_apply: 'Sla de groepsnaam op.',

			action_header_at_top: 'Bewerk selectie',
			action_header_at_top_help:
`Hier kan je van de geselecteerde woorden een groep maken, door een naam te geven, en je kan een eigen kleur, symbool en symboolgrootte kiezen.
Je kan er ook voor kiezen om de selectie te verbergen op de kaart.`,

			action_set_color_label: 'Kleur',
			action_set_color_help: `Kies een kleur of kies via de dobbelsteen een willekeurige kleur.`,
			action_set_color_placeholder: 'Kies kleur',
			action_set_color_random_color_label: 'Kies een willekeurige kleur',
			action_set_color_apply: 'Kleur toepassen',

			action_set_symbol_label: 'Symbool',
			action_set_symbol_help: `Kies een symbool of kies via de dobbelsteen een willekeurig symbool.`,
			action_set_symbol_placeholder: 'Kies symbool',
			action_set_symbol_random_symbol_label: 'Kies een willekeurig symbool',
			action_set_symbol_apply: 'Symbool toepassen',

			action_set_symbol_size_label: 'Grootte',
			action_set_symbol_size_help: `Met de schuifbalk kan je de grootte van je symbool aanpassen.`,
			action_set_symbol_size_apply: 'Symboolgrootte toepassen',

			action_set_visible_true_label: 'Tonen',
			action_set_visible_true_help: 'Toon deze woorden op de kaart',
			action_set_visible_false_label: 'Verbergen',
			action_set_visible_false_help: 'Verwijder deze woorden op de kaart',

			action_set_highlighted_true_help: 'Deze woorden uitlichten op de kaart',
			action_set_highlighted_false_help: 'Deze woorden niet langer uitlichten op de kaart',

			action_randomize_all_symbols_label: 'Elk woord een ander symbool',
			action_randomize_all_symbols_help: `Door te klikken, kiezen we voor elk trefwoord een ander symbool op de kaart.\nJe kan zelf symbolen kiezen in het keuzemenu hierboven.`,
			action_randomize_all_colors_label: 'Elk woord een andere kleur',
			action_randomize_all_colors_help: `Door te klikken, kiezen we voor elk trefwoord een andere kleur.\nJe kan zelf kleuren kiezen in het keuzemenu hierboven.`,

			action_reset_all_label: 'Symbolen verwijderen',
			action_reset_all_help: `Door te klikken, verdwijnen de zelfgekozen symbolen en kleuren op de kaart en herstel je de oorspronkelijke kaart zoals die in het begin getoond wordt.`,
			action_reset_all_warning_message: `Weet u zeker dat u alle instellingen voor deze trefwooorden wilt verwijderen?\nSymbolen's, kleuren, groepindelingen etc. zullen worden hersteld naar de originele waarden.`,
			action_reset_all_warning_ok: 'Verwijderen',
			action_reset_all_warning_cancel: 'Annuleren',
			action_reset_all_warning_title: 'Instellingen verwijderen?',

			default_group_name: {
				0: 'Overige trefwoorden',
				1: 'Overige concepten',
			},

			group_set_visible_true_help: 'Woorden in deze groep tonen op de kaart.\nU kunt ook dubbelklikken op een enkel woord in dit menu om alleen dat woord weer te tonen op de kaart.',
			group_set_visible_false_help: 'Woorden in deze groep verbergen op de kaart.\nU kunt ook dubbelklikken op een enkel woord in dit menu om alleen dat woord te verbergen op de kaart.',

			group_set_highlighted_true_help: 'Woorden in deze groep uitlichten op de kaart.\nU kunt ook klikken op een enkel woord in dit menu om alleen dat woord uit te lichten op de kaart.',
			group_set_highlighted_false_help: 'Woorden in deze groep niet langer uitlichten op de kaart.\nU kunt ook klikken op een enkel woord in dit menu om alleen dat woord niet langer uit te lichten op de kaart.',

			action_copy_keyword_settings_label: 'Kopiëren',
			action_copy_keyword_settings_help: 'Klik hier en daarna op een woord om kleur, grootte, symbool en groep to kopiëren naar de andere geselecteerde woorden.',

			select: 'Bewerk',
			select_help: 'Als je hier klikt, worden alle geselecteerde woorden verplaatst naar het werkveld voor verdere bewerking.',
			deselect: 'Opslaan',
			deselect_help: 'De bewerkte groep wordt bewaard en weer naar boven verplaatst.',

			undo: 'Ongedaan maken',
			undo_help: 'De selectie wordt ongewijzigd weer naar boven verplaatst.',
		},

		common: {
			title: 'Database van de Zuidelijk-Nederlandse Dialecten',
			title_en: 'Database of the Southern Dutch Dialects',

			no_internet: 'Geen verbinding',
			retry: 'Probeer opnieuw',
			apply: 'Toepassen',
			cancel: 'Annuleren',
			close: 'Sluiten',
			search: 'Zoeken',
			map: 'Kaart',
			reset: 'Reset',
			help: 'Help',
			about: 'Over het project',

			regex_toggle: 'Reguliere expressies',
			regex_toggle_help: 'Als je reguliere expressies aanvinkt, werk je met reguliere expressies (zie uitleg bij help),\nals je het uitvinkt, werk je met de gebruikelijke jokertekens (* en ?)',
			regex_toggle_more_info_label: 'Hulp bij reguliere expressies',

			return_to_map: 'Terug naar kaart',
			return_to_results: 'Terug naar resultaten',

			keyword: 'dialectwoord',
			Keyword: 'Dialectwoord',
			keywords: '0 dialectwoorden | 1 dialectwoord | {count} dialectwoorden',
			keywords_title: 'Dialectwoorden',
			concept: 'concept',
			Concept: 'Concept',
			concepts: '0 concepten | 1 concept | {count} concepten',
			concepts_title: 'Concepten',

			sort: 'Sorteren',
			sort_value: {
				12: 'Sorteer op aantal voorkomens oplopend',
				21: 'Sorteer op aantal voorkomens aflopend',
				az: 'Sorteer op naam a-z',
				za: 'Sorteer op naam z-a'
			},

			yes: 'ja',
			no: 'nee',

			group: 'Groep',
			symbol: 'Symbool',
			size: 'Grootte',
			color: 'Kleur',
			number_of_occurances: 'Aantal voorkomens',
			variants: 'Dialectvarianten',
			visible: 'Zichtbaar',
			hidden: 'Verborgen',

			cookie_consent: 'Mogen we cookies plaatsen?<br>Er wordt alleen anonieme informatie verzameld om de werking van de site te verbeteren.<br><a href="https://ivdnt.org/privacyverklaring/#cookiebeleid" target="_blank">Meer informatie.</a>'

		},

		export: {
			title: 'Zoekopdracht',
			legend: 'Legenda',
			parameters: 'Parameters',
		},


		map: {
			show_search_options: 'Zoekopties tonen',
			hide_search_options: 'Zoekopties_verbergen',

			more_info: 'Ga&nbsp;naar&nbsp;resultaten',
			more_info_help: 'Bekijk uitgebreidere informatie over de resultaten',
			no_results_for_term: `Geen resultaten voor {term} gevonden.`,

			edit_filters: 'Verfijn resultaten',
			clear_filters: 'Filters wissen',

			show_map_options: 'Kaartopties tonen',
			hide_map_options: 'Kaartopties verbergen',

			map_options_enable_clusters_label: 'Symbolen spreiden',
			map_options_enable_clusters_help:
`Door dit vakje aan of uit te vinken, kan je de (verschillende) symbolen die op één plaats staan een beetje verplaatsen
om een beter beeld te krijgen van de symbolen.`,

			map_options_expand_clusters_always_label: 'Symbolen niet samenvoegen tot cijfers',
			map_options_expand_clusters_always_help:
`Als er grote hoeveelheden symbolen zijn voor één plaats, wordt een cijfer gegeven i.p.v. het symbool.
Wilt u de cijfers toch door de symbolen vervangen, vink dan dit vak aan.`,

			map_options_cluster_spread_label: 'Spreiding',
			map_options_cluster_spread_help: `Stel in hoe ver overlappende symbolen uit elkaar getrokken moeten worden op de kaart`,

			map_options_symbol_size_label: 'Symboolgrootte',
			map_options_symbol_size_help: `Stel de grootte van de symbolen in`,

			map_options_cluster_identical_label: `Identieke symbolen samenvoegen`,
			map_options_cluster_identical_help: `Teken slechts één voorkomen van ieder symbool per locatie`,

			map_options_number_of_occurances: 'Aantal voorkomens',
			map_options_number_of_occurances_help:
`Met de schuifbalk kun je op basis van hoe vaak een trefwoord is aangetroffen, kiezen welke woorden je wil laten zien op de kaart.
Door de linkerknop naar rechts te schuiven geef je de minimumfrequentie aan van de te karteren woorden.
Door de rechterknop naar links te verschuiven geef je de maximumfrequentie aan van de te karteren woorden.`,

			map_options_select_search_area_label: 'Selecteer gebied',
			map_options_select_search_area_help:
`Klik hier, plaats je muis op de kaart en selecteer door te slepen met de muis, welk gebied je wil laten zien op de kaart.
Je kan ook een gebied kiezen door de control-toets ingedrukt te houden, en over de kaart te slepen met de muis.
Door nogmaal te klikken of met de escape-toets kun je een eenmaal begonnen gebiedsselectie annuleren.`,

			map_options_remove_search_area_label: 'Gebied verwijderen',
			map_options_remove_search_area_help: 'Klik hier om het geselecteerde gebied te verwijderen',

			map_options_center_map_label: `Centreren`,
			map_options_center_map_help: `Centreer de kaart zo dat alle gevonden symbolen zichtbaar zijn`,

			map_options_print_label: `Printversie`,
			map_options_print_help: `Download de kaart en de legenda in pdf-formaat`,

			map_layer_area_border: `Omkadering kaart`,

			legend_title_dir: {
				0: 'Dialectwoorden',
				1: 'Concepten',
			},

			go_to_edit_groups: `Door op het potlood te klikken kom je in een kaartbewerkingsomgeving waarin je de kartering van de zoekresultaten kunt manipuleren.`,

			error_pdf_export: 'Fout tijdens exporteren van pdf',
		}
	}
};

Vue.use(VueI18n);
export default new VueI18n({
	locale: 'nl',
	messages
});

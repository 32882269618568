
import Vue from 'vue';
import LongString from '@/components/LongString.vue';
import LongList from '@/components/LongList.vue';

import { ResultConcept, ResultKeyword } from '@/scopes/SearchScopeTypes';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';

export default Vue.extend({
	components: {
		LongString,
		LongList
	},
	props: {
		...SearchScopeChildProps,
		concept: Object as () => ResultConcept,
		keywords: Array as () => ResultKeyword[],
	},
	data: () => ({
		showDetails: false,
	}),
});

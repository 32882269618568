import html2canvas from 'html2canvas';
import L from 'leaflet';

import { CustomMarker } from '@/components/leaflet-extensions';
import { decompose, localMatrix, matrix } from './matrix';

/** Iterate over all tiles in the map, and returns them one by one with their scale and translation relative to the map container.
Assuming the map container itself is not translated. */
export async function* getMapTiles(map: L.Map) {
	// might be interesting
	// https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames

	const currentTiles = map.getPane('tilePane')!.querySelectorAll('img');
	for (const img of currentTiles) {
		const t = decompose(matrix(img));
		if (img.crossOrigin !== 'anonymous') {
			img.crossOrigin = 'anonymous';
			// await loading of image, since it needs to reload if we change the crossOrigin
			// and if we draw before loading, the canvas will be blank
			await new Promise(resolve => img.onload = resolve);
		}

		// the image has a base scale (i.e. the resolution), but sometimes height= and width= are overridden on the <img> element.
		// which has the effect of scaling the image on top of the transform
		// we need to account for this scale, otherwise the image will be the wrong size
		const scaleMod = img.width / img.naturalWidth;
		let renderScale = t.scaleX * scaleMod;

		// XXX Firefox workaround
		// Sometimes tiles are rendered a fraction of a pixel too small (up to 1px) in firefox, which causes semi-transparent lines to appear between tiles
		// up the size by one pixel, so we overlap a little bit, an overlap is less noticable than than having black lines
		const outputSize = img.naturalWidth * renderScale;
		renderScale = Math.ceil(outputSize) / img.naturalWidth;

		yield {
			img,
			scale: renderScale,
			translateX: t.translateX,
			translateY: t.translateY,
		};
	}
}

/** Export the map by copying all tiles to the target canvas, then rendering the icons on top of the canvas too. */
export async function renderMap(map: L.Map, exportScale: number, icons: L.LayerGroup<CustomMarker>, canvas: HTMLCanvasElement = document.createElement('canvas')): Promise<HTMLCanvasElement> {
	if (!map) return Promise.resolve(canvas);
	const mapSize = map.getSize();

	canvas.width = mapSize.x * exportScale; 
	canvas.height = mapSize.y * exportScale; 
	canvas.style.imageRendering = 'high-quality'; // this doesn't seem to do anything, but it can't hurt.
	
	const ctx = canvas.getContext('2d')!;
	
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	ctx.scale(exportScale, exportScale);
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';

	for await (const {img, scale, translateX, translateY} of getMapTiles(map)) {
		ctx.save();
		ctx.translate(Math.floor(translateX), Math.floor(translateY));
		ctx.scale(scale, scale);
		ctx.drawImage(img, 0, 0);
		// debug - see where tiles end up.
		// ctx.strokeRect(0, 0, img.naturalWidth, img.naturalHeight);
		ctx.restore();
	}
	
	// draw icons, icons always seem to render centered on the canvas, so copy the offset from leaflet
	// unsure how this works inside leaflet, but this seems to generate the correct results for our purpose
	// @ts-ignore
	// let icons: L.LayerGroup<CustomMarker> = this.$refs['map-preview'].$refs.map.mapData.iconLayer;
	const baseOffset = decompose(matrix(map.getPane('overlayPane')!));
	ctx.save();
	ctx.translate(baseOffset.translateX, baseOffset.translateY);
	ctx.scale(baseOffset.scaleX, baseOffset.scaleY);
	icons.eachLayer(m => {
		const marker: CustomMarker = m as any;
		marker.drawOnCanvas(ctx, true);
	});
	ctx.restore();

	return Promise.resolve(canvas);
}

export async function renderLegend(legend: HTMLElement, exportScale: number, options: {
	canvas: HTMLCanvasElement;
	style?: Partial<CSSStyleDeclaration>;
} = {canvas: document.createElement('canvas')}) {
	const originalLegend = legend;
	const originalContent = originalLegend.querySelector('.legend-preview') as HTMLElement;
	
	// html2canvas seems to mess up when elements are scaled sometimes
	// and also when elements are not part of dom
	// so we clone the legend, preprocess it a litte to get rid of the scale, and render the clone instead
	const cloneLegend = originalLegend.cloneNode(true) as HTMLElement;
	const canvas = options.canvas;
	cloneLegend.style.transform = '';
	cloneLegend.style.position = 'fixed';
	cloneLegend.style.top = '-100000';
	cloneLegend.style.left = '-100000';

	const cloneContent = cloneLegend.querySelector('.legend-preview') as HTMLElement;
	cloneContent.style.transform = '';

	// do first so getComputedStyle and offsetWidth/Height works
	document.body.appendChild(cloneLegend);
	Object.assign(cloneLegend.style, options.style || {});

	cloneLegend.style.height = cloneContent.offsetHeight + 'px';
	cloneLegend.style.width = cloneContent.offsetWidth + 'px';
	
	// XXX: input rendering does not work correctly: https://github.com/niklasvh/html2canvas/issues/1667
	// seems a fix is underway, but merging it hasn't happened yet.
	[...cloneLegend.querySelectorAll('input')].forEach((i: HTMLInputElement) => {
		const title = document.createElement('div');
		const styles = getComputedStyle(i);
		title.style.fontFamily = styles.fontFamily;
		title.style.fontSize = styles.fontSize;
		title.style.fontWeight = styles.fontWeight;
		title.style.fontStyle = styles.fontStyle;
		title.style.color = styles.color;
		title.style.textAlign = styles.textAlign;
		title.style.lineHeight = styles.lineHeight;
		title.style.padding = styles.padding;
		title.style.margin = styles.margin;
		title.style.border = styles.border;
		title.style.borderRadius = styles.borderRadius;
		title.style.whiteSpace = 'nowrap';
		
		title.textContent = i.value!;
		i.classList.forEach(c => title.classList.add(c));
		i.replaceWith(title);
	});
	[...cloneLegend.querySelectorAll('.scale-handle') as any].forEach((el: HTMLElement) => el.remove());

	const trans = decompose(localMatrix(originalContent));
	canvas.width = Math.ceil(cloneLegend.offsetWidth * trans.scaleX * exportScale);  
	canvas.height = Math.ceil(cloneLegend.offsetHeight * trans.scaleY * exportScale);

	return html2canvas(cloneLegend, {
		canvas: canvas,
		logging: false,
		allowTaint: true,
		useCORS: true,
		width: canvas.width,
		height: canvas.height,
		backgroundColor: 'transparent',
		scale: trans.scaleX * exportScale,
	}).then(legendCanvas => {
		cloneLegend.remove();
		return legendCanvas;
	})
}
// Missing polyfills before we import anything else.
import 'core-js';

import 'element-closest/browser';
import 'css.escape';
import 'whatwg-fetch';

// tslint:disable

// some of our own, depending on what we need
// @ts-ignore;
Array.prototype.flat = Array.prototype.flat || Array.prototype.flatten || Array.flatten;

// custom events
// source: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
(() => {
	if (typeof window.CustomEvent !== 'function') {
		function CustomEvent(event: any, params: any) {
			params = params || { bubbles: false, cancelable: false, detail: undefined };
			const evt = document.createEvent('CustomEvent');
			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
			return evt;
		}

		CustomEvent.prototype = window.Event.prototype;

		// @ts-ignore
		window.CustomEvent = CustomEvent;
	}
})();

// element.replaceWith
(() => {
	function ReplaceWithPolyfill(this: HTMLElement) {
		var parent = this.parentNode, i = arguments.length, currentNode;
			if (!parent) return;
			if (!i) parent.removeChild(this); // if there are no arguments
			while (i--) { // i-- decrements i and returns the value of i before the decrement
				currentNode = arguments[i];
				if (typeof currentNode !== 'object'){
					currentNode = this.ownerDocument.createTextNode(currentNode);
				} else if (currentNode.parentNode){
					currentNode.parentNode.removeChild(currentNode);
				}
				// the value of "i" below is after the decrement
				if (!i) // if currentNode is the first argument (currentNode === arguments[0])
					parent.replaceChild(currentNode, this);
				else // if currentNode isn't the first
					parent.insertBefore(currentNode, this.nextSibling);
			}
		}

		if (!Element.prototype.replaceWith) {
			Element.prototype.replaceWith = ReplaceWithPolyfill;
		}
		if (!CharacterData.prototype.replaceWith) {
			CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
		}
		if (!DocumentType.prototype.replaceWith) {
			DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
		}
})();



import Vue from 'vue';
export default Vue.extend({
	props: {
		// we use "value" so that v-model works in our parent
		value: Object as () => {
			input: string;
			set: Set<string>;
		},
		suggestions: {
			type: Array as () => string[],
			default: () => [] as string[]
		},
		loading: Boolean,
		id: {
			type: String,
			required: true
		},
		blurOnSubmit: Boolean
	},
	data: () => ({
		isOpen: false,
		hasFocus: false,
		isPendingFocusout: false,
	}),
	computed: {
		open(): boolean { return this.suggestions.length > 0 && this.isOpen; },
		inputValue: {
			get(): string { return this.value.input || ''; },
			set(v: string) { this.$emit('input', {...this.value, input: v }); }
		},
		setValue(): string[] { return Array.from(this.value.set.values()); },
	},
	methods: {
		handleFocusOut() {
			this.isPendingFocusout = true;
			requestAnimationFrame(() => {
				if (this.isPendingFocusout) {
					this.isPendingFocusout = this.isOpen = this.hasFocus = false;
				}
			});
		},
		handleFocusIn() {
			this.isPendingFocusout = false;
			this.isOpen = this.hasFocus = true;
		},
		handleAddValue(v: string|Event) {
			if (typeof v !== 'string') { v = this.inputValue; }
			if (!v) { return; }

			const clearInput = v === this.inputValue;
			const newValue = {
				set: new Set(this.value.set).add(v),
				input: clearInput ? '' : this.inputValue
			};

			this.$emit('input', newValue);
		},
		handleRemoveValue(v: string) {
			if (this.value.set.has(v)) {
				const newValue = {...this.value, set: new Set(this.value.set) };
				newValue.set.delete(v);
				this.$emit('input', newValue);
			}
		},
		placeLastSetValueInInput(e: KeyboardEvent) {
			if (this.inputValue || !this.setValue.length) { return; }
			e.preventDefault();

			const v = this.setValue[this.setValue.length -1];
			const setWithoutV = new Set(this.value.set);
			setWithoutV.delete(v);
			this.$emit('input', {
				input: v,
				set: setWithoutV
			});
		},
	},
	watch: {
		value: {
			deep: true,
			handler() { this.$forceUpdate(); }
		}
	},
});

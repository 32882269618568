import { render, staticRenderFns } from "./MapSettings.vue?vue&type=template&id=1c08f53b&"
import script from "./MapSettings.vue?vue&type=script&lang=ts&"
export * from "./MapSettings.vue?vue&type=script&lang=ts&"
import style0 from "./MapSettings.vue?vue&type=style&index=0&id=1c08f53b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
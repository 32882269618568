<template>
	<div class="overlay">
		<div class="spinner-container">
			<span class="fa fa-spinner fa-spin fa-4x"></span>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.overlay {
	background: rgba(255,255,255,0.5);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	/* pointer-events: none; */
	user-select: none;
}

.spinner-container {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 0.4rem;
	box-shadow: 0 0 0.5rem rgba(0,0,0,0.5);
	border-radius: 100%;
	z-index: 101;
	position: absolute;
	background: white;
}
</style>
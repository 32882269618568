import axios, { AxiosPromise, Canceler } from 'axios';
import Axios from 'axios';
import { MapOf } from './Util';

export enum SearchDirection {
	s2d = '0',
	d2s = '1',
}

export class DSDDQuery {
	constructor(
		private host = `${window.location.protocol}//${window.location.host}`,
	) {}

	private url(path: string): string {
		return `${dsddconfig.serverHost || this.host}/${dsddconfig.serverPath}/${path}`;
	}
	get(path: 'suggest/keywords'|'suggest/concepts', query: {word: string}): {
		cancel: Canceler,
		req: AxiosPromise<{suggestions: string[]}>;
	};
	get(path: 'keywords', query: MapOf<string|string[]|boolean|number|undefined>): {
		cancel: Canceler;
		req: AxiosPromise<{
			/** Only when include_facets= true and actual results found (numFound > 0) */
			facets?: {
				bucketed: {
					country: MapOf<number>;
					dictionary: MapOf<number>;
					place: MapOf<number>;
					province: MapOf<number>;
					topic_group: MapOf<number>;
				};
				stats: {
					/** Total number of matched concepts */
					'concept.count': number;
				};
			},
			keywords: Array<{
				id: string;
				display: string;
				data?: Array<{
					country: string;
					dictionary: string;
					id: string;
					kloeke: string;
					place: string;
					point: string;
					province: string;
				}>;
				/** Number of matched occurances/points on the map */
				'data.count': number;
				lexical_variants: string[];
			}>;
			/** Total number of matched keywords */
			numFound: number;
			start: number;
		}>;
	};
	get(path: 'concepts', query: MapOf<string|string[]|boolean|number|undefined>): {
		cancel: Canceler;
		req: AxiosPromise<{
			/** Only when include_facets= true and actual results found (numFound > 0) */
			facets?: {
				bucketed: {
					country: MapOf<number>;
					dictionary: MapOf<number>;
					place: MapOf<number>;
					province: MapOf<number>;
					topic_group: MapOf<number>;
				};
				/** Total number of matched keywords */
				stats: {
					'keyword.count': number;
				};
			},
			concepts: Array<{
				definition: string;
				display: string;
				id: string;
				keywords: Array<{
					id: string;
					display: string;
					data?: Array<{
						country: string;
						dictionary: string;
						id: string;
						kloeke: string;
						place: string;
						point: string;
						province: string;
					}>;
					/** Number of matched occurances/points on the map */
					'data.count': number;
					lexical_variants: string[];
				}>;
				lemmata: Array<{
					definition: string;
					definitionComplement: string;
					dictionary: string;
					display: string;
					id: string;
					publicationId: string;
				}>;
				topic_group: string;
			}>;
			/** Total number of matched concepts */
			numFound: number;
			start: number;
		}>;
	};
	get(path: string, query: MapOf<string|string[]|boolean|number|undefined>|{word: string}) {
		const canceler = Axios.CancelToken.source();
		let url = this.url(path);
		const querystring =
			Object
			.entries(query)
			.flatMap(([k, v]) => {
				let vs: string[]|undefined;
				if (Array.isArray(v) && v.length) { vs = v.map(vv => encodeURIComponent(vv)); }
				else if (typeof v === 'string' && v.length) { vs = [encodeURIComponent(v)]; }
				else if (typeof v === 'number') { vs = [encodeURIComponent(v)]; }
				else if (v === true) { vs = ['true']; }

				if (vs != null && vs.length === 0) { return encodeURIComponent(k); }
				else if (vs != null) { return vs.map(ve => `${encodeURIComponent(k)}=${ve}`); }
				return [];
			})
			.join('&');

		if (querystring) {
			url += '?' + querystring;
		}

		return {
			req: axios.get(url, {
				cancelToken: canceler.token,
			}),
			cancel: canceler.cancel
		};
	}
}

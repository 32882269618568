import Vue from 'vue';
import VueRouter, { Location as VueRouterLocation, Route, Location, RouteConfig } from 'vue-router';
import qs, { IStringifyOptions, IParseOptions } from 'qs';
import cloneDeep from 'clone-deep';
import { LatLngBounds, latLngBounds, latLng } from 'leaflet';

import i18n from '@/i18n';
import SearchScope from '@/scopes/SearchScope.vue';
import EditGroupsView from '@/views/edit-groups/EditGroupsView.vue';
import DetailedResultsView from '@/views/detailed-results/DetailedResultsView.vue';
import ExportView from '@/views/export/ExportView.vue';

import AdvancedFormView from '@/views/advanced-form/AdvancedFormView.vue';
import MapView from '@/views/map/MapView.vue';

// async loading of these pages upon request
const AboutPage = () => import(/* webpackChunkName: "aboutpage" */ '@/pages/AboutPage.vue');
const HelpPage = () => import(/* webpackChunkName: "helpPage" */ '@/pages/HelpPage.vue');

import { MapOf, mapReduce } from '@/Util';
import { SearchDirection } from './DSDDQuery';

Vue.use(VueRouter);

type RouterInput<T> = { [K in keyof T]?: T[K]|null };
type RouterOutput<I, O extends {[K in keyof I]-?: any}> = { [K in keyof I]: O[K]|undefined; };

export namespace SearchScope {
	/**
	 * This is what goes into the router when using .push or .replace,
	 * Also what should come out of decoding the query string
	 */
	export type RouteQueryParams = RouterInput<{
		dir: string;
		word: string[],
		/** A mapping between FacetCategory Ids and a list of values (not ids!) selected within that category */
		filter: MapOf<string[]>;
		area: LatLngBounds;
		/** 1-indexed!, might also be undefined for "no pages, all results" */
		page: number;
		sort: '12'|'21'|'az'|'za';
		/** concept_id if dir == s2d, keyword_id if dir == d2s */
		id?: string;
	}>;

	/** This is what we send into the actual query string, it's simplified for more readable urls */
	export type QueryStringParams = RouterOutput<RouteQueryParams, {
		dir: string|undefined;
		word: string[]|undefined,
		filter: MapOf<string[]>|undefined;
		area: [string, string, string, string]|undefined;
		page: number|undefined;
		sort: string;
		id: string|undefined;
	}>;

	/** This is what the router injects into the components. Is mapped from the router (query-)params */
	export type ComponentProps = {
		searchDirection: SearchDirection;
		searchWords: string[],
		searchFilters: MapOf<string[]>;
		searchArea: LatLngBounds;
		/** 1-indexed!, might also be undefined for "no pages, all results" */
		searchPage: number;
		searchSort: '12'|'21'|'az'|'za';
		/** concept_id if dir == s2d, keyword_id if dir == d2s. Usually undefined */
		searchId: string;
	};

	export interface RouterLocation extends Omit<VueRouterLocation, 'query'> { query?: RouteQueryParams; }
}

const qssOptions: IStringifyOptions = {
	allowDots: true,
	arrayFormat: 'repeat',
	addQueryPrefix: true,
	sort: (a, b) => a.localeCompare(b),
};

const qspOptions: IParseOptions = {
	allowDots: true,
	// comma: true
};

// TODO: nederlandse urls?
/**
 * /search -- is when no results are loaded
 * /search/:query/map -- is when results are loaded
 * /search/:query/groups
 * /search/:query/details
 * /search/:query/filter
 *
 * /preferences
 * /login -- maybe?
 * /profile/:username -- maybe?
 * /history
 * /saved -- manage saved group configs and queries?
 */
const router = new VueRouter({
	base: process.env.NODE_ENV === 'development' ? undefined : '/DSDD',

	// Landing page
	routes: [{
		// we could make some fun full-page landing thing here
		// with a nice picture and a central search bar and such
		name: 'home',
		path: '/',
		redirect: '/search'
	}, {
		path: '/search',
		component: SearchScope,
		props: ({query}: {query: SearchScope.RouteQueryParams}): SearchScope.ComponentProps => ({
			// Note, they're not always set, but we assume the Scope root has defaults for these props
			searchDirection: query.dir as SearchDirection,
			searchWords: query.word!,
			searchFilters: query.filter!,
			searchArea: query.area!,
			searchPage: query.page!,
			searchSort: query.sort!,
			searchId: query.id!
		}),
		beforeEnter(from, to, next) {
			// make sure "page" query parameter is a valid number
			if (to.query.page != null && (isNaN(Number(to.query.page)) || Number(to.query.page) <= 0)) {
				return next(copyLocationProperties({query: {page: '1'}}, from));
			} else {
				return next();
			}
		},
		meta: {
			getPageTitle(route: SearchScope.RouterLocation): string {
				return route.query && route.query.word && route.query.word.length === 1
					? `${route.query.word[0]} - ${i18n.t('common.title')}`
					: i18n.t('common.title').toString();
			}
		},

		children: [{
			name: 'search',
			path: '/search',
			component: AdvancedFormView,
			meta: { facets: true }
		}, {
			name: 'details',
			path: '/search/details',
			component: DetailedResultsView,
		}, {
			name: 'map',
			path: '/search/map',
			component: MapView,
			meta: { facets: true },
		}, {
			name: 'export',
			path: '/search/map/export',
			component: ExportView,
		}, {
			name: 'edit-groups',
			path: '/search/map/groups',
			component: EditGroupsView,
		}, {
			path: '*', // match anything
			redirect: to => ({...to, name: 'search'})
		}]
	}, {
		name: 'help',
		path: '/help',
		component: HelpPage,
		meta: {
			getPageTitle(): string { return `${i18n.t('common.help')} - ${i18n.t('common.title')}`; }
		}
	}, {
		name: 'about',
		path: '/about',
		component: AboutPage,
		meta: {
			getPageTitle(): string { return `${i18n.t('common.about')} - ${i18n.t('common.title')}`; }
		}
	}, {
		path: '*',
		redirect: to => ({...to, name: 'search'})
	}],

	mode: 'history',
	parseQuery(q): any { // Realistically not any, any type of the .*ScopeQueryParams
		const decoders: {
			[K in keyof SearchScope.QueryStringParams]?: (v: NonNullable<SearchScope.QueryStringParams[K]>) => SearchScope.RouteQueryParams[K]
		} = {
			area: v => latLngBounds(latLng(Number(v[0]), Number(v[1])), latLng(Number(v[2]), Number(v[3]))),
			filter: f => {
				const entries = Object.entries(f) as Array<[string, string|string[]]>;
				if (!entries.length) { return undefined; }
				// Fix filters with a single value from "value" to ["value"]
				return mapReduce(entries, '0', ([_k, v]) =>  Array.isArray(v) ? v : [v]);
			},
			// we need the word query parameter to be an array, if it exists
			// but when there is only one value, qs unpacks it into a string. So map it back to an array.
			word: v => v ? [v].flat() : undefined,
			page: v => v != null ? Math.max(1, Number(v)) : undefined
		};

		const toBeDecoded: SearchScope.QueryStringParams = qs.parse(q, qspOptions);
		return Object.entries(toBeDecoded).reduce((output, [k, v]) => {
			const decoder = (decoders as any)[k];
			output[k] = decoder ? decoder(v) : v;
			return output;
		}, {} as any);
	},
	stringifyQuery(p: SearchScope.RouteQueryParams) {
		const encoders: {
			[K in keyof SearchScope.RouteQueryParams]?: (v: NonNullable<SearchScope.RouteQueryParams[K]>) => SearchScope.QueryStringParams[K]
		} = {
			filter: v => Object.keys(v).length ? v : undefined,
			area: v => [
				v.getNorthWest().lat.toFixed(4),
				v.getNorthWest().lng.toFixed(4),
				v.getSouthEast().lat.toFixed(4),
				v.getNorthEast().lng.toFixed(4)
			]
		};

		const mapped = Object.entries(p).reduce((output, [k, v]) => {
			const encoder: any = (encoders as any)[k];
			output[k] = v != null ? encoder ? encoder(v) : v : undefined;
			return output;
		}, {} as MapOf<any>);

		if (mapped.sort === 'az') { delete mapped.sort; } // is the default, no need to show

		return qs.stringify(mapped, qssOptions);
	},
});

// Update page title when required
router.afterEach((to, from) => {
	// can't use to.meta directly, because the getPageTitle might be defined in the parent route
	// currentRoute.matched is an array of all the parents + the current route in that order
	const route = router.currentRoute.matched.find(r => r.meta && r.meta.getPageTitle);
	if (route) { document.title = route.meta.getPageTitle(to); }

	// if a new search is made, notify plausible (since it doesn't track query parameters)
	if (to.query.word && to.query.word !== from?.query.word && Vue.$plausible) {
		Vue.$plausible?.trackEvent('search', { props: { word: to.query.word!.toString() }});
	}
});

/**
 * We store the last search performed in both the map view and the form view.
 * See the .beforeEach just below.
 * The lastSearch and lastMapSearch variables are updated every time the 'search' (for the search form) or 'map' (for the map view) route is used.
 * These are made observable, so we can "listen" to them in a Vue component.
 * In App.vue, in the main navbar, we use these links instead of a "blank slate" empty form
 * This allows the user to go back and forth between the map and form views, and keep (and continue with) a separate search in both windows.
 * A nice effect of rendering the links directly is that opening them in a new tab or window also works
 */
export const lastSearch = Vue.observable({
	lastSearch: {name: 'search'} as Route|RouteConfig,
	lastMapSearch: {name: 'map'} as Route|RouteConfig,
});

router.beforeEach((to, _from, next) => {
	if (to.name === 'search') { lastSearch.lastSearch = to; }
	if (to.name === 'map') { lastSearch.lastMapSearch = to; }
	next();
});

/**
 * Copy over a parameter from the previous route, if the new route doesn't explicitly clear it
 *
 * @param to the new route options/query
 * @param from the old route options/query
 * @param k the key
 */
function set(to: MapOf<any>, from: MapOf<any>, k: string): boolean {
	// Only delete when prop is explicitly set to null in the new state
	const shouldDelete = k in to && to[k] === null;
	// Only migrate when the prop is explictly set to undefined, or missing altogether
	// Also the previous state should have some value to migrate.
	const shouldMigrate = to[k] === undefined && from[k] != null;

	if (shouldDelete) { delete to[k]; }
	if (shouldMigrate) { to[k] = from[k]; }
	return shouldDelete || shouldMigrate;
}

/** Copy all parameters from the "from" location if the "to" location doesn't specify or clear them (setting a param or query to null clears it) */
export function copyLocationProperties(to: Location, from: Route) {
	to = cloneDeep(to);
	to.params = to.params || {};
	to.query = to.query || {};

	new Set([...Object.keys(to.params), ...Object.keys(from.params)])
	.forEach(k => set(to.params!, from.params, k));
	new Set([...Object.keys(to.query), ...Object.keys(from.query)])
	.forEach(k => set(to.query!, from.query, k));
	return to;
}

export {
	SearchScope,

	qspOptions,
	qssOptions,
	router,
};


import Vue from 'vue';
// object-hash typings don't work with ts 3.9
// @ts-ignore
import ObjectHash from 'object-hash';
import { AxiosError } from 'axios';
import { PropValidator } from 'vue/types/options';
import { LatLngBounds } from 'leaflet';

import { SearchDirection } from '@/DSDDQuery';
import { SearchScope } from '@/router';
import { MapOf } from '@/Util';

import { ResultConcept, ResultKeyword, ResultPoint, ResultVisual } from './SearchScopeTypes';
import { facetResults, DerivedResultsComputer } from './SearchScopeGetResults';
import { saveVisuals } from './SearchScopeVisuals';

/** Props of the SearchScope component itself - i.e. search parameters */
const props: {[K in keyof SearchScope.ComponentProps]: PropValidator<SearchScope.ComponentProps[K]>} = {
	searchDirection: { type: String as () => SearchDirection, default: SearchDirection.s2d },
	searchWords: Array as () => string[],
	searchFilters: Object as () => MapOf<string[]>,
	searchArea: Object as () => LatLngBounds,
	/** 1-indexed!, might also be undefined for "no pages, all results" */
	searchPage: Number,
	searchSort: { type: String as () => '12'|'21'|'az'|'za', default: 'az' },
	searchId: String
};

// The surrounding anonymous function only serves to validate that every entry is a valid prop definition
// We need to run it through a function to capture the type,
// if we were to directly annotate the object as {[propName: string]: PropValidator<any>} then
// we wouldn't have the valid keys and their types
// NOTE: most of these are computed in SearchScopeGetResults.ts - make sure these stay in sync!
/** Props of children of the SearchScope component - i.e. search parameters + results */
export const SearchScopeChildProps = (<T extends MapOf<PropValidator<any>>>(v: T): T => v)({
	...props,

	loading: Boolean,
	/** Whether results were found */
	hasResults: Boolean,
	/** Whether no results were found */
	hasNoResults: Boolean,
	/** Whether a search has been executed (i.e. not looking at the "blank slate page") */
	hasSearch: Boolean,

	resultKeywordsArray: Array as () => undefined|ResultKeyword[],
	resultConceptsArray: Array as () => undefined|ResultConcept[],
	resultPointsArray: Array as () => undefined|ResultPoint[],
	resultKeywords: Object as () => undefined|MapOf<ResultKeyword>,
	resultConcepts: Object as () => undefined|MapOf<ResultConcept>,
	resultVisuals: Object as () => undefined|MapOf<ResultVisual>,
	resultBounds: Object as () => L.LatLngBounds,
	resultKeywordsByGroup: Array as () => undefined|Array<{group: string|null, keywords: string[], count: number}>,
	totalConceptCount: Number as () => undefined|number,
	totalKeywordCount: Number as () => undefined|number,

	error: Error as () => Error|undefined
});

// INT in Leiden
const defaultLat =  52.1577;
const defaultLong = 4.48525;
const defaultLongDelta = 0.005;
const defaultLatDelta = 0.0025;

export default Vue.extend({
	props,
	data: () => ({
		error: undefined as undefined|Error,

		results: new DerivedResultsComputer(),
		defaultResultBounds: new LatLngBounds(
			{ lat: defaultLat - defaultLatDelta, lng: defaultLong - defaultLongDelta },
			{ lat: defaultLat + defaultLatDelta, lng: defaultLong + defaultLongDelta}
		),
	}),
	computed: {
		/**
		 * Because our props are reconstructed every time anything in the url changes (such as the view we're using),
		 * we can't rely on vue change detection.
		 * To circumvent this, hash the relevant values and detect changes in the hash
		 */
		queryHash(): string {
			return ObjectHash.MD5([
				this.searchArea,
				this.searchWords,
				this.searchFilters,
				this.searchDirection,
				this.searchPage,
				this.searchId
			]);
		},
		facetProps(): { params: any, update: boolean } {
			return {
				params: {
					filters: this.searchFilters,
					dir: this.searchDirection,
					terms: this.searchWords
				},
				update: !!this.$router.currentRoute.meta!.facets
			};
		},
	},
	methods: {
		handleVisualsChanged(changeSet: MapOf<ResultVisual>) {
			Object.assign(this.results.resultVisuals!, changeSet);
			saveVisuals(this.searchWords, this.searchFilters, this.results.resultVisuals!);
		},
		handleReloadFacets() { if (this.facetProps.update) { facetResults.next(this.facetProps.params); } }
	},
	watch: {
		facetProps: {
			immediate: true,
			deep: true,
			handler() {
				if (this.facetProps.update) {
					facetResults.next(this.facetProps.params);
				}
			}
		},
		queryHash: {
			immediate: true,
			handler() {
				this.results.next({
					searchWords: this.searchWords,
					searchFilters: this.searchFilters,
					searchDirection: this.searchDirection,
					searchArea: this.searchArea,
					searchPage: this.searchPage,
					searchId: this.searchId
				});
			},
		},
		'results.error'(e?: AxiosError|Error) {
			if (!e) { return; } // error cleared
			this.$bvToast.toast(`${this.$t('searching.error')}: ${e.stack ? e.message : (e as AxiosError).response ? e.message : this.$t('common.no_internet')}`, {
				title: this.$t('searching.error').toString(),
				appendToast: true,
				variant: 'warning',
			});
			if (e.stack) { console.error(e); }
		},
		searchSort() {
			this.results.searchSort = this.searchSort;
		},
	}
});


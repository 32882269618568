
import Vue from 'vue';

import { elementToSVG, inlineResources } from 'dom-to-svg';

import L from 'leaflet';
import VueSlider from 'vue-slider-component';

import MapSettings from '@/components/MapSettings.vue';

import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';
import { ResultVisual } from '@/scopes/SearchScopeTypes';
import { MapOf } from '@/Util';
import { NextGroupSummaryOrKeyword, doTableLayout, DomCell, TableEntry } from './columnlayout';
import { CustomMarker, DetailControl } from '@/components/leaflet-extensions';

import { decompose, localMatrix } from './matrix';
import { renderLegend, renderMap } from './export';
import { Subscription } from 'rxjs';



export default Vue.extend({
	name: 'ExportView',
	components: { VueSlider, MapSettings },
	props: {
		...SearchScopeChildProps,
	},
	data: () => ({
		refs: {
			/** The main export container. Has the legend, padding, and map. */
			'container': null as any as HTMLDivElement,
			/** The Vue MapSettings component */
			'container.map.settings': null as any as InstanceType<typeof MapSettings>,
			/** The Leaflet Map object */
			'container.map.map': null as any as L.Map,
			/** The legend div. Contains the title and the table. */
			'container.legend': null as any as HTMLDivElement,
			/** 
			 * The Legend topmost parent element. Serves as a flow placeholder, 
			 * because a transform() does not change document flow, and we do want this to happen.
			 */
			'container.legend.placeholder': null as any as HTMLDivElement,
			/** The table within the legend. */
			'container.legend.table': null as any as HTMLTableElement,
			/** Padding in between legend and map. */
			'container.padding': null as any as HTMLDivElement,
		},

		// Legend stuff
		legendPlacement: 'bottom' as 'top' | 'left' | 'right' | 'bottom' | 'overlay',
		/** Number of columns in the legend */
		legendColumns: 2,
		/** Show group contents? */
		showGroupContents: false,
		showWordCounts: false,
		/** Alternative legend layout */
		groupColumns: false,
		transparentBackground: false,
		// copy of the value in MapSettings, populated through event
		// required to sync the legend contents with what is shown on the map
		showOnlyWordsWithOccurances: [0, 0] as [number, number],
		
		// general settings,not specific to map or legend
		title: 'Titel' as string,
		showTitle: true,
		titleSize: 1,
		exportScale: 1,
		exportMode: 'png',
		exportMargin: 2,

		// Leaflet plugin/extension we use to increase por decrease the detail level of tiles that are drawn by the map
		// Ex. if exportScale is 2, our export will be double the size of the map on screen
		// So we draw all tiles at one detail level higher than the current zoom would require
		// This way, when we export at double size, the detail will be appropriate.
		zoomOffsetControl: null as null|DetailControl,
		
		resizeObserver: null as null|ResizeObserver,
		subs: [] as Subscription[],

		// stuff for legend size slider
		// bleh
		// available width inside the map/legend container. Accounts for padding, border etc.
		availableContainerWidth: 0,
		// width of the legend after scaling, in px
		legendScaledWidth: 0,

		exporting: false,

		legendScaleFactorModel: 1,
	}),
	computed: {
		/** Do not do highlighting in our preview map */
		visualsWithoutHighlight(): MapOf<ResultVisual> {
			const r: MapOf<ResultVisual> = {};
			for (const k in this.resultVisuals) {
				r[k] = {...this.resultVisuals[k], isHighlighted: false };
			}
			return r;
		},
		legendPaddingClass(): any {
			if (this.legendPlacement === 'left') return 'pr-4';
			if (this.legendPlacement === 'right') return 'pl-4';
			if (this.legendPlacement === 'top') return 'pb-4';
			if (this.legendPlacement === 'bottom') return 'pt-4';
		},
		// compute separately from actual cells, 
		// because contents do not change ever, 
		// but layout does, so save some cycles by splitting them up
		// (this is computed, so is cached)
		legendCellsInput(): TableEntry[] {
			if (!this.hasResults) return [];
			return Array.from(new NextGroupSummaryOrKeyword(
				this.resultKeywordsByGroup!, 
				this.resultKeywords!, 
				this.resultVisuals!, 
				this.$t(`groups.default_group_name.${this.searchDirection}`).toString(),
				!this.showGroupContents,
				this.showOnlyWordsWithOccurances,
				this.searchSort
			));
		},
		legendCells(): DomCell[][] {
			return this.legendCellsInput && doTableLayout(this.legendCellsInput, {
				numColumns: this.legendColumns, 
				horizontalGroupLayout: this.groupColumns && this.showGroupContents,
				showCounts: this.showWordCounts
			});
		},
		
		/** 
		 * helper for legend sizing.
		 * it's hard to consistently set the size of the legend, 
		 * so we also provide this slider to set the size
		 * [1-100], meaning 1% to 100% of available width in the container.
		 * it's bound to the size of the dom rectangle of the legend
		 * The dom is always source of truth, this just helps with setting it.
		 */
		legendSizeComputed: {
			get(): number {
				const legendPlaceholder = this.refs['container.legend.placeholder'];
				if (!legendPlaceholder) return 100;
				return Math.round(100 * this.legendScaledWidth / this.availableContainerWidth);
			},
			set(v: number) {
				const containerWidth = this.availableContainerWidth;
				if (containerWidth == 0 || v == 0) return; // happens during init
				// First get the available width, the container.
				const regularWidth = this.refs['container.legend'].offsetWidth;
				const targetWidth = Math.floor(containerWidth * v / 100);
				
				const newScale = targetWidth / regularWidth;
				
				this.refs['container.legend'].style.transform = `scale(${newScale})`;
				this.refs['container.legend'].dispatchEvent(new CustomEvent('scale', {detail: {scale: newScale}}));
				this._updateLegendPlaceholderSize();
			}
		},
		legendScaleFactor: {
			get(): string {
				return this.legendScaleFactorModel.toFixed(2);
			},
			set(v: string) {
				const n =  parseFloat(v);
				if (isNaN(n)) return;
				this.legendScaleFactorModel = n;
				// apply new scale, preserve transform position
				const legend = this.refs['container.legend'];
				const legendPosition = decompose(localMatrix(legend));

				this.refs['container.legend'].style.transform = `translate(${legendPosition.translateX}px, ${legendPosition.translateY}px) scale(${v})`;
				this._updateLegendPlaceholderSize();
			}
		}
	},
	methods: {
		handleScale(event: CustomEvent) {
			if (isNaN(event.detail.scale)) return; 
			this.legendScaleFactorModel = event.detail.scale;
		},
		handleShowOnlyWordsWithOccurances(newValue: [number, number]) {
			this.showOnlyWordsWithOccurances = newValue;
		},
		/** 
		 * This function retrieves the size of the legend, and sets the size of the legend placeholder to that size.
		 * It runs whenever the actual legend size changes, and also when the legend size slider is moved.
		 * The legend is made of two parts:
		 * the parent div (refs[container.legend.placeholder]), which is a div with explicitly set width and height, it serves as a placeholder (as in, it occupies space) 
		 * for document flow concerns.
		 * If the legend mode is 'overlay', it is also the element that is translated to the correct position.
		 * Inside the parent div is the actual legend element (refs[container.legend]), which is a div with a transform:scale() set on it.
		 * We use this because scale() does not affect the size of the element's bounding box/document flow, so we need the placeholder element to do this.
		 */
		_updateLegendPlaceholderSize() {
			const legendAndTitle = this.refs['container.legend'];
			const placeholder = this.refs['container.legend.placeholder'];
			const {width, height} = legendAndTitle.getBoundingClientRect();
			placeholder.style.width = Math.ceil(width) + 'px';
			placeholder.style.height = Math.ceil(height) + 'px';
		},

		mapResized() {
			const map = this.refs['container.map.map'];
			if (!map) return;
			
			const ne = map.project(this.resultBounds.getNorthEast());
			const sw = map.project(this.resultBounds.getSouthWest());
			const resultwidth = Math.abs(ne.x - sw.x);
			const resultheight = Math.abs(ne.y - sw.y);
		
			const mapEl = this.refs['container.map.settings'].$el as HTMLElement;
			mapEl.style.aspectRatio = `${resultwidth / resultheight}`;
			
			// Notify map of the new aspect ratio
			map.invalidateSize();
			// Give the DOM some time to reflow, and leaflet to process this
			// then fit the bounds again. This updates panning and zoom to fit all results.
			setTimeout(() => map.fitBounds(this.resultBounds), 100);
		},

		doExport(what: 'map' | 'legend' | 'both') {
			if (this.exporting) return;
			this.exporting = true;
			if (this.exportMode === 'svg') this.exportSvg(what).then(() => this.exporting = false);
			else if (this.exportMode === 'png') this.exportCanvas(what).then(() => this.exporting = false);
			else if (this.exportMode === 'pdf') this.exportPdf().then(() => this.exporting = false);	

			Vue.$plausible?.trackEvent('export', { props: { what }});
		},
		// TODO: does not work yet. disabled from the UI.
		async exportSvg(what: 'map' | 'legend' | 'both') {
			const doc = elementToSVG(this.refs['container.legend'], {});
			await inlineResources(doc.documentElement)
			// hmm, seems that doesn't work properly.
			// inline the stylesheet and font we need for the symbols.
			for (const sheet of document.querySelectorAll('style')) {
				if (sheet.textContent?.includes('Font Awesome Free') || sheet.textContent?.includes('DSDD')) {
					const text = sheet.textContent!;
					const style = doc.createElement('style');
					doc.documentElement.prepend(style);
					style.innerHTML = text;
				}
			}
			
			const svgString = new XMLSerializer().serializeToString(doc)
			const blob = new Blob([svgString], {type: 'image/svg+xml'});
			
			const a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			a.download = (this.title || this.searchWords.join(' + ')) + (what !== 'both' ? ` (${what})` : '') + '.svg';
			a.click();
			a.remove();
		},
		async exportCanvas(what: 'map' | 'legend' | 'both') {
			const exportScale = this.exportScale * window.devicePixelRatio;
			
			const legendCanvas: HTMLCanvasElement = this.$refs.canvas2 as HTMLCanvasElement || document.createElement('canvas');
			const mapCanvas: HTMLCanvasElement = this.$refs.canvas as HTMLCanvasElement || document.createElement('canvas');
			const outputCanvas =  this.$refs.canvas3 as HTMLCanvasElement || document.createElement('canvas');

			// we read these later to determine position of the map/legend on the outputCanvas.
			// if one of the two is not rendered, we set it to 0 so it doesn't affect flow.
			legendCanvas.height = 0;
			legendCanvas.width = 0;
			mapCanvas.height = 0;
			mapCanvas.width = 0;


			const legend: HTMLElement = this.refs['container.legend.placeholder']; // parentmost legend element.
			
			// render legend
			if (what !== 'map') {
				const legendStyle: Partial<CSSStyleDeclaration> = {};
				if (this.legendPlacement !== 'overlay') {
					legendStyle.backgroundColor = this.transparentBackground ? 'transparent' : 'white';
					legendStyle.borderRadius = '0px!important;';
				}

				await renderLegend(legend, exportScale, {
					canvas: legendCanvas, 
					style: legendStyle
				});
			}
			
			// render map
			if (what !== 'legend') {
				// eww.. we need to get the icons
				// These exist in the <Map/> Vue component (as mapData.iconLayer), which exists in <MapSettings/>.
				// @ts-ignore
				const icons: L.LayerGroup<CustomMarker> = this.refs['container.map.settings'].$refs.map.mapData.iconLayer;
				await renderMap(this.refs['container.map.map'], exportScale, icons, mapCanvas);
			}
			const margin = this.exportMargin * exportScale * 16; // 16px = 1em
			let {width: paddingWidth, height: paddingHeight} = this.refs['container.padding'].getBoundingClientRect();
			paddingWidth *= exportScale;
			paddingHeight *= exportScale;
			
			// compute size of the output canvas, and position of contents on that canvas.
			let width = margin * 2;
			let height = margin * 2;
			let mapLeft = margin;
			let mapTop = margin;
			let legendLeft = margin;
			let legendTop = margin;

			if (what === 'map') { width += mapCanvas.width; height += mapCanvas.height; }
			if (what === 'legend') { width += legendCanvas.width; height += legendCanvas.height; }
			if (what === 'both') { 
				const largestWidth = Math.max(mapCanvas.width, legendCanvas.width);
				const largestHeight = Math.max(mapCanvas.height, legendCanvas.height);
				const totalWidth = mapCanvas.width + legendCanvas.width + paddingWidth;
				const totalHeight = mapCanvas.height + legendCanvas.height + paddingHeight;

				if (this.legendPlacement === 'left' || this.legendPlacement === 'right') {
					width += totalWidth;
					height += largestHeight;
					if (this.legendPlacement === 'left') mapLeft += legendCanvas.width + paddingWidth;
					else legendLeft += mapCanvas.width + paddingWidth;
				} else if (this.legendPlacement === 'top' || this.legendPlacement === 'bottom') {
					width += largestWidth;
					height += totalHeight;
					if (this.legendPlacement === 'top') mapTop += legendCanvas.height + paddingHeight;
					else legendTop += mapCanvas.height + paddingHeight;
				} else if (this.legendPlacement === 'overlay') {
					width += largestWidth;
					height += largestHeight;

					const legendPosition = decompose(localMatrix(legend));
					legendLeft = legendPosition.translateX + margin;
					legendTop = legendPosition.translateY + margin;
				}
				
			}

			outputCanvas.width = width;
			outputCanvas.height = height;
			const ctx = outputCanvas.getContext('2d')!;
			if (!this.transparentBackground) {
				ctx.fillStyle = 'white';
				ctx.fillRect(0, 0, outputCanvas.width, outputCanvas.height);
			}
			// needs condition because can't draw canvas of size 0
			if (what !== 'legend') ctx.drawImage(mapCanvas, mapLeft, mapTop);
			if (what !== 'map') ctx.drawImage(legendCanvas, legendLeft, legendTop);

			const a = document.createElement('a');
			a.href = outputCanvas!.toDataURL('image/png');
			a.download = (this.title || this.searchWords.join(' + ')) + (what !== 'both' ? ` (${what})` : '') + '.png';
			a.click();
			a.remove();
		},
		async exportPdf() {
			const [{default: pdfMake}, {default: pdfFonts}, {default: symbolFonts}] = await Promise.all([
				// @ts-ignore
				// import(/* webpackChunkName: "leaflet-image" */ 'leaflet-image'),
				// @ts-ignore
				import(/* webpackChunkName: "pdfmake" */ 'pdfmake'),
				// @ts-ignore
				import(/* webpackChunkName: "pdfmake-fonts" */ 'pdfmake/build/vfs_fonts'),
				// @ts-ignore
				import(/* webpackChunkName: "pdfmake-vfs-fonts" */ '@/assets/symbols/vfs_fonts'),
			]);
		
			pdfMake.vfs = pdfFonts.pdfMake.vfs;
			pdfMake.fonts = Object.assign(pdfMake.fonts || {}, {
				'DSDD-symbols': {
					normal: 'DSDD-symbols.ttf',
					bold: 'DSDD-symbols.ttf',
					italics: 'DSDD-symbols.ttf',
					bolditalics: 'DSDD-symbols.ttf'
				},
				'Font Awesome 5 Free': {
					normal: 'fa-solid-900.ttf',
					bold: 'fa-solid-900.ttf',
					italics: 'fa-solid-900.ttf',
					bolditalics: 'fa-solid-900.ttf'
				},
				Roboto: {
					normal: 'Roboto-Regular.ttf',
					bold: 'Roboto-Medium.ttf',
					italics: 'Roboto-Italic.ttf',
					bolditalics: 'Roboto-MediumItalic.ttf'
				}
			});

			Object.assign(pdfMake.vfs, symbolFonts);

			try {
				const map = this.refs['container.map.map']
				const size = map.getSize();

				const rawSize = [size.x, size.y];
				const a4size = [595.28 - 40, 841.89 - 40].reverse(); // landscape, invert size, subtract 20px on every side for margin
				const ratios = rawSize.map((s, i) => s / a4size[i]);
				const largestRatio = ratios.reduce((s, max) => Math.max(s, max), 0);
				const finalSize = rawSize.map(s => s/largestRatio);

				// since the map is probably smaller than an a4 page, scale up the render resolution of the canvas if required.
				const scale = Math.max(a4size[0] / rawSize[0], a4size[1] / rawSize[1]);
				const canvas = await renderMap(
					map, 
					Math.max(scale, this.exportScale), 
					// @ts-ignore
					this.refs['container.map.settings'].$refs.map.mapData.iconLayer as L.LayerGroup<CustomMarker>
				)
				const imgData = canvas.toDataURL('image/png', 1.0);

				// [row][column]
				const legendTableCells = JSON.parse(JSON.stringify(this.legendCells!)) as typeof this.legendCells;
				
				// number of columns in table
				// used to pad out shorter rows as pdfmake doesn't like that.
				const tableWidth = legendTableCells[0]?.reduce((acc, cur) => cur.colSpan + acc, 0) ?? 0;

				// add data specific to pdfmake to the cells
				// mostly markup
				// but also replace reserved cells (which we don't render in dom) with empty strings
				for (let ri = 0; ri < legendTableCells.length; ++ri) {
					let row = legendTableCells[ri];
					if (!row) row = legendTableCells[ri] = [];
					for (let ci = 0; ci < tableWidth; ++ci) {
						const cell = row[ci];
						// @ts-ignore
						if (!cell) row[ci] = '';
						else if (cell.type === 'text') continue;
						// @ts-ignore
						else if (cell.type === 'reserved') row[ci] = ''; 
						// @ts-ignore
						else if (cell.type === 'group') cell.fontSize = 16; 
						// @ts-ignore
						else if (cell.type === 'count') cell.alignment = 'right';
						else if (cell.type === 'symbol') {
							// @ts-ignore
							cell.text = cell.icons.map(icon => ({
								text: icon.glyph,
								color: icon.color,
								style: icon.font,
								fillColor: '#FFFFFF', // bg
								alignment: 'center'
							}));
						} 
					}
				}

				// Add title.
				if (this.showTitle) {
					legendTableCells.unshift([{
						text: this.title,
						// @ts-ignore
						fontSize: 24 * this.titleSize,
						colSpan: tableWidth,
					}]);
				}
				// @ts-ignore
				// add an empty row at the top of the table that has the full width
				// pdfmake trips when the first row has cells with colspan > 1
				// that's a bug, but one we can't solve ourselves
				// because there are bugs where colspan isn't checked properly
				// so prevent this in any case		
				legendTableCells.unshift(Array.from({length: tableWidth}, () => ''));

				const dd = {
					pageSize: 'a4',
					pageOrientation: 'landscape',
					pageMargins: [20,20,20,20],
					content: [
						{
							image: 'map',
							width: finalSize[0],
							height: finalSize[1],
							margin: [
								(a4size[0] - finalSize[0]) / 2,
								(a4size[1] - finalSize[1]) / 2,
							],
							pageBreak: 'after',
						}, {
							// legend
							margin: 0,
							layout: 'noBorders',
							table: { body: legendTableCells },
						},
					],
					images: { map: imgData },
					styles: {
						'DSDD-symbols': { font: 'DSDD-symbols' },
						'Font Awesome 5 Free': { font: 'Font Awesome 5 Free' }
					},
					defaultStyle: { color: '#212529' /* bootstrap body text color */ }
				};

				const pdf = pdfMake.createPdf(dd, {
					tableLayouts: {
						main: {
							hLineColor: () => '#343a40',
							vLineColor: () => '#343a40',
						}
					}
				});
				pdf.download('DSDD Kaart - ' + this.searchWords[0] + '.pdf');
			} catch (e) {
				console.error(e);
				const translation = this.$t('map.error_pdf_export');
				this.$bvToast.toast(translation.toString(), {
					variant: 'danger',
				});
				this.exporting = false;
				return;
			}
		},
	},
	watch: {
		resultBounds() { debouncedMapResize(this); },
		exportScale() {
			if (this.zoomOffsetControl) {
				let offset = Math.log2(this.exportScale * window.devicePixelRatio);
				offset = offset >= 0 ? Math.ceil(offset) : Math.floor(offset);
				this.zoomOffsetControl.setDetail(offset);
			}
		},
		legendPlacement(placement: string, prev: string) {
			if (placement === 'overlay' || prev === 'overlay')  {
				// reset transform if switching between overlay and other placements
				const el = this.refs['container.legend'];
				const el2 = this.refs['container.legend.placeholder'];
				el.style.transform = '';
				el2.style.transform = '';
				this.refs['container.legend'].dispatchEvent(new CustomEvent('scale', {detail: {scale: 1}}));
			}
			// this._updateLegendPlaceholderSize();
		},
	},
	created() {
		this.title = this.searchWords?.map(w => w.slice(0, 1).toUpperCase() + w.slice(1)).join(' ') || '';
		// weird path, not sure how to do this in regular watch function.
		this.$watch(() => this.refs['container.map.map'], map => {
			if (map) {
				this.zoomOffsetControl = new DetailControl(map, -5, 5);
			} else if (this.zoomOffsetControl) {
				this.zoomOffsetControl.detach();
				this.zoomOffsetControl = null;
			}
		});
	},
	mounted() {
		this.refs['container'] = this.$refs['container'] as any;
		this.refs['container.legend'] = this.$refs['container.legend'] as any;
		this.refs['container.legend.placeholder'] = this.$refs['container.legend.placeholder'] as any;
		this.refs['container.legend.table'] = this.$refs['container.legend.table'] as any;
		this.refs['container.map.settings'] = this.$refs['container.map.settings'] as any;
		this.refs['container.padding'] = this.$refs['container.padding'] as any;
		// container.map.map done on @map-changed event of MapSettings

		const mapElement = this.refs['container.map.settings'].$el;
		const legendElement = this.refs['container.legend'];
		const containerElement = this.refs['container'];
		const legendPlaceholder = this.refs['container.legend.placeholder'];
		this.resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.target === mapElement) {
					debouncedMapResize(this);
				} else if (entry.target === legendElement) {
					// unscaled size of legend table changed, update placeholder
					// (scaled change is updated through data-placeholer in resize drag code)
					const {width, height} = entry.target.getBoundingClientRect();
					this.legendScaledWidth = Math.ceil(width);

					this.refs['container.legend.placeholder'].style.width = `${width}px`;
					this.refs['container.legend.placeholder'].style.height = `${height}px`;
				} else if (entry.target === containerElement) {
					// container size changed, update so legend size slider bar can be recomputed.
					
					const placement = this.legendPlacement;
					const container = this.refs['container'];
						
					// compute content size of container
					const style = window.getComputedStyle(container);
					const paddingRight = parseInt(style.paddingRight);
					const paddingLeft = parseInt(style.paddingLeft);
					const containerWidth = container.scrollWidth - (paddingLeft + paddingRight);

					// compute width of other content
					const paddingWidth = this.refs['container.padding'].offsetWidth;
					const availableWidth = containerWidth - (placement === 'left' || placement === 'right' ? paddingWidth : 0);
					this.availableContainerWidth = availableWidth;
				} else if (entry.target === legendPlaceholder) {
					// placeholder size changed, update so legend size slider bar can be recomputed.
					const {width} = entry.target.getBoundingClientRect();
					this.legendScaledWidth = width;
				}
			}
		});

		this.resizeObserver.observe(mapElement);
		this.resizeObserver.observe(legendElement);
		this.resizeObserver.observe(containerElement);
		this.resizeObserver.observe(legendPlaceholder);

		dragElement(this.refs['container.legend.placeholder']); // draggable
		dragElement(this.refs['container.legend']); // scalable
		debouncedMapResize(this);
	},
	activated() {
		this._updateLegendPlaceholderSize();
		debouncedMapResize(this);
	},
	beforeDestroy() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
			this.resizeObserver = null;
		}
		undragElement(this.refs['container.legend']);
		undragElement(this.refs['container.legend.placeholder']);
		this.subs.forEach(s => s.unsubscribe());
		this.subs.slice(0);
	},
})

const debouncedMapResize = debounce(function(instance: any) {
	instance.mapResized();
}, 100, true);

function debounce<T extends Function>(func: T, wait: number, immediate?: boolean): T {
	let timeout: number|null;
	return function(this: any) {
		const context = this, args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		if (!timeout) {
			timeout = setTimeout(later, wait);
			if (immediate) func.apply(context, args);
		}
	} as any;
};

function createScaleHandle(el?: HTMLElement) {
	const handle = document.createElement('div');
	handle.classList.add('scale-handle', 'fas', 'fa-grip-lines');
	if (el) el.append(handle);
	return handle;
}

function destroyScaleHandle(el: HTMLElement) {
	if (!el.classList.contains('scale-handle')) {
		el = el.querySelector('.scale-handle')!;
	}
	el.remove();
}

function undragElement(el: HTMLElement) {
	el.onmousedown = null;
	destroyScaleHandle(el);
}

function dragElement(el: HTMLElement) {
	const placeholder = el.hasAttribute('data-placeholder') ? document.querySelector(el.getAttribute('data-placeholder')!) as HTMLElement : null;
	const dragInfo = {
		startX: 0, 
		startY: 0
	};

	const scaleInfo = {
		startWidth: 0,
		startHeight: 0,
		startScale: 0,
		startX: 0,
		startY: 0,
	};

	el.onmousedown = mouseDown;

	if (el.classList.contains('scalable')) {
		createScaleHandle(el);
	}

	function mouseDown(e: MouseEvent) {
		document.onmouseup = closeDragElement;
		
		// if the click was in the drag corner, instead of dragging, scale the element
		const {right, bottom} = el.getBoundingClientRect();
		if (el.classList.contains('scalable') && e.clientX > right - 20 && e.clientY > bottom - 20) {
			startScale(e);
		} else if (el.classList.contains('draggable') && !((e.target as HTMLElement).matches('input') || (e.target as HTMLElement).matches('textarea') || (e.target as HTMLElement).matches('select'))) {
			startDrag(e);
		}
	}

	function startDrag(e: MouseEvent) {
		// get the mouse cursor position at startup:
		dragInfo.startX = e.clientX;
		dragInfo.startY = e.clientY;
		
		// call a function whenever the cursor moves:
		document.onmousemove = elementDrag;
		e.preventDefault();
	}

	function startScale(e: MouseEvent) {
		scaleInfo.startX = e.clientX;
		scaleInfo.startY = e.clientY;
		scaleInfo.startScale = decompose(localMatrix(el)).scaleX; // el.getBoundingClientRect().height / el.offsetHeight;
		scaleInfo.startWidth = el.offsetWidth * scaleInfo.startScale;
		scaleInfo.startHeight = el.offsetHeight * scaleInfo.startScale;
		document.onmousemove = elementScale;
		e.preventDefault();
		e.stopImmediatePropagation();
	}

	function elementDrag(e: MouseEvent) {
		e = e || window.event;
		// calculate the new cursor position:
		const dx = dragInfo.startX - e.clientX;
		const dy = dragInfo.startY - e.clientY;
		dragInfo.startX = e.clientX;
		dragInfo.startY = e.clientY;
		
		// set the element's new position:
		const trans = decompose(localMatrix(el));
		el.style.transform = `translate(${trans.translateX - dx}px, ${trans.translateY - dy}px) scale(${trans.scaleX})`
	}

	function elementScale(e: MouseEvent) {
		const dx = e.clientX - scaleInfo.startX;
		const dy = e.clientY - scaleInfo.startY;
		const scaleX = ((dx / scaleInfo.startWidth) + 1) * scaleInfo.startScale;
		const scaleY = ((dy / scaleInfo.startHeight) + 1) * scaleInfo.startScale;
		const newScale = Math.max(0.1, Math.max(scaleX, scaleY));

		const trans = decompose(localMatrix(el));

		el.style.transform = `translate(${trans.translateX}px, ${trans.translateY}px) scale(${newScale})`;
		el.dispatchEvent(new CustomEvent('scale', {detail: {scale: newScale}}));
		// scale handle
		const handle = el.querySelector('.scale-handle') as HTMLElement;
		if (handle) {
			handle.style.transform = `translate(50%) scale(${1 / newScale}) rotate(-45deg)`;
		} 
		if (placeholder) {
			const {width, height} = el.getBoundingClientRect();
			placeholder.style.width = width + 'px';
			placeholder.style.height = height + 'px';
		}
	}

	function closeDragElement() {
		// stop moving when mouse button is released:
		document.onmouseup = null;
		document.onmousemove = null;
	}
}


// function intersects(a: DOMRect, b: DOMRect): boolean {
// 	return a.left < b.right && a.right > b.left && a.top < b.bottom && a.bottom > b.top;
// }

/** Returns whether a is fully within b */
// function within(a: DOMRect, b: DOMRect): boolean {
// 	return a.left > b.left && a.right < b.right && a.top > b.top && a.bottom < b.bottom;
// }


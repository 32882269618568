
import Vue from 'vue';
import VueSlider from 'vue-slider-component';

import * as L from 'leaflet';
import MapComponent, { Point } from './Map.vue';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';
import { ResultPoint } from '@/scopes/SearchScopeTypes';
import { FAGlyphs } from '@/Util';

export default Vue.extend({
	components: { VueSlider, MapComponent },
	props: {
		...SearchScopeChildProps,
		showExportButtons: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		isSelectingArea: false,
		id: 'map-options-' + Math.random().toFixed(4),

		/** Hide all points with < or > occurances than these numbers on the map. */
		showOnlyWordsWithOccurances: [1, 1] as [number, number],
		/** Do we enable special treatment for nearby points (i.e. "clustering") (to prevent drawing confusing overlapping symbols)? */
		clusterEnabled: true,
		/** If clusterEnabled, do we draw clustered points as a single point (a grey circle with a number)? If false, we spread them out. */
		clusterCollapseEnabled: true,
		/** If clusterCollapseEnabled, we spread out overlapping points, this controls how far. */
		clusterSpread: 1,
		/** Do we draw only one symbol even if there are multiple identical points (same symbol, same location)? 
		 * If false, all points are drawn, if true, only one instance of a point at a location is drawn (even when spreading out cluster contents).  */
		clusterIdenticalPoints: false,

		/** Global symbol size multiplier */
		symbolSize: 1,

		exporting: false,
		map: null as L.Map|null
	}),
	computed: {
		occurances(): number[] {
			if (!this.hasResults) { return [1, 1]; }
			const counts = this.resultKeywordsArray!.map(kw => kw.count).sort((a, b) => a - b);
			const distinctCounts = new Set(counts); // preserves order, removes dupes
			const distinctCountsArray = [...distinctCounts.values()];
			return distinctCountsArray;
		},
		shownResultPoints(): ResultPoint[] {
			const kws = this.resultKeywords!;
			let [min, max] = this.showOnlyWordsWithOccurances;
			if (max < min) { const tmp = min; min = max; max = tmp; } // bug with v-slider where min and max sometimes get swapped
			return (this.resultPointsArray || []).filter(p =>
				kws[p.keyword_id].count >= min &&
				kws[p.keyword_id].count <= max
			);
		},

		points(): Point[] {
			if (!this.hasResults) { return []; }
			const kws = this.resultKeywords!;
			const vs = this.resultVisuals!;
			const glyphs = FAGlyphs;

			return this.shownResultPoints.reduce<Point[]>((points, pt) => {
				const v = vs[pt.keyword_id];
				if (!v.isVisible && !v.isHighlighted) { return points; }
				const kw = kws[pt.keyword_id];
				points.push({
					lng: pt.lng,
					lat: pt.lat,
					color: v.color,
					word: `${pt.place}: ${kw.display} ${pt.variant ? ` (${pt.variant})` : ''}`,
					glyph: glyphs[v.icon].glyph,
					font: glyphs[v.icon].font,
					size: v.iconSize * this.symbolSize,
					highlight: v.isHighlighted,
				});
				return points;
			}, []);
		},
	},
	
	methods: {
		startAreaSelect() {
			this.isSelectingArea = true;

			const escapeKeyListener = (e: KeyboardEvent) => e.keyCode === 27 && removeListenersAndStopSelectingArea();
			const removeChangeListener = this.$watch('searchArea', function() { removeListenersAndStopSelectingArea(); });
			const removeListenersAndStopSelectingArea = () => {
				this.isSelectingArea = false;
				document.body.removeEventListener('keydown', escapeKeyListener);
				removeChangeListener();
			};
			document.body.addEventListener('keydown', escapeKeyListener);
		},

		attachToMap(map: L.Map) {
			if (map) {
				const container = map.getContainer();
				const zoomControl = container.querySelector('.leaflet-control-zoom') as HTMLElement;
				const layersControl = container.querySelector('.leaflet-control-layers') as HTMLElement;
				const zoomPlaceholder = this.$el.querySelector('.leaflet-control-zoom-placeholder') as HTMLElement;
				const layersPlaceholder = this.$el.querySelector('.leaflet-control-layers-placeholder') as HTMLElement;
				this.swapElements(zoomControl, zoomPlaceholder);
				this.swapElements(layersControl, layersPlaceholder);
			}
		},
		detachFromMap(map: L.Map) {
			if (map) {
				const container = map.getContainer();
				const zoomControl = this.$el.querySelector('.leaflet-control-zoom') as HTMLElement;
				const layersControl = this.$el.querySelector('.leaflet-control-layers') as HTMLElement;
				const zoomPlaceholder = container.querySelector('.leaflet-control-zoom-placeholder') as HTMLElement;
				const layersPlaceholder = container.querySelector('.leaflet-control-layers-placeholder') as HTMLElement;
				this.swapElements(zoomControl, zoomPlaceholder);
				this.swapElements(layersControl, layersPlaceholder);
			}
		},
		swapElements(obj1?: HTMLElement, obj2?: HTMLElement) {
			if (!obj1 || !obj2) return;
			var temp = document.createElement("div");
			obj1.parentNode!.insertBefore(temp, obj1);
			obj2.parentNode!.insertBefore(obj1, obj2);
			temp.parentNode!.insertBefore(obj2, temp);
			temp.parentNode!.removeChild(temp);
		},
	},
	watch: {
		isSelectingArea() {
			this.map!.selectArea.setControlKey(!this.isSelectingArea);
		},
		resultKeywords: {
			immediate: true,
			handler() {
				if (this.resultKeywords) {
					// when results change, reset the slider.
					this.showOnlyWordsWithOccurances = [this.occurances[0], this.occurances[this.occurances.length - 1]];
				}
			}
		},
		map(cur?: L.Map, prev?: L.Map) {
			if (prev) this.detachFromMap(prev);
			if (cur) this.attachToMap(cur);
		},
		showOnlyWordsWithOccurances: {
			deep: true,
			immediate: true,
			handler() {
				let [min, max] = this.showOnlyWordsWithOccurances;
				if (max < min) { const tmp = min; min = max; max = tmp; } // bug with v-slider where min and max sometimes get swapped
				this.$emit('showOnlyWordsWithOccurances', [min, max]);
			}
		}
	},
	activated() { (this as any).mounted(); },
	deactivated() { (this as any).beforeDestroy(); },

	created() {
		// sync setting with localstorage
		if (window.localStorage) {
			[
				'clusterEnabled',
				'clusterSpread',
				'clusterCollapseEnabled',
				'clusterIdenticalPoints',
				'symbolSize'
			].forEach((k: any) => {
				const stored = window.localStorage.getItem(k);
				if (stored != null) { (this as any)[k] = JSON.parse(stored); }
				this.$watch(k, function() { window.localStorage.setItem(k, (this as any)[k]); });
			});
		}
	}
});



import Vue from 'vue';
import L from 'leaflet';

import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';
import { MapOf, FAGlyphs } from '@/Util';
import { DSDDQuery } from '@/DSDDQuery';
import Map, { Point } from '@/components/Map.vue';

export default Vue.extend({
	inheritAttrs: false,
	components: { Map },
	props: {
		...SearchScopeChildProps,
		concept_id: String,
		keyword_id: String,
		use_variants: Boolean // TODO promote variants to keywords, needs to be discussed.
	},
	data: () => ({
		points: [] as Point[],
		bounds: undefined as undefined|L.LatLngBounds
	}),
	created() {
		// TODO factor this into a common search helper together with the generic results getting

		// concept_id is always set, keyword_id only when we need to match a specific keyword
		const search_in: 'concepts'|'keywords' = this.keyword_id ? 'keywords' : 'concepts';
		const word = this.searchWords;
		const id = this.keyword_id || this.concept_id;
		const filters = this.searchFilters;
		const include_data = true;
		const include_facets = false;
		const start = 0;
		const rows = Math.pow(2,31)-1;

		const queryParams = {
			search_in,
			word,
			id,
			...Object
				.entries(filters || {})
				.map(([key, value]) => ['filter.'+key, value] as [string, string[]])
				.reduce((acc, [key, value]) => {acc[key] = value; return acc;}, {} as MapOf<string[]>),
			'filter.area': this.searchArea ?
				this.searchArea.getSouth().toFixed(4)+','+
				this.searchArea.getWest().toFixed(4)+','+
				this.searchArea.getNorth().toFixed(4)+','+
				this.searchArea.getEast().toFixed(4)
				: undefined,
			include_data,
			include_facets,
			start,
			rows,
		};

		new DSDDQuery().get('keywords', queryParams)
		.req
		.then(r => {
			const visuals = this.resultVisuals!;
			let minlng = Number.MAX_SAFE_INTEGER;
			let minlat = Number.MAX_SAFE_INTEGER;
			let maxlng = Number.MIN_SAFE_INTEGER;
			let maxlat = Number.MIN_SAFE_INTEGER;
			const points = r.data.keywords.flatMap(kw => kw.data!.map(p => {
				const v = visuals[kw.id];

				const pos = p.point.split(',');
				const lat = Number(pos[0]);
				const lng = Number(pos[1]);

				minlng = Math.min(minlng, lng);
				maxlng = Math.max(maxlng, lng);
				minlat = Math.min(minlat, lat);
				maxlat = Math.max(maxlat, lat);

				return {
					lng,
					lat,
					color: v.color,
					// @ts-ignore
					word: p.place + ': ' + kw.display + (p.variant ? ` (${p.variant})` : ''), // hmm
					glyph: FAGlyphs[v.icon].glyph,
					font: FAGlyphs[v.icon].font,
					highlight: false,
					opacity: 1,
					size: v.iconSize
				};
			}));

			this.points = points;
			this.bounds = new L.LatLngBounds([[minlat, minlng], [maxlat, maxlng]]);
			if (this.bounds.getNorthEast().distanceTo(this.bounds.getSouthWest()) === 0) {
				this.bounds = new L.LatLngBounds([[minlat - 0.01, minlng - 0.01], [maxlat + 0.01, maxlng + 0.01]]);
			}
		})
		.catch(e => console.error(e));
	}
});

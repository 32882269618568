
import Vue from 'vue';
import { ResizeSensor } from 'css-element-queries';
import VueSlider from 'vue-slider-component';

import { SearchScope } from '@/router';
import { SearchDirection } from '@/DSDDQuery';
import { MapOf } from '@/Util';

import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';
import Groups from './Groups.vue';
import PrintHelper from './PrintHelper.vue';
import Facets from '@/components/Facets.vue';
import LongList from '@/components/LongList.vue';
import MapSettings from '@/components/MapSettings.vue';

import { suggestionResults, facetResults } from '@/scopes/SearchScopeGetResults';


export default Vue.extend({
	components: {
		Map,
		Groups,
		LongList,
		Facets,
		PrintHelper,
		VueSlider,
		MapSettings
	},
	props: {
		...SearchScopeChildProps,
	},
	data: () => ({
		modelSearchTerm: '',
		modelSearchDirection: SearchDirection.s2d,
		modelFacets: {} as MapOf<string[]>,
		modelArea: null as null|L.LatLngBounds,

		searchAddon: 'search-controls' as string|null,

		resizeSensors: [] as ResizeSensor[],

		facetData: facetResults,
		facetVisible: false,
		// exporting: false,

		// hack! needed to properly init our mapSettings child component
		// refMapComponentMap: undefined as any,
	}),
	computed: {
		
		numSelectedFilters(): number { return Object.values(this.searchFilters || {}).reduce((sum, v) => sum + v.length, 0) + (this.searchArea ? 1 : 0); },

		routerSearchParams(): SearchScope.RouterLocation {
			return {
				name: 'map',
				query: {
					dir: this.modelSearchDirection,
					word: this.modelSearchTerm ? [this.modelSearchTerm] : null,
					filter: null,
					area: null,
					id: null, // always clear this when performing a new search
				},
			};
		},

		
		suggestionsParams(): {dir: SearchDirection, term: string} {
			return {
				dir: this.modelSearchDirection,
				term: this.modelSearchTerm
			};
		},
		suggestions(): string[]|undefined { return suggestionResults.lastResults; }
	},

	methods: {
		// invalidateSize() { requestAnimationFrame(() => Object.values(this.$refs).filter(e => 'invalidateSize' in e!).forEach(e => (e as any).invalidateSize())); }, // todo
		toggle(id: string) {
			this.$root.$emit('bv::toggle::collapse', id);
		},
		delay(cb: CallableFunction) {
			setTimeout(() => requestAnimationFrame(() => requestAnimationFrame(() => cb())), 0);
		},
	},

	watch: {
		resultKeywords: {
			immediate: true,
			handler() {
				if (this.resultKeywords) {
					this.searchAddon = null;
				}
			}
		},
		searchDirection: {
			immediate: true,
			handler(_cur: string) {
				this.modelSearchDirection = this.searchDirection as SearchDirection;
			}
		},
		searchWords: {
			immediate: true,
			handler() {
				const next = this.searchWords && this.searchWords.length ? this.searchWords[0] : '';
				if (next && next !== this.modelSearchTerm) {
					this.modelSearchTerm = next;
				}
			}
		},
		searchFilters: {
			immediate: true,
			handler() {
				this.modelFacets = this.searchFilters;
			}
		},
		searchArea: {
			immediate: true,
			handler() {
				this.modelArea = this.searchArea || null;
			}
		},
		modelFacets() { this.facetData.next({filters: this.modelFacets, dir: this.searchDirection, terms: this.searchWords}); },
		suggestionsParams() {
			suggestionResults.next(this.suggestionsParams);
		}
	},
	mounted() {
		const searchContainer = this.$refs.searchContainer as HTMLElement;
		const groupContainer = this.$refs.groupContainer as HTMLElement;

		this.resizeSensors.push(new ResizeSensor([searchContainer, document.documentElement], function() {
			const left = searchContainer.offsetLeft;
			const bottom = searchContainer.offsetTop + searchContainer.offsetHeight;
			const windowBottom = searchContainer.getBoundingClientRect().bottom;
			const {marginTop, marginBottom} = window.getComputedStyle(groupContainer);

			const mt = marginTop ? Number(marginTop.substring(0, marginTop.length - 2)) : 0;
			const mb = marginBottom ? Number(marginBottom.substring(0, marginBottom.length - 2)) : 0;
			const marginCorrection = mt + mb;

			groupContainer.style.transform = `translate(${left}px, ${bottom}px)`;
			groupContainer.style.top = '0';
			groupContainer.style.left = '0';

			const mh = (document.documentElement.clientHeight - windowBottom - marginCorrection - 2) + 'px';
			groupContainer.style.maxHeight = mh;
		}));

		
		// hack! refs are not reactive, so unless this component rerenders, these props are otherwise never placed in our child component.
	},
	beforeDestroy() {
		this.resizeSensors.forEach(s => s.detach());
		this.resizeSensors = [];
	},
	activated() { (this as any).mounted(); },
	deactivated() { (this as any).beforeDestroy(); },
});


import Vue from 'vue';
import { bootstrap } from 'vue-gtag';
import {lastSearch} from  '@/router';

const analyticsLocalStorageKey = 'dsdd/analyticsEnabled';

export default Vue.extend({
	name: 'app',
	data: () => ({
		analyticsConfigured: null as boolean|null,
		analyticsDisabled: dsddconfig.ga == null,
		lastSearch,
	}),
	created() {
		this.analyticsConfigured = JSON.parse(window.localStorage.getItem(analyticsLocalStorageKey) || 'null') as boolean|null;
		if (this.analyticsConfigured) {
			this.enableAnalytics();
		}
	},
	methods: {
		enableAnalytics() {
			this.analyticsConfigured = true;
			window.localStorage.setItem(analyticsLocalStorageKey, JSON.stringify(this.analyticsConfigured));
			if (!this.analyticsDisabled) {
				bootstrap().catch(() => { /** swallow error, probably an ad blocker blocking the google analytics script */ });
			}
		},
		disableAnalytics() {
			this.analyticsConfigured = false;
			window.localStorage.setItem(analyticsLocalStorageKey, JSON.stringify(this.analyticsConfigured));
		},
	}
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"group"},[(!_vm.hideHeader)?_c('div',{staticClass:"px-1 py-2 d-flex flex-nowrap align-items-baseline",staticStyle:{"position":"relative"}},[_c('div',{style:({
			position: 'absolute',
			left: 0,
			top: 0,
			height: '100%',
			width: '3px',
			background: _vm.cssColors
		})}),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.id),expression:"id"}],staticClass:"p-0 m-0 text-body",attrs:{"variant":"link","tabindex":"-1"}},[_c('span',{staticClass:"fas fa-fw fa-caret-right when-closed"}),_c('span',{staticClass:"fas fa-fw fa-caret-down when-open"})]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.id),expression:"id"}],staticClass:"h6 text-body text-decoration-none font-weight-bold flex-fill text-left m-0 p-0",attrs:{"variant":"link"}},[_vm._v(_vm._s(_vm.group))]),_c('b-button',{staticClass:"m-0 p-0 icon-toggle-button",attrs:{"variant":"link","pressed":!_vm.isVisible,"title":_vm.$t(`groups.group_set_visible_${!_vm.isVisible}_help`)},on:{"click":function($event){return _vm.$emit('toggleHidden', _vm.group, !_vm.isVisible)}}},[(_vm.isVisible)?_c('span',{staticClass:"fas fa-fw fa-eye"}):_c('span',{staticClass:"fas fa-fw fa-eye-slash text-danger"})]),_c('b-button',{staticClass:"m-0 p-0 icon-toggle-button text-danger",attrs:{"variant":"link","pressed":_vm.isHighlighted,"title":_vm.$t(`groups.group_set_highlighted_${!_vm.isHighlighted}_help`)},on:{"click":function($event){return _vm.$emit('toggleHighlight', _vm.group, !_vm.isHighlighted)}}},[_c('span',{staticClass:"fas fa-fw fa-exclamation"})])],1):_vm._e(),_c('b-collapse',{staticClass:"group-items border-top shadow-top",attrs:{"visible":_vm.index === 0,"id":_vm.id}},[_c('div',{staticClass:"p-2"},[_vm._l((_vm.data),function(d){return [_c('button',{key:d.id,class:{
						keyword: true,
						hidden: !(d.isVisible || d.isHighlighted),
						'text-danger': d.isHighlighted
					},attrs:{"title":`${_vm.$t('common.Keyword')} '${d.keyword_display}' (${_vm.$t('common.concept')} '${d.concept_display}') - ${d.count}x`},on:{"click":function($event){return _vm.$emit('toggleKeywordHighlight', d.id)},"dblclick":function($event){return _vm.$emit('toggleKeywordVisible', d.id)}}},[_c('span',{class:'keyword-icon fa ' + d.class,style:({
							color: d.color,
							fontSize: _vm.keywordSizes[d.id] + 'px'
						})}),_vm._v(_vm._s(d.display)+" ")])]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import Vue from 'vue';

import { ResultFacet } from '@/scopes/SearchScopeTypes';
import { MapOf, mapReduce } from '@/Util';

import SelectPicker from '@/components/SelectPicker.vue';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import { AxiosError } from 'axios';

const facetCategories = [
	'dictionary',
	'topic_group',
	'country',
	'province',
	'region',
	'place',
];

export default Vue.extend({
	components: {
		SelectPicker,
		OverlaySpinner
	},
	props: {
		loading: Boolean,
		facets: {
			type: Object as () => MapOf<string[]>,
			default: () => ({}) as MapOf<string[]>
		},
		area: {
			type: [Object, null] as any as () => null|L.LatLngBounds,
			default: () => null as null|L.LatLngBounds
		},
		options: Object as () => undefined|MapOf<ResultFacet[]>,
		error: Error as any as () => AxiosError|undefined
	},
	computed: {
		preventInput(): boolean { return this.loading && !this.options; },

		missingOptions(): MapOf<MapOf<boolean>> {
			return facetCategories.reduce<MapOf<MapOf<boolean>>>((r, cat) => {
				r[cat] = mapReduce(this.filter(this.facets && this.facets[cat], this.options && this.options[cat]));
				return r;
			}, {});
		},
		// Always render an option for every selected value, regardless of whether we have a result set
		renderOptions(): MapOf<ResultFacet[]> {
			return facetCategories.reduce<MapOf<ResultFacet[]>>((r, cat) => {
				r[cat] = this.prependMissingOptions(Object.keys(this.missingOptions[cat]), this.options && this.options[cat] || []);
				return r;
			}, {});
		},
		selectedFacets(): Array<{cat: string, value: string}> {
			return Object.entries(this.facets).flatMap(([cat, values]) => values.map(value => ({cat, value})));
		}
	},
	methods: {
		filter(removeFromThis: string[] = [], ifInThis: ResultFacet[] = []) {
			const asSet = new Set(ifInThis.map(e => e.value));
			return removeFromThis.filter(s => !asSet.has(s));
		},
		prependMissingOptions(missingOptions: string[] = [], existingOptions: ResultFacet[] = []) {
			return missingOptions.sort().map<ResultFacet>(o => ({
				label: `<span class="text-danger font-italic">${o}</span> <small class="font-italic text-secondary">(0)</small>`,
				value: o,
				count: 0
			})).concat(existingOptions);
		},
		clearFacet(cat: string, value: string) {
			this.$emit('input', {
				area: this.area,
				facets: {...this.facets, [cat]: this.facets[cat].filter(v => v !== value)}
			});
		},
		clearArea() {
			this.$emit('input', {
				area: null,
				facets: this.facets
			});
		}
	}
});



import Vue from 'vue';
// @ts-ignore
import { Compact, ColorMixin, Hue, Saturation } from 'vue-color';
import tinycolor from 'tinycolor2';
import { sortedPresetColors, textColor, isLightColor } from '@/Util';

export default Vue.extend({
	props: {
		value: String,
	},
	components: {Compact, Hue, Saturation},
	mixins: [ColorMixin],
	name: 'DSDD-color-picker',
	data: () => ({
		presetColors: sortedPresetColors
	}),
	methods: {
		textColor,
		tryNewColor(e: InputEvent) {
			const c = (e.target as HTMLInputElement).value;
			try {
				const tc = tinycolor(c);
				(this as any).colorChange(tc.toHex8String());
			} catch (e) {
				// ignore;
			}
		}
	},
	computed: {
		presetStyles(): any {
			const perLine = Math.min(this.presetColors.length, 12);
			const marginsPerLine = perLine - 1;

			const marginTotalPercent = 15;
			const baseWidth = 100 / perLine;
			const actualMargin = marginsPerLine ? marginTotalPercent / marginsPerLine : 0;
			const actualWidth = baseWidth - actualMargin;

			return {
				paddingTop: actualWidth + '%',
				width: actualWidth + '%',
				margin: actualMargin / 2 + '%'
			};
		},
		buttonStyle(): any {
			const c = tinycolor((this as any).colors.hex);
			return {
				'color': isLightColor((this as any).colors.hex) ? undefined : 'white',
				'--borderColor': c.clone().darken(15).toString(),
				'--backgroundColor': c.toString(),
				'--hover-backgroundColor': c.clone().darken(10).toString(),
				'--hover-borderColor': c.clone().darken(25).toString(),
			};
		}
	}
});



import Vue from 'vue';
import { ResultVisual, ResultKeyword } from '@/scopes/SearchScopeTypes';
import { FAGlyphsArray } from '../../Util';
export default Vue.extend({
	props: {
		keyword: Object as () => ResultKeyword&ResultVisual&(typeof FAGlyphsArray)[number],
		active: { default: true, }
	},
	computed: {
		tooltip(): string {
			const kw = this.keyword;
			const lines: string[] = [];
			if (!kw.isVisible) { lines.push(`${this.$t('common.hidden')}\n`); }
			lines.push(`${this.$t('common.Concept')}: ${kw.concept_display}`);
			if (kw.variants && kw.variants.length) { lines.push(`${this.$t('common.variants')}: ${kw.variants.join(', ')}`); }
			lines.push(`${this.$t('common.number_of_occurances')}: ${kw.count.toLocaleString()}`);

			lines.push('-----');
			lines.push(`${this.$t('common.symbol')}: ${kw.icon}`);
			lines.push(`${this.$t('common.color')}: ${kw.color}`);
			lines.push(`${this.$t('common.size')}: ${kw.iconSize}`);

			return lines.join('\n');
		}
	}
});

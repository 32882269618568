import { MapOf, mapReduce, sortKeywords } from '@/Util';
import { SearchDirection, DSDDQuery } from '@/DSDDQuery';

import { ResultFacet, ResultKeyword, ResultConcept, ResultPoint, ResultVisual } from './SearchScopeTypes';
import { Canceler, AxiosError } from 'axios';

import Vue from 'vue';
import { Subject, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
// object-hash typings don't work with ts 3.9
// @ts-ignore
import ObjectHash from 'object-hash';
import { getVisuals } from './SearchScopeVisuals';
import { LatLng, LatLngBounds } from 'leaflet';

export const PAGE_SIZE = 20;

/**
 * Wrap a function that returns promises.
 * The function is called with the parameters passed to next().
 * The result of the promise is exposed as a property, along with the loading state, and the error (if any).
 * Upon calling next(), any pending promise is cancelled, and the previous results/error are cleared (optionally with a (up to infinite) delay).
 * If the function returns undefined, nothing happens and the current state is kept.
 */
export class AutoPromise<P = any, T = unknown> { // P = parameters, T = Result
	private pendingRequest?: Promise<T>;
	private pendingRequestCanceler?: Canceler;
	private pendingLoadingState?: number;
	private pendingClearResults?: number;
	private readonly params$ = new Subject<P>();
	private readonly subscriptions: Subscription[] = [];

	// NOTE: initialize these props, as vue needs something to make reactive
	public loading = false;
	public lastError?: Error = undefined;
	public lastResults?: T = undefined;

	constructor(
		private readonly createRequest: (p: P) => undefined|{req: Promise<T>, cancel: Canceler},
		/** below 0 will never set loading state */
		private readonly delayLoading = 1000,
		/** below 0 will never clear */
		private readonly delayClear = -1,
		/** below 0 will not debounce */
		debounce = -1,
		private readonly clearOnError = true,
	) {
		Vue.observable(this); // make it so that Vue components can use anything from this object and it will be reactive.

		let ob: Observable<P> = this.params$;
		if (debounce > 0) { ob = ob.pipe(debounceTime(debounce)); }
		ob = ob.pipe(distinctUntilChanged((x, y) => this.lastError ? false : ObjectHash(x) === ObjectHash(y)));
		this.subscriptions.push(ob.subscribe(params => this._next(params)));
	}

	public next(params: P) { this.params$.next(params); }
	/** Stops any running requests, and stops responding to next() calls. The autopromise will no longer function, and cannot be restarted. */
	public stop() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
		this.subscriptions.splice(0); // clear array
		this.abortPending(true);
	}

	private _next(params: P) {
		const next = this.createRequest(params);
		if (!next) { return; }
		const {req, cancel} = next;

		this.abortPending();
		this.pendingRequest = req;
		this.pendingRequestCanceler = cancel;
		this.pendingLoadingState =
			this.pendingLoadingState ? this.pendingLoadingState :
			this.delayLoading >= 0 ? window.setTimeout(() => { this.loading = true; this.pendingLoadingState = undefined; }, this.delayLoading) :
			undefined;
		this.pendingClearResults =
			this.pendingClearResults ? this.pendingClearResults :
			this.delayClear >= 0 ? window.setTimeout(() => this.lastResults = this.lastError = this.pendingClearResults = undefined, this.delayClear) :
			undefined;

		this.pendingRequest
		.then(
			r => {
				this.lastResults = Object.freeze(r); // response is readonly, so make sure vue doesn't recursively make it reactive, as that's unneeded
				this.lastError = undefined;
				this.loading = false;

				// only perform these on success and actual error, as we may just be cancelled, and we don't want to change states then
				this.pendingRequest = this.pendingRequestCanceler = undefined;
				if (this.pendingLoadingState != null) {
					window.clearTimeout(this.pendingLoadingState);
					this.pendingLoadingState = undefined;
				}
				if (this.pendingClearResults != null) {
					window.clearTimeout(this.pendingClearResults);
					this.pendingClearResults = undefined;
				}
			},
			(error: Error|AxiosError) => {
				if (!error.stack && !(error as AxiosError).config) { return; } // cancelled request
				if (this.clearOnError) { this.lastResults = undefined; }
				this.lastError = Object.freeze(error); // error is readonly,  so make sure vue doesn't recursively make it reactive, as that's unneeded
				this.loading = false;

				// only perform these on success and actual error, as we may just be cancelled, and we don't want to change states then
				this.pendingRequest = this.pendingRequestCanceler = undefined;
				if (this.pendingLoadingState != null) {
					window.clearTimeout(this.pendingLoadingState);
					this.pendingLoadingState = undefined;
				}
				if (this.pendingClearResults != null) {
					window.clearTimeout(this.pendingClearResults);
					this.pendingClearResults = undefined;
				}
			}
		);
	}

	private abortPending(abortLoadingState: boolean = false) {
		if (this.pendingRequest) {
			this.pendingRequestCanceler!();
			this.pendingRequest = undefined;
			this.pendingRequestCanceler = undefined;
		}

		if (abortLoadingState && this.pendingLoadingState) {
			clearTimeout(this.pendingLoadingState);
			this.pendingLoadingState = undefined;
		}
	}
}

const results = new AutoPromise((p: {
	searchWords?: string[],
	searchFilters?: MapOf<string[]>,
	searchDirection?: SearchDirection,
	searchArea?: L.LatLngBounds,
	searchPage?: number,
	/** concept_id or keyword_id, depending on direction */
	searchId?: string
}) => {
	const {
		searchWords = [],
		searchFilters = {},
		searchDirection = SearchDirection.s2d,
		searchArea,
		searchPage,
		searchId
	} = p;

	if (!searchWords.length && !Object.values(searchFilters).filter(v => v.length).length && searchId == null) {
		// no words, no filters, no id --> no results.
		return {
			req: Promise.resolve(undefined),
			cancel: () => {/**/}
		};
	}

	const queryParams = {
		// TODO update back-end so this can be false
		include_facets: true, // must be included or total results is not available
		include_data: true, // must be included or keywords are omitted and only concepts returned

		search_in: searchDirection === SearchDirection.s2d ? 'concepts' : 'keywords',
		word: searchId == null ? searchWords : undefined, // Backend: when ID is specified, word must be omitted and vice-versa
		id: searchId != null ? searchId : undefined,
		...Object
			.entries(searchFilters)
			.map(([key, value]) => ['filter.'+key, value] as [string, string[]])
			.reduce((acc, [key, value]) => {acc[key] = value; return acc;}, {} as MapOf<string[]>),
		'filter.area': searchArea ?
				searchArea.getSouth().toFixed(4)+','+
				searchArea.getWest().toFixed(4)+','+
				searchArea.getNorth().toFixed(4)+','+
				searchArea.getEast().toFixed(4)
				: undefined,
		start: searchPage == null ? undefined : (searchPage - 1) * PAGE_SIZE,
		rows: searchPage == null ? Math.pow(2,31)-1 : PAGE_SIZE,
	};

	// TODO keywords when appropriate (is that ever NOT the case?)
	// (we need concepts to show the concept displayname
	// and we need concept info to group by concept when displaying in the form
	// only place when we don't need concepts if perhaps when searching standard to dialect and showing the map
	const {req, cancel} = new DSDDQuery().get('concepts', queryParams);

	const returned_req = req.then(r => {
		const resultConcepts = mapReduce(Object.values(r.data.concepts), 'id', (concept): ResultConcept => ({
			id: concept.id,
			display: concept.display,
			definitions: concept.lemmata.map(l => [l.dictionary, l.definition]),
			concept_definition: concept.definition,
		}));
		const resultKeywords = mapReduce(r.data.concepts.flatMap(c => c.keywords.filter(kw => kw.data).map<ResultKeyword>(kw => ({
			id: kw.id,
			concept_id: c.id,
			display: searchDirection === SearchDirection.s2d ? kw.display : c.display,
			keyword_display: kw.display,
			concept_display: c.display,
			count: kw['data.count'],
			variants: kw.lexical_variants,
			places: [...new Set(kw.data!.map(d => d.place)).keys()].sort((a, b) => a.localeCompare(b)),
		}))), 'id');

		const resultPointsArray = r.data.concepts.flatMap(c =>  c.keywords.filter(kw => kw.data).flatMap(kw => kw.data!.map<ResultPoint>(d => {
			const pos = d.point.split(',');
			const lat = Number(pos[0]);
			const lng = Number(pos[1]);

			return {
				keyword_id: kw.id,
				country: d.country,
				dictionary: d.dictionary,
				province: d.province,
				place: d.place,
				lat,
				lng
			};
		})));

		const totalConceptCount = r.data.numFound;
		const totalKeywordCount = (r.data.facets && r.data.facets.stats) ? r.data.facets!.stats['keyword.count'] : 0; // facets not present when no results

		// NOTE: resultVisuals not part of this object.
		// The reason for this is that AutoPromise uses Object.freeze() on the results (i.e. the object returned below)
		// in order to exclude it from vue's reactivity system.
		// This is useful because making the search results reactive is a relatively large overhead, and not needed because the data is constant anyway.
		// HOWEVER: resultVisuals can be edited by the user, so we must keep those reactive, or the UI won't update.
		// This means we cannot add them to this object (again - as it's a non-reactive object due to being frozen)
		// So what we do instead, is keep it as a state variable in the DerivedResultsComputer
		// that variable we CAN keep reactive. We just have to make sure it stays in sync - but that's done over there.

		return {
			resultKeywords,
			resultConcepts,
			resultPointsArray,
			totalKeywordCount,
			totalConceptCount,

			_params: p
		};
	});

	return {req: returned_req, cancel};
}, 0, 2500, -1, true);

/** Make sure the computeds and data of this stay in sync with the types declared in SearchScopeChildProps (see SearchScope.vue) */
export const DerivedResultsComputer = Vue.extend({
	data: () => ({
		results,
		searchSort: 'az' as '12'|'21'|'az'|'za',
		// Keep the visuals here instead of in this.results, as this.results is not a reactive object (apart from the top-level properties)
		// (we also can't make it a computed, as it needs to be modifiable)
		resultVisuals: undefined as undefined|MapOf<ResultVisual>
	}),
	computed: {
		// To keep track of this, computeds only depend on those declared above them
		resultKeywordsArray(): ResultKeyword[]|undefined {
			if (!this.results.lastResults) { return undefined; }

			const kws = Object.values(this.results.lastResults.resultKeywords);
			return sortKeywords(this.searchSort, ['display', 'count'], kws);
		},
		resultConceptsArray(): ResultConcept[]|undefined {
			if (!this.results.lastResults) { return undefined; }
			return Object.values(this.results.lastResults.resultConcepts).sort((a, b) => a.display.localeCompare(b.display));
		},
		resultPointsArray(): ResultPoint[]|undefined {
			if (!this.results.lastResults) { return undefined; }
			return Object.values(this.results.lastResults.resultPointsArray);
		},

		resultKeywords(): MapOf<ResultKeyword>|undefined {
			if (!this.results.lastResults) { return undefined; }
			return this.results.lastResults.resultKeywords;
		},
		resultConcepts(): MapOf<ResultConcept>|undefined {
			if (!this.results.lastResults) { return undefined; }
			return this.results.lastResults.resultConcepts;
		},

		resultBounds(): L.LatLngBounds|undefined {
			if (!this.results.lastResults) { return undefined; }
			const points = this.resultPointsArray!;
			if (!points.length) { return undefined; }

			const maxLong = points.reduce((n, p) => Math.max(n, p.lng), Number.MIN_SAFE_INTEGER);
			const minLong = points.reduce((n, p) => Math.min(n, p.lng), Number.MAX_SAFE_INTEGER);
			const maxLat = points.reduce((n, p) => Math.max(n, p.lat), Number.MIN_SAFE_INTEGER);
			const minLat = points.reduce((n, p) => Math.min(n, p.lat), Number.MAX_SAFE_INTEGER);

			const southWest = new LatLng(minLat, minLong);
			const northEast = new LatLng(maxLat, maxLong);
			const bounds = new LatLngBounds(southWest, northEast);
			return bounds;
		},
		resultKeywordsByGroup(): Array<{group: string|null, keywords: string[]}>|undefined {
			if (!this.results.lastResults) { return undefined; }
			const groups = new Map<string|null, {keywords: string[], group: string|null, count: number} >();
			const vs = this.resultVisuals!;
			this.resultKeywordsArray!.forEach(kw => {
				const g = vs[kw.id].group; // group name, or null if ungrouped.
				if (!groups.has(g)) {
					groups.set(g, {keywords: [], group: g, count: 0});
				}
				const group = groups.get(g)!;
				group.keywords.push(kw.id);
				group.count += kw.count;
			});

			// sort groups large to small, and null group last
			const r = [...groups.values()].sort((a, b) => a.group === null ? 1 : b.group === null ? -1 : b.count - a.count);
			return r;
		},

		totalKeywordCount(): undefined|number { return this.results.lastResults && this.results.lastResults.totalKeywordCount; },
		totalConceptCount(): undefined|number { return this.results.lastResults && this.results.lastResults.totalConceptCount; },
		error(): undefined|Error { return this.results.lastError; },
		loading(): boolean { return this.results.loading; }
	},

	methods: {
		// wrap next and stop functions so 'this' is correct inside the function
		next: ((v: any) => results.next(v)) as typeof results['next'],
		stop: () => results.stop(),
		changeSort(v: '12'|'21'|'az'|'za') { this.searchSort = v; }
	},
	watch: {
		'results.lastResults': {
			immediate: true,
			handler() {
				this.resultVisuals = this.results.lastResults ?
					getVisuals(this.results.lastResults._params.searchWords, this.results.lastResults._params.searchFilters, this.resultKeywords!) :
					undefined;
			}
		}
	}
});


export const facetResults = new AutoPromise((p: {filters: MapOf<string[]>, dir: SearchDirection, terms: string[]}) => {
	const search_in: 'concepts'|'keywords' = p.dir === SearchDirection.s2d ? 'concepts' : 'keywords';
	const word = p.terms;
	const filters = p.filters;
	const include_facets = true;
	const include_data = false;
	const rows = 0;
	const returnType = 'keywords' as const; // otherwise facets are not returned

	const {req, cancel} = new DSDDQuery().get(returnType, {
		search_in,
		word,
		...Object
			.entries(filters || {})
			.map(([key, value]) => ['filter.'+key, value] as [string, string[]])
			.reduce((acc, [key, value]) => {acc[key] = value; return acc;}, {} as MapOf<string[]>),
		include_data,
		include_facets,
		rows,
	});

	const res = req.then(r => {
		if (!r.data.facets) {
			return {} as MapOf<ResultFacet[]>;
		}
		return Object.entries(r.data.facets!.bucketed)
		.reduce((output, [key, values]) => {
			output[key] = Object
				.entries(values!)
				.filter(([_value, count]) => count > 0)
				.sort(([value1], [value2]) => value1.localeCompare(value2)) // ascending alphabetically, helps with long lists
				.map(([v, c]) => ({
					label: `<span class="text-body">${v}</span> <small class="text-secondary">(${c.toLocaleString()})</small>`,
					value: v,
					count: c
				}));
			return output;
		}, {} as MapOf<ResultFacet[]>);
	});
	return {req: res, cancel};
});

export const suggestionResults = new AutoPromise((p: {term: string, dir: SearchDirection}) => {
	if (p.term) {
		const {req, cancel} = new DSDDQuery().get(p.dir === SearchDirection.s2d ? 'suggest/concepts' : 'suggest/keywords', {word: p.term});
		return { cancel, req: req.then(r => r.data.suggestions) };
	} else {
		return {req: Promise.resolve([]), cancel: () => {/*nothing to cancel but autopromise demands this exists*/}};
	}
}, 0, -1, 300);

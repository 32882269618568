var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"print-helper border border-dark px-5 pb-5"},[_vm._m(0),_c('div',{staticClass:"border-top border-dark row"},[_c('div',{staticClass:"col-6 border-right border-dark p-4"},[(_vm.displayWords)?_c('div',{staticClass:"mb-5"},[_c('h2',{staticClass:"d-inline-block border-bottom border-dark"},[_c('span',{staticClass:"fas fa-book fa-sm mr-2"}),_vm._v(" Zoekterm"+_vm._s(_vm.displayWords.length > 1 ? 'en' : ''))]),_c('br'),_c('div',{staticClass:"pl-2"},[(_vm.displayWords.length === 1)?_c('span',{staticClass:"ml-2",staticStyle:{"font-size":"1.5em"}},[_vm._v(_vm._s(_vm.displayWords[0]))]):_c('ul',_vm._l((_vm.displayWords),function(w){return _c('li',{key:w},[_vm._v(_vm._s(w))])}),0)])]):_vm._e(),(_vm.displayFilters)?_c('div',[_vm._m(1),_c('br'),_vm._l((_vm.displayFilters),function(f){return _c('div',{key:f[0],staticClass:"pl-2"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:(`facets.label.${f[0]}`),expression:"`facets.label.${f[0]}`"}]}),_c('ul',_vm._l((f[1]),function(v){return _c('li',{key:v},[_vm._v(_vm._s(v))])}),0)])})],2):_vm._e()]),_c('div',{staticClass:"col-6 p-4"},[_vm._m(2),_vm._l((_vm.legend),function({group, words},gi){return _c('div',{key:group,staticClass:"group"},[(group)?_c('h4',{staticClass:"border-bottom border-dark"},[_vm._v(_vm._s(group))]):(gi !== 0)?_c('hr',{staticClass:"mb-2 border-dark"}):_vm._e(),_c('div',{staticClass:"d-flex flex-row justify-content-between"},_vm._l((words),function(part,i){return _c('table',{key:i,staticClass:"legend-table"},[_c('tbody',_vm._l((part),function(w){return _c('tr',{key:w.id},[_c('td',{staticClass:"px-1",staticStyle:{"vertical-align":"top"}},[_c('span',{staticClass:"fa keyword-icon",class:w.class,style:({
									color: _vm.colors ? w.color : undefined,
									fontSize: _vm.sizes ? Math.sqrt(_vm.keyword.iconSize) + 'em' : undefined
								})})]),_c('td',{staticClass:"pr-3",staticStyle:{"vertical-align":"top"}},[_vm._v(_vm._s(w.display))]),_c('td',{staticClass:"text-right",staticStyle:{"vertical-align":"top"}},[_vm._v(_vm._s(w.count))])])}),0)])}),0)])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h1',{staticClass:"m-0 py-4"},[_c('span',{staticClass:"fas fa-search mr-3"}),_vm._v(" Zoekopdracht")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h2',{staticClass:"d-inline-block border-bottom border-dark"},[_c('span',{staticClass:"fas fa-filter fa-sm mr-2"}),_vm._v(" Filters")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h2',{staticClass:"d-inline-block border-bottom border-dark"},[_c('span',{staticClass:"fas fa-map-marker-alt fs-sm ml-2"},[_vm._v(" Legenda")])])
}]

export { render, staticRenderFns }

import Vue from 'vue';

import LongList from '@/components/LongList.vue';
import Concept from './Concept.vue';
import Result from './Result.vue';

import { multimapReduce } from '@/Util';
import { ResultConcept, ResultKeyword } from '@/scopes/SearchScopeTypes';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';

export default Vue.extend({
	components: {
		LongList,
		Concept,
		Result,
	},
	props: {
		...SearchScopeChildProps,
	},
	computed: {
		keywordsByConcept(): Array<{concept: ResultConcept, keywords: ResultKeyword[]}> {
			const kwsPerConcept = multimapReduce(this.resultKeywordsArray, 'concept_id');
			return this.resultConceptsArray!.map(concept => ({
				concept,
				keywords: kwsPerConcept[concept.id]
			}));
		}
	},
});


import Vue from 'vue';

import LongList from '@/components/LongList.vue';
import SortControl from '@/components/SortControl.vue';
import { ResultKeyword } from '@/scopes/SearchScopeTypes';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';

import InlineMap from '@/components/InlineMap.vue';


export default Vue.extend({
	components: {
		LongList,
		SortControl,
		InlineMap
	},
	props: {
		...SearchScopeChildProps,
		keywords: Array as () => ResultKeyword[],
	},
	data: () => ({
		showMap: undefined as undefined|ResultKeyword
	}),
	computed: {
		showVariants(): boolean {
			return this.keywords.some(kw => kw.variants.length);
		}
	},
});


import Vue from 'vue';

export default Vue.extend({
	props: {
		string: String,
		l: {
			type: Number,
			default: 150,
		}
	},
	data: () => ({
		expanded: false,
	}),
	computed: {
		truncate(): boolean { return !this.expanded && this.l < (this.string.length + 10); /* +10 voor ... (meer) */ },
		display(): string { return this.truncate ? this.string.substring(0, this.l) : this.string; },
	},
});


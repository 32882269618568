
import Vue from 'vue';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';

import LongString from '@/components/LongString.vue';
import LongList from '@/components/LongList.vue';
import { mapReduce } from '@/Util';
import { BvTableFieldArray, BvTableField } from 'bootstrap-vue';

import SelectPicker from '@/components/SelectPicker.vue';

import { PAGE_SIZE } from '@/scopes/SearchScopeGetResults';

export default Vue.extend({
	components: {LongString, LongList, SelectPicker},
	props: {
		...SearchScopeChildProps,
	},
	data: () => ({
		currentPage: 1,
		displayedFieldKeys: [] as string[],
		PAGE_SIZE
	}),
	computed: {
		fields(): Array<{key: string}&BvTableField> {
			if (!this.resultKeywordsArray || !this.resultKeywordsArray.length) {
				return [];
			}
			return ['index', ...Object.keys(this.resultKeywordsArray[0])].map(key => ({
				key,
				sortable: true,
			}));
		},

		allFieldKeys(): string[] {
			return this.fields.map(f => f.key);
		},
		displayedFields(): BvTableFieldArray {
			const isVisibleField = mapReduce(this.displayedFieldKeys);
			const key2Field = mapReduce(this.fields, 'key');

			return this.allFieldKeys.filter(k => isVisibleField[k]).map(k => key2Field[k]);
		}
	},
	watch: {
		allFieldKeys: {
			immediate: true,
			handler() {
				this.displayedFieldKeys = this.allFieldKeys.concat().filter(k => k !== 'id' && k !== 'concept_id');
			}
		}
	}
});

import { ResultKeyword, ResultVisual } from '@/scopes/SearchScopeTypes';
import { FAGlyphs, Glyph, MapOf, sortKeywords } from '@/Util';

type Group = {group: string|null, keywords: string[], count: number};
export type TableEntry = {
    display: string;
    count: number;
    symbols: Array<ResultVisual&Glyph>;
    isHeader: boolean;
}

/** Put in groups and keywords, get out entries (including group headers) in order */
export class NextGroupSummaryOrKeyword implements Iterable<TableEntry> {
    /** Groups after sorting, summarizing, or unpacking them where necessary (all are summarized, except the default group, which we unpack instead.) */
    private entries: TableEntry[] = [];
    
    constructor(
        groups: Group[],
        public keywords: MapOf<ResultKeyword>,
        public visuals: MapOf<ResultVisual>,
        public defaultGroupName: string,
        summarizeGroups: boolean,
        showOnlyWordsWithOccurances: [number, number],
        sort: '12'|'21'|'az'|'za',
    ) {
        // first order of business, remove hidden keywords and empty groups (those for which all keywords are hidden)
        // NB clone the groups, or we'd modify the original
        groups = groups.map(g => ({
            ...g, 
            keywords: g.keywords.filter(kw => this.visuals[kw].isVisible && this.keywords[kw].count >= showOnlyWordsWithOccurances[0] && this.keywords[kw].count <= showOnlyWordsWithOccurances[1]), 
            display: g.group,
            // remove hidden keywords from count.
            count: g.keywords.reduce((acc, kw) => acc + (visuals[kw].isVisible ? this.keywords[kw].count : 0), 0),
        }))
        .filter(g => g.keywords.length > 0);
        
        groups = sortKeywords(sort, ['count', 'group'], groups);
        
        // remove default group, put it back at the end. (it may have been sorted away from the last position, but we always put it last)
        const defaultGroupIndex = groups.findIndex(g => g.group == null);
        if (defaultGroupIndex !== -1) {
            const defaultGroup = groups[defaultGroupIndex];
            groups.splice(defaultGroupIndex, 1);
            groups.push(defaultGroup);
        }

        const hasOnlyDefaultGroup = groups.length === 1 && groups[0].group == null;
        if (hasOnlyDefaultGroup) summarizeGroups = true;
        if (summarizeGroups) {
            // Normal groups (i.e. those with a title) are summarized, but the default group is unpacked.
            // This is because a group with a title is usually a category, 
            // or to hide useless spelling differences for two words 
            // (ex. words "puit", "puid") These would be put in a group with title "puit"
            // So by summarizing the group we add the count of the two words together (and we get a single entry in the table instead of two)
            // The default group is unpacked because it's a list of words that don't have anything in common.
            // and we want them at the same importance level as the other words.
            this.entries = groups.flatMap<TableEntry>(g => g.group == null ? this.unpackGroup(g) : this.summarizeGroup(g));
            this.entries = sortKeywords(sort, ['count', 'display'], this.entries);
        } else {
            this.entries = groups.flatMap(g => {
                const sortedContents = sortKeywords(sort, ['count', 'display'], g.keywords.map(kw => keywords[kw]));
                const r: TableEntry[] = [{
                    ...this.summarizeGroup(g),
                    isHeader: true
                }, ...sortedContents.map(kw => ({
                    isHeader: false,
                    display: kw.display,
                    count: kw.count,
                    symbols: [{...this.visuals[kw.id], ...FAGlyphs[this.visuals[kw.id].icon]}],
                }))]
                return r;
            });
        }
    }

    private unpackGroup(g: Group): TableEntry[] {
        return g.keywords.map<TableEntry>(kw => ({
            isHeader: false,
            display: this.keywords[kw].display,
            count: this.keywords[kw].count,
            symbols: [{...this.visuals[kw], ...FAGlyphs[this.visuals[kw].icon]}],
        }));
    }
    private summarizeGroup(group: Group): TableEntry {
        const count = group.count;
        const display = group.group || this.defaultGroupName;
        const symbols = group.keywords
            .map(id => ({...this.visuals[id], ...FAGlyphs[this.visuals[id].icon]}))
            .reduce((acc, icon) => {
                if (!acc.some(i => i.icon === icon.icon && i.color === icon.color)) acc.push(icon)
                return acc;
        }, [] as Array<ResultVisual&Glyph>);
        return {
            display,
            count,
            symbols,
            isHeader: false,
        }
    }

    // for ... of, etc.
    [Symbol.iterator]() { return this.entries[Symbol.iterator](); }
}

export type DomCell = {colSpan: number; rowSpan: number}&({
    type: 'text'|'count';
    text: string;
}|{
    type: 'group';
    text: string;
}|{
    type: 'symbol';
    icons: Array<ResultVisual&Glyph>;
}|{ 
    type: 'reserved'
});

/** 
 * Return in order [row][column] 
 * Construct a table layout for the given cells, with the given options.
 * - numColumns is the number of columns, but a column may be wider than 1 cell (usually 3, for icon, text, and count).
 * 
 * - horizontalGroupLayout means that groups are laid out horizontally, rather than vertically.
 * horizontalGroupLayout === true means a full width row is used for the group header 
 * Then the contents of the group are spread over numColumns columns.
 * Then the next header.
 * Ex. :
 * TITLE
 * content1 content1 content1
 * content1 content1
 * TITLE 
 * content2 content2 content2
 * ... etc
 * 
 * horizontalGroupLayout === false, the layout is vertical, with the group header and its contents on the same column.
 * So 
 * TITLE      content2 
 * content1   content2
 * content1   TITLE
 * content1   content3
 * content1   content3
 * TITLE      ... etc (wrapping to the next column when needed, to fill numColumns)
 * content2
 * content2
 * content2
 * 
 * - showCounts means that the count is shown for each entry, or not. Changes the column width from 2 to 3 cells.
*/
export function doTableLayout(entries: TableEntry[], options?: {
    numColumns: number;
    horizontalGroupLayout: boolean;
    showCounts: boolean;
}): DomCell[][] {
    const {
        numColumns,
        horizontalGroupLayout,
        showCounts,
    } = Object.assign({
        numColumns: 2,
        horizontalGroupLayout: false,
        showCounts: true,
    }, options || {});

    const columnWidth = showCounts ? 3 : 2;
    const fullWidth = columnWidth * numColumns;

    /** Input split by group, every group in a separate array. */
    const entriesPerGroup: TableEntry[][] = entries.reduce<TableEntry[][]>((acc, cell) => {
        if (!acc.length || cell.isHeader) acc.push([]);
        acc[acc.length-1].push(cell);
        return acc;
    }, []);

    if (horizontalGroupLayout) {
        const ret: DomCell[][] = [];
        let rowIndex = 0;
        for (const group of entriesPerGroup) {
            const header = group.shift()!;
            // edge case. can occur on horizontal layout, when only default group exists. 
            // it is unpacked, therefor has no header. 
            // so if we were to render a header here, we'd lose the icon and count.
            if (!header.isHeader) group.unshift(header);
            else {
                ret[rowIndex++] = [{
                    type: 'group',
                    text: header.display,
                    colSpan: fullWidth,
                    rowSpan: 1,
                }];
            }

            // divide group contents over columns
            let longestColumn = 0;
            for (let columnIndex = 0; columnIndex < numColumns; columnIndex++) {
                const cellsPerColumn = Math.ceil(group.length / numColumns);
                const start = columnIndex * cellsPerColumn;
                const end = Math.min((columnIndex+1) * cellsPerColumn, group.length);
                const column = group.slice(start, end);
                if (column.length > longestColumn) longestColumn = column.length;

                for (let i = 0; i < column.length; i++) {
                    if (ret[rowIndex+i] == null) ret[rowIndex+i] = [];
                    ret[rowIndex+i][columnIndex*columnWidth] = {
                        type: 'symbol',
                        icons: column[i].symbols,
                        colSpan: 1,
                        rowSpan: 1,
                    }
                    ret[rowIndex+i][columnIndex*columnWidth+1] = {
                        type: 'text',
                        text: column[i].display,
                        colSpan: 1,
                        rowSpan: 1,
                    }
                    if (showCounts) {
                        ret[rowIndex+i][columnIndex*columnWidth+2] = {
                            type: 'count',
                            text: column[i].count.toLocaleString(),
                            colSpan: 1,
                            rowSpan: 1,
                        }
                    }
                }
            }
            rowIndex += longestColumn;
        }
        fillReservedCells(ret, fullWidth);
        return ret;
    }

    // alternative layout:
    // just stack groups on top of each other
    
    /** headers are double height to lend some weight. 
     *  This is done using rowspan.
     *  otherwise unwanted whitespace would appear in other columns where there is no header in the same row */
    const rows_per_header = 2; 
    // Compute the total number of entries in the table. 
    // Note that headers count double as they occupy 2 rows.
    // this is used to compute the average length of each column. So know when to wrap to the next column during layout.
    const groupHeaderCount = entriesPerGroup.reduce((groupCount, group) => group[0].isHeader ? groupCount + 1 : groupCount, 0);
    const minimumTotalCells = entriesPerGroup.reduce((acc, group) => acc + group.length, 0) + groupHeaderCount * (rows_per_header - 1);

    const ret: DomCell[][] = [];
    let rowIndex = 0;
    let columnIndex = 0;
    // Used to compute the number of rows we should target in the remaining columns    
    let cellsLeft = minimumTotalCells;
    let targetRowCount = Math.floor(cellsLeft / numColumns);
    const increaseRowLengthBy1 = cellsLeft % (targetRowCount * numColumns);
    for (let gi = 0; gi < entriesPerGroup.length; ++gi) {
        const group = entriesPerGroup[gi];
        for (let ci = 0; ci < group.length; ++ci) {
            --cellsLeft;

            const cell = group[ci];
            if (!ret[rowIndex]) ret[rowIndex] = [];
            
            const isLastColumn = columnIndex + columnWidth >= fullWidth;
            // filling row at index rowIndex, that means rowIndex rows already filled
            // so that means rows left is 
            // example:
            // target = 10
            // rowIndex = 8
            // so 8 rows filled
            // left = target - rowIndex
            // lastRow would be <= 1
            const targetRowCountThisColumn = targetRowCount + ((increaseRowLengthBy1 * columnWidth > columnIndex) ? 1 : 0);

            const rowsLeftAfterThisOne = targetRowCountThisColumn - rowIndex - 1;
            const isLastRow = rowsLeftAfterThisOne <= 0;

            if (cell.isHeader) {
                // just some approximation so we don't start a new group right at the end of a column
                const rowsLeft = targetRowCount - rowIndex;
                const isAlmostAtEndOfColumn = rowsLeft < Math.min(Math.max(3, targetRowCount * 0.2), 5);
                
                if (!isLastColumn && isAlmostAtEndOfColumn) {
                    columnIndex += columnWidth;
                    rowIndex = 0;
                    if (entriesPerGroup.length > 1)
                        targetRowCount = Math.ceil(cellsLeft / numColumns);
                }

                ret[rowIndex][columnIndex] = {
                    type: 'group',
                    text: cell.display,
                    colSpan: columnWidth,
                    rowSpan: rows_per_header,
                }; 
                rowIndex += rows_per_header;
                continue;
            } 
            
            ret[rowIndex][columnIndex] = {
                type: 'symbol',
                icons: cell.symbols,
                colSpan: 1,
                rowSpan: 1,
            }
            ret[rowIndex][columnIndex+1] = {
                type: 'text',
                text: cell.display,
                colSpan: 1,
                rowSpan: 1,
            }
            if (showCounts) {
                ret[rowIndex][columnIndex+2] = {
                    type: 'count',
                    text: cell.count.toLocaleString(),
                    colSpan: 1,
                    rowSpan: 1,
                }
            }

            const almostAtEndOfGroup = group.length - ci < 3;
            const isLastGroup = gi === entriesPerGroup.length - 1;

            const forceBreak = isLastGroup && !isLastColumn;
            const shouldBreak = !isLastColumn && isLastGroup;
            const preventBreak = isLastColumn || almostAtEndOfGroup;

            
            if (isLastRow && (forceBreak || (shouldBreak && !preventBreak))) {
                columnIndex += columnWidth;
                rowIndex = 0;
                if (entriesPerGroup.length > 1)
                    targetRowCount = Math.ceil(cellsLeft / numColumns);
            } else {
                rowIndex += 1;
            }
        }
    }

    fillReservedCells(ret, fullWidth);
    
    return ret as DomCell[][];
}

/** fill out missing cells, taking into account the rowSpan and colSpan */
function fillReservedCells(ret: DomCell[][], fullWidth: number) {
    for (let rowIndex = 0; rowIndex < ret.length; ++ rowIndex) {
        const row = ret[rowIndex];
        if (!row) continue;
        for (let col = 0; col < fullWidth; ++col) {
            const cell = row[col] = row[col] || {
                type: 'text',
                text: '',
                colSpan: 1,
                rowSpan: 1,
            }

            for (let colspan = 1; colspan < cell.colSpan; ++colspan) {
                row[col+colspan] = {
                    type: 'reserved',
                    colSpan: 0,
                    rowSpan: 0,
                }
            }

            for (let rowspan = 1; rowspan < cell.rowSpan; ++rowspan) {
                const offsetrow = ret[rowIndex+rowspan];
                if (!offsetrow) continue;
                for (let colspan = 0; colspan < cell.colSpan; ++colspan) {
                    offsetrow[col+colspan] = {
                        type: 'reserved',
                        colSpan: 0,
                        rowSpan: 0,
                    }
                }
            }
        }
    }
}
import seedrandom from 'seedrandom';
import tinycolor from 'tinycolor2';
import { LatLng, LatLngBounds } from 'leaflet';

export function max(x: number[]): number {
	const maxie = x.reduce(function(a, b) {
		return Math.max(a, b);
	});
	return maxie;
}

export function min(x: number[]): number {
	const minnie = x.reduce(function(a, b) {
		return Math.min(a, b);
	});
	return minnie;
}

export function distinctly<T>(a: T[]): T[] {
	const s: {[key: string]: T} = {};
	a.forEach((x) => (s[JSON.stringify(x)] = x));
	return Object.values(s);
}

export function shuffleArray<T>(array: T[], seed = '' + Math.random()): T[] {
	const r = seedrandom(seed);
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(r() * (i + 1));
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
}

export function distance(p1: L.LatLng, p2: L.LatLng): number {
	const dx = p1.lng - p2.lng;
	const dy = p1.lat - p2.lat;
	return Math.sqrt(dx * dx + dy * dy);
}

export function latLngFromString(v: string): L.LatLngBounds {
	const [minlat, minlong, maxlat, maxlong] = v.split('_').map(e => Number(e));
	return new LatLngBounds(
		new LatLng(minlat, minlong),
		new LatLng(maxlat, maxlong)
	);
}

export function latLngToString(v: L.LatLngBounds): string {
	return [
		v.getNorthWest().lat.toFixed(6),
		v.getNorthWest().lng.toFixed(6),
		v.getSouthEast().lat.toFixed(6),
		v.getSouthEast().lng.toFixed(6)
	].join('_');
}

export function wildcard2regex(wildcard: string): string {
	let characterIsEscaped = false;
	// we don't include backslash, since that's just pass-through
	// the only thing it does is cause us to passthrough the next wildcard character as-is
	const regexCharacters = '.[()^$+';

	let output = '';
	for (const c of wildcard) {
		switch (c) {
			case '?': output += characterIsEscaped ? c : '.'; break;
			case '*': output += characterIsEscaped ? c : '.*'; break;
			case '|': output += characterIsEscaped ? c : '|'; break;
			default: {
				output += characterIsEscaped || !regexCharacters.includes(c) ? c : '\\' + c;
			}
		}
		characterIsEscaped = characterIsEscaped ? false : c === '\\';
	}
	return output;
}

export function randomRgb(): string {
	return hslToRgb(Math.random(), Math.random(), Math.random());
}

export function hue2rgb(p: number, q: number, t: number) {
	if (t < 0) { t += 1; }
	if (t > 1) { t -= 1; }
	if (t < 1 / 6) { return p + (q - p) * 6 * t; }
	if (t < 1 / 2) { return q; }
	if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
	return p;
}

export const presetColors = [
	'#3333ff',
	'#ff3333',
	'#ffff33',
	'#33ff33',
	'#33ddff',
	'#7733ff',
	'#ff33dd',
	'#3355ff',
	'#ff5533',
	'#ddff33',
	'#33ff55',
	'#33ffff',
	'#9933ff',
	'#ff33bb',
	'#3377ff',
	'#ff7733',
	'#bbff33',
	'#33ff99',
	'#3399ff',
	'#bb33ff',
	'#ff3377',
	'#33bbff',
	'#ff9933',
	'#55ff33',
	'#5533ff',
	'#ff3399',
	'#ffbb33',
	'#77ff33',
	'#33ffdd',
	'#dd33ff',
	'#ff3355',
	'#ffdd33',
	'#99ff33',
	'#33ff77',
	'#33ffbb',
	'#ff33ff'
];
export const sortedPresetColors = presetColors.map(c => tinycolor(c)).sort((a, b) => a.toHsv().h - b.toHsv().h).map(c => c.toHexString());

/**
 *
 * @param h 0-1
 * @param s 0-1
 * @param l 0-1
 */
export function hslToRgb(h: number, s: number, l: number) {
	let _r: number;
	let _g: number;
	let _b: number;

	if (s === 0) {
		_r = _g = _b = l; // achromatic
	} else {
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const p = 2 * l - q;
		_r = hue2rgb(p, q, h + 1 / 3);
		_g = hue2rgb(p, q, h);
		_b = hue2rgb(p, q, h - 1 / 3);
	}

	const rgb = `#${Math.round(_r * 255).toString(16).padStart(2, '0')}${Math.round(_g * 255).toString(16).padStart(2, '0')}${Math.round(_b * 255).toString(16).padStart(2, '0')}`;
	return rgb;
}

export function isLightColor(color: string) {
	const [r, g, b] = color.length === 4 ? [
		Number.parseInt(color[1] + color[1], 16),
		Number.parseInt(color[2] + color[2], 16),
		Number.parseInt(color[3] + color[3], 16)
	] : [
		Number.parseInt(color[1] + [2], 16),
		Number.parseInt(color[3] + [4], 16),
		Number.parseInt(color[5] + [6], 16)
	];

	const luma = (r * 0.299 + g * 0.587 + b * 0.114) / 255;
	return luma > 0.6;
}

/**
 * Get a foreground color with sufficient contrast.
 * @param backgroundColor format #ABC or #1A2B3C
 */
export function textColor(backgroundColor: string): string {
	return isLightColor(backgroundColor) ? '#333' : '#FFF';
}

export function stripTags(s: string): string {
	return s && s.replace(/<.*?>/g, '').replace('[...]', '');
}

// ------------
// Map reducing
// ------------

export type MapOf<T> = {
	[key: string]: T;
};
export type KeyedMap<K extends string, T> = {
	[I in K]: T;
};
export type PartialKeyedMap<K extends string, T> = {
	[I in K]?: T;
};

/** Object.keys, but type return as Array<keyof ...> instead of string[] */
export const objectKeys = Object.keys as <T>(o: T) => Array<Extract<keyof T, string>>;
/** Object.entries, but preserve the types of the keys and values when known */
export const objectEntries = Object.entries as <T>(o: T) => Array<[Extract<keyof T, string>, T[keyof T]]>;

type KeysOfType<Base, Condition> = keyof Pick<Base, {
	[Key in keyof Base]: Base[Key] extends Condition ? Key : never
}[keyof Base]>;

// tslint:disable-next-line
export function makeMapReducer<T, V extends (t: T, i: number) => any = (t: T, i: number) => T>(k: KeysOfType<T, string|number>, m?: V): (m: MapOf<ReturnType<V>>, t: T, i: number) => MapOf<ReturnType<V>> {
	return (acc: MapOf<ReturnType<V>>, v: T, i: number): MapOf<ReturnType<V>> => {
		const kv = v[k] as any as string; // technically is string|number, but numbers are cast to strings when used as keys in an object
		acc[kv] = m ? m(v, i) : v;
		return acc;
	};
}

// tslint:disable-next-line
export function makeMultimapReducer<T, V extends (t: T, i: number) => any = (t: T, i: number) => T>(k: KeysOfType<T, string|number>, m?: V): (m: MapOf<Array<ReturnType<V>>>, t: T, i: number) => MapOf<Array<ReturnType<V>>> {
	return (acc: MapOf<Array<ReturnType<V>>>, v: T, i: number): MapOf<Array<ReturnType<V>>> => {
		const kv = v[k] as any as string; // technically is string|number, but numbers are cast to strings when used as keys in an object
		acc[kv] ? acc[kv].push(m ? m(v, i) : v) : acc[kv] = [m ? m(v, i) : v];
		return acc;
	};
}

/**
 * Turn an array of strings into a map of type {[key: string]: true}.
 * Optionally mapping the values to be something other than "true".
 *
 * @param t the array of strings to place in a map.
 * @param m (optional) a mapping function to apply to values.
 */
export function mapReduce<VS extends (t: string, i: number) => any = (t: string, i: number) => true>(t: string[]|undefined|null, m?: VS): MapOf<ReturnType<VS>>;
/**
 * Turn an array of type T[] into a map of type {[key: string]: T}.
 * Optionally mapping the values to be something other than "true".
 *
 * @param t the array of objects to place in a map.
 * @param k a key in the objects to use as key in the map.
 * @param m (optional) a mapping function to apply to values.
 */
export function mapReduce<T, VT extends (t: T, i: number) => any = (t: T, i: number) => T>(t: T[]|undefined|null, k: KeysOfType<T, string|number>, m?: VT): MapOf<ReturnType<VT>>;
// tslint:disable-next-line
export function mapReduce<T, VT extends (t: T, i: number) => any = (t: T, i: number) => T, VS extends (t: string, i: number) => any = (t: string, i: number) => true>(t: string[]|T[]|undefined|null,a?: VS|KeysOfType<T, string|number>,b?: VT): any {
	if (t && t.length > 0 && typeof t[0] === 'string') {
		const values = t as string[];
		const mapper = a as VS|undefined;
		return values.reduce<MapOf<ReturnType<VS>>>((acc, cur, index) => {
			acc[cur] = mapper ? mapper(cur, index) : true;
			return acc;
		}, {});
	} else {
		const values = t as T[]|undefined|null;
		const key = a as KeysOfType<T, string|number>;
		const mapper = b as VT|undefined;
		return values ? values.reduce(makeMapReducer<T>(key, mapper), {}) : {};
	}
}

/**
 * Turn an array of type T[] into a map of type {[key: string]: T[]};
 * Duplicate keys will be pushed into the array at that key in order of appearance.
 *
 * @param t the array of objects to place in a map.
 * @param k a key in the objects to use as key in the map.
 * @param m (optional) a mapping function to apply to values.
 */
// tslint:disable-next-line
export function multimapReduce<T, V extends (t: T, i: number) => any = (t: T, i: number) => T>(t: T[]|undefined|null, k: KeysOfType<T, string|number>, m?: V): MapOf<Array<ReturnType<V>>> {
	return t ? t.reduce(makeMultimapReducer<T, V>(k, m), {}) : {};
}

export function filterDuplicates<T>(t: T[]|null|undefined, k: KeysOfType<T, string|number>): T[] {
	const found = new Set<T[KeysOfType<T, string|number>]>();
	return t ? t.filter(v => {
		if (!found.has(v[k])) {
			found.add(v[k]);
			return true;
		}
		return false;
	}) : [];
}

export function sortKeywords<T = {count: number, display: string}>(sort: 'az'|'za'|'12'|'21', keys: Array<keyof T>, keywords: T[]): T[] {
	if (!keywords || !keywords.length) return keywords;
	
	const ascending = sort === 'az' || sort === '12';
	const order = ascending ? 1 : -1;
	
	const numericKeys: typeof keys = [];
	const stringKeys: typeof keys = [];
	for (const k of keys) {
		const v = keywords[0][k] != null ? keywords[0][k] : keywords[1] ? keywords[1][k] : undefined;
		const keyIsNumeric = typeof v === 'number';
		(keyIsNumeric ? numericKeys : stringKeys).push(k);
	}
	const numericKeysFirst = sort === '12' || sort === '21';
	keys = numericKeysFirst ? numericKeys.concat(stringKeys) : stringKeys.concat(numericKeys);

	return keywords.concat().sort((a, b) => {
		for (const k of keys) {
			const aKey = a[k] as any;
			const bKey = b[k] as any;
			if (aKey !== bKey) {
				if (typeof aKey === 'number') {
					return (aKey - bKey) * order;
				} else {
					if (aKey == null) return bKey == null ? 0 : 1;
					if (bKey == null) return -1;
					return aKey.localeCompare(bKey) * order;
				}
			}
		}
		return 0;
	});
}

// ------------

export type Glyph = {
	/** Css class to set on the <span> to render this glyph */
	class: string;
	/** Alternatively, font-family to set in cases no CSS class can be applied (such as in map rendering) */
	font: string;
	/** Display name for the icon/glyph */
	name: string;
	/** Charcode for the glyph. */
	glyph: string;
};

// NOTE: keywords get assigned symbols based on the order of this list, sorted by number of occurances in descending order
// So, the word with the most occurances gets the first symbol, the second most gets the second symbol, etc.
export const FAGlyphsArray: Glyph[] = [
	{ class: 'dsdd-symbol dsdd-slash-mirror', font: 'DSDD-symbols', name: 'slash-mirror', glyph: '' },
	{ class: 'dsdd-symbol dsdd-circle-outline', font: 'DSDD-symbols', name: 'circle-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-square-solid', font: 'DSDD-symbols', name: 'square-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-bar-outline-vertical', font: 'DSDD-symbols', name: 'bar-outline-vertical', glyph: '' },
	{ class: 'dsdd-symbol dsdd-bar-solid-horizontal', font: 'DSDD-symbols', name: 'bar-solid-horizontal', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-outline-down', font: 'DSDD-symbols', name: 'triangle-outline-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-solid-up', font: 'DSDD-symbols', name: 'triangle-solid-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-square-outline-45', font: 'DSDD-symbols', name: 'square-outline-45', glyph: '' },
	{ class: 'dsdd-symbol dsdd-circle-solid', font: 'DSDD-symbols', name: 'circle-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-square-outline', font: 'DSDD-symbols', name: 'square-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-diamond-solid-horizontal', font: 'DSDD-symbols', name: 'diamond-solid-horizontal', glyph: '' },
	{ class: 'dsdd-symbol dsdd-bar-outline-horizontal', font: 'DSDD-symbols', name: 'bar-outline-horizontal', glyph: '' },
	{ class: 'dsdd-symbol dsdd-bar-solid-vertical', font: 'DSDD-symbols', name: 'bar-solid-vertical', glyph: '' },
	{ class: 'dsdd-symbol dsdd-slash', font: 'DSDD-symbols', name: 'slash', glyph: '' },
	{ class: 'dsdd-symbol dsdd-square-solid-45', font: 'DSDD-symbols', name: 'square-solid-45', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-outline-up', font: 'DSDD-symbols', name: 'triangle-outline-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-solid-down', font: 'DSDD-symbols', name: 'triangle-solid-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-star-outline', font: 'DSDD-symbols', name: 'star-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-solid-left', font: 'DSDD-symbols', name: 'triangle-solid-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-outline-right', font: 'DSDD-symbols', name: 'triangle-outline-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-star-solid', font: 'DSDD-symbols', name: 'star-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-outline-left', font: 'DSDD-symbols', name: 'triangle-outline-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-triangle-solid-right', font: 'DSDD-symbols', name: 'triangle-solid-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-diamond-outline-vertical', font: 'DSDD-symbols', name: 'diamond-outline-vertical', glyph: '' },
	{ class: 'dsdd-symbol dsdd-diamond-solid-vertical', font: 'DSDD-symbols', name: 'diamond-solid-vertical', glyph: '' },
	{ class: 'dsdd-symbol dsdd-plus-solid-45', font: 'DSDD-symbols', name: 'plus-solid-45', glyph: '' },
	{ class: 'dsdd-symbol dsdd-hexagon-outline', font: 'DSDD-symbols', name: 'hexagon-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-asterisk-solid', font: 'DSDD-symbols', name: 'asterisk-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-plus-solid', font: 'DSDD-symbols', name: 'plus-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-hexagon-solid', font: 'DSDD-symbols', name: 'hexagon-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-pin-right', font: 'DSDD-symbols', name: 'pin-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-heart-solid', font: 'DSDD-symbols', name: 'heart-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-up', font: 'DSDD-symbols', name: 'arrow-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-solid-left', font: 'DSDD-symbols', name: 'arrow-solid-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-left', font: 'DSDD-symbols', name: 'arrow-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-caret-solid-right', font: 'DSDD-symbols', name: 'caret-solid-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-right', font: 'DSDD-symbols', name: 'arrow-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-ban', font: 'DSDD-symbols', name: 'ban', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-down', font: 'DSDD-symbols', name: 'arrow-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-caret-solid-left', font: 'DSDD-symbols', name: 'caret-solid-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-solid-down', font: 'DSDD-symbols', name: 'arrow-solid-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-caret-solid-up', font: 'DSDD-symbols', name: 'caret-solid-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-heart-outline', font: 'DSDD-symbols', name: 'heart-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-solid-up', font: 'DSDD-symbols', name: 'arrow-solid-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-caret-solid-down', font: 'DSDD-symbols', name: 'caret-solid-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-target', font: 'DSDD-symbols', name: 'target', glyph: '' },
	{ class: 'dsdd-symbol dsdd-lightning-solid', font: 'DSDD-symbols', name: 'lightning-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-ban-mirror', font: 'DSDD-symbols', name: 'ban-mirror', glyph: '' },
	{ class: 'dsdd-symbol dsdd-arrow-solid-right', font: 'DSDD-symbols', name: 'arrow-solid-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-lightning-outline', font: 'DSDD-symbols', name: 'lightning-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-plus-outline', font: 'DSDD-symbols', name: 'plus-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-plus-outline-45', font: 'DSDD-symbols', name: 'plus-outline-45', glyph: '' },
	{ class: 'dsdd-symbol dsdd-map-marker-outline', font: 'DSDD-symbols', name: 'map-marker-outline', glyph: '' },
	{ class: 'dsdd-symbol dsdd-map-marker-solid', font: 'DSDD-symbols', name: 'map-marker-solid', glyph: '' },
	{ class: 'dsdd-symbol dsdd-crosshair', font: 'DSDD-symbols', name: 'crosshair', glyph: '' },
	{ class: 'dsdd-symbol dsdd-crosshair-with-dot', font: 'DSDD-symbols', name: 'crosshair-with-dot', glyph: '' },
	{ class: 'dsdd-symbol dsdd-pin-up', font: 'DSDD-symbols', name: 'pin-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-pin-down', font: 'DSDD-symbols', name: 'pin-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-pin-left', font: 'DSDD-symbols', name: 'pin-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-solid-right-down', font: 'DSDD-symbols', name: 'wedge-solid-right-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-solid-left-down', font: 'DSDD-symbols', name: 'wedge-solid-left-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-solid-right-up', font: 'DSDD-symbols', name: 'wedge-solid-right-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-solid-left-up', font: 'DSDD-symbols', name: 'wedge-solid-left-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-outline-right-down', font: 'DSDD-symbols', name: 'wedge-outline-right-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-outline-left-down', font: 'DSDD-symbols', name: 'wedge-outline-left-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-outline-right-up', font: 'DSDD-symbols', name: 'wedge-outline-right-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-wedge-outline-left-up', font: 'DSDD-symbols', name: 'wedge-outline-left-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-diamond-outline-horizontal', font: 'DSDD-symbols', name: 'diamond-outline-horizontal', glyph: '' },
	{ class: 'dsdd-symbol dsdd-tall-triangle-solid-up', font: 'DSDD-symbols', name: 'tall-triangle-solid-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-tall-triangle-solid-down', font: 'DSDD-symbols', name: 'tall-triangle-solid-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-tall-triangle-solid-left', font: 'DSDD-symbols', name: 'tall-triangle-solid-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-tall-triangle-solid-right', font: 'DSDD-symbols', name: 'tall-triangle-solid-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-outline-left', font: 'DSDD-symbols', name: 'double-chevron-outline-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-outline-right', font: 'DSDD-symbols', name: 'double-chevron-outline-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-circle', font: 'DSDD-symbols', name: 'half-circle', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-circle-mirror', font: 'DSDD-symbols', name: 'half-circle-mirror', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-outline-up', font: 'DSDD-symbols', name: 'double-chevron-outline-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-outline-down', font: 'DSDD-symbols', name: 'double-chevron-outline-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-solid-left', font: 'DSDD-symbols', name: 'double-chevron-solid-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-solid-right', font: 'DSDD-symbols', name: 'double-chevron-solid-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-square-down', font: 'DSDD-symbols', name: 'half-square-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-square-up', font: 'DSDD-symbols', name: 'half-square-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-solid-up', font: 'DSDD-symbols', name: 'double-chevron-solid-up', glyph: '' },
	{ class: 'dsdd-symbol dsdd-double-chevron-solid-down', font: 'DSDD-symbols', name: 'double-chevron-solid-down', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-square-right', font: 'DSDD-symbols', name: 'half-square-right', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-square-left', font: 'DSDD-symbols', name: 'half-square-left', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-wedge', font: 'DSDD-symbols', name: 'half-wedge', glyph: '' },
	{ class: 'dsdd-symbol dsdd-half-wegde-mirror', font: 'DSDD-symbols', name: 'half-wegde-mirror', glyph: '' },
	{ class: 'fas fa-anchor', font: 'Font Awesome 5 Free', name: 'anchor', glyph: '' },
	{ class: 'fas fa-ankh', font: 'Font Awesome 5 Free', name: 'ankh', glyph: '' },
	{ class: 'fas fa-apple-alt', font: 'Font Awesome 5 Free', name: 'apple-alt', glyph: '' },
	{ class: 'fas fa-archway', font: 'Font Awesome 5 Free', name: 'archway', glyph: '' },
	{ class: 'fas fa-atom', font: 'Font Awesome 5 Free', name: 'atom', glyph: '' },
	{ class: 'fas fa-award', font: 'Font Awesome 5 Free', name: 'award', glyph: '' },
	{ class: 'fas fa-backward', font: 'Font Awesome 5 Free', name: 'backward', glyph: '' },
	{ class: 'fas fa-bacon', font: 'Font Awesome 5 Free', name: 'bacon', glyph: '' },
	{ class: 'fas fa-balance-scale', font: 'Font Awesome 5 Free', name: 'balance-scale', glyph: '' },
	{ class: 'fas fa-band-aid', font: 'Font Awesome 5 Free', name: 'band-aid', glyph: '' },
	{ class: 'fas fa-bars', font: 'Font Awesome 5 Free', name: 'bars', glyph: '' },
	{ class: 'fas fa-basketball-ball', font: 'Font Awesome 5 Free', name: 'basketball-ball', glyph: '' },
	{ class: 'fas fa-bath', font: 'Font Awesome 5 Free', name: 'bath', glyph: '' },
	{ class: 'fas fa-battery-empty', font: 'Font Awesome 5 Free', name: 'battery-empty', glyph: '' },
	{ class: 'fas fa-battery-full', font: 'Font Awesome 5 Free', name: 'battery-full', glyph: '' },
	{ class: 'fas fa-bed', font: 'Font Awesome 5 Free', name: 'bed', glyph: '' },
	{ class: 'fas fa-beer', font: 'Font Awesome 5 Free', name: 'beer', glyph: '' },
	{ class: 'fas fa-bell', font: 'Font Awesome 5 Free', name: 'bell', glyph: '' },
	{ class: 'fas fa-bible', font: 'Font Awesome 5 Free', name: 'bible', glyph: '' },
	{ class: 'fas fa-biking', font: 'Font Awesome 5 Free', name: 'biking', glyph: '' },
	{ class: 'fas fa-binoculars', font: 'Font Awesome 5 Free', name: 'binoculars', glyph: '' },
	{ class: 'fas fa-biohazard', font: 'Font Awesome 5 Free', name: 'biohazard', glyph: '' },
	{ class: 'fas fa-birthday-cake', font: 'Font Awesome 5 Free', name: 'birthday-cake', glyph: '' },
	{ class: 'fas fa-bolt', font: 'Font Awesome 5 Free', name: 'bolt', glyph: '' },
	{ class: 'fas fa-bomb', font: 'Font Awesome 5 Free', name: 'bomb', glyph: '' },
	{ class: 'fas fa-bone', font: 'Font Awesome 5 Free', name: 'bone', glyph: '' },
	{ class: 'fas fa-bong', font: 'Font Awesome 5 Free', name: 'bong', glyph: '' },
	{ class: 'fas fa-book', font: 'Font Awesome 5 Free', name: 'book', glyph: '' },
	{ class: 'fas fa-book-open', font: 'Font Awesome 5 Free', name: 'book-open', glyph: '' },
	{ class: 'fas fa-bookmark', font: 'Font Awesome 5 Free', name: 'bookmark', glyph: '' },
	{ class: 'fas fa-border-all', font: 'Font Awesome 5 Free', name: 'border-all', glyph: '' },
	{ class: 'fas fa-bowling-ball', font: 'Font Awesome 5 Free', name: 'bowling-ball', glyph: '' },
	{ class: 'fas fa-box', font: 'Font Awesome 5 Free', name: 'box', glyph: '' },
	{ class: 'fas fa-boxes', font: 'Font Awesome 5 Free', name: 'boxes', glyph: '' },
	{ class: 'fas fa-brain', font: 'Font Awesome 5 Free', name: 'brain', glyph: '' },
	{ class: 'fas fa-bread-slice', font: 'Font Awesome 5 Free', name: 'bread-slice', glyph: '' },
	{ class: 'fas fa-brush', font: 'Font Awesome 5 Free', name: 'brush', glyph: '' },
	{ class: 'fas fa-bug', font: 'Font Awesome 5 Free', name: 'bug', glyph: '' },
	{ class: 'fas fa-building', font: 'Font Awesome 5 Free', name: 'building', glyph: '' },
	{ class: 'fas fa-burn', font: 'Font Awesome 5 Free', name: 'burn', glyph: '' },
	{ class: 'fas fa-bus', font: 'Font Awesome 5 Free', name: 'bus', glyph: '' },
	{ class: 'fas fa-calendar', font: 'Font Awesome 5 Free', name: 'calendar', glyph: '' },
	{ class: 'fas fa-camera', font: 'Font Awesome 5 Free', name: 'camera', glyph: '' },
	{ class: 'fas fa-campground', font: 'Font Awesome 5 Free', name: 'campground', glyph: '' },
	{ class: 'fas fa-candy-cane', font: 'Font Awesome 5 Free', name: 'candy-cane', glyph: '' },
	{ class: 'fas fa-cannabis', font: 'Font Awesome 5 Free', name: 'cannabis', glyph: '' },
	{ class: 'fas fa-car-side', font: 'Font Awesome 5 Free', name: 'car-side', glyph: '' },
	{ class: 'fas fa-carrot', font: 'Font Awesome 5 Free', name: 'carrot', glyph: '' },
	{ class: 'fas fa-cat', font: 'Font Awesome 5 Free', name: 'cat', glyph: '' },
	{ class: 'fas fa-certificate', font: 'Font Awesome 5 Free', name: 'certificate', glyph: '' },
	{ class: 'fas fa-chair', font: 'Font Awesome 5 Free', name: 'chair', glyph: '' },
	{ class: 'fas fa-cheese', font: 'Font Awesome 5 Free', name: 'cheese', glyph: '' },
	{ class: 'fas fa-child', font: 'Font Awesome 5 Free', name: 'child', glyph: '' },
	{ class: 'fas fa-church', font: 'Font Awesome 5 Free', name: 'church', glyph: '' },
	{ class: 'fas fa-city', font: 'Font Awesome 5 Free', name: 'city', glyph: '' },
	{ class: 'fas fa-clinic-medical', font: 'Font Awesome 5 Free', name: 'clinic-medical', glyph: '' },
	{ class: 'fas fa-clock', font: 'Font Awesome 5 Free', name: 'clock', glyph: '' },
	{ class: 'fas fa-cloud', font: 'Font Awesome 5 Free', name: 'cloud', glyph: '' },
	{ class: 'fas fa-cloud-moon', font: 'Font Awesome 5 Free', name: 'cloud-moon', glyph: '' },
	{ class: 'fas fa-cloud-moon-rain', font: 'Font Awesome 5 Free', name: 'cloud-moon-rain', glyph: '' },
	{ class: 'fas fa-cloud-rain', font: 'Font Awesome 5 Free', name: 'cloud-rain', glyph: '' },
	{ class: 'fas fa-cloud-showers-heavy', font: 'Font Awesome 5 Free', name: 'cloud-showers-heavy', glyph: '' },
	{ class: 'fas fa-cloud-sun', font: 'Font Awesome 5 Free', name: 'cloud-sun', glyph: '' },
	{ class: 'fas fa-cloud-sun-rain', font: 'Font Awesome 5 Free', name: 'cloud-sun-rain', glyph: '' },
	{ class: 'fas fa-cocktail', font: 'Font Awesome 5 Free', name: 'cocktail', glyph: '' },
	{ class: 'fas fa-code-branch', font: 'Font Awesome 5 Free', name: 'code-branch', glyph: '' },
	{ class: 'fas fa-cog', font: 'Font Awesome 5 Free', name: 'cog', glyph: '' },
	{ class: 'fas fa-cogs', font: 'Font Awesome 5 Free', name: 'cogs', glyph: '' },
	{ class: 'fas fa-coins', font: 'Font Awesome 5 Free', name: 'coins', glyph: '' },
	{ class: 'fas fa-comment', font: 'Font Awesome 5 Free', name: 'comment', glyph: '' },
	{ class: 'fas fa-compact-disc', font: 'Font Awesome 5 Free', name: 'compact-disc', glyph: '' },
	{ class: 'fas fa-compass', font: 'Font Awesome 5 Free', name: 'compass', glyph: '' },
	{ class: 'fas fa-compress', font: 'Font Awesome 5 Free', name: 'compress', glyph: '' },
	{ class: 'fas fa-concierge-bell', font: 'Font Awesome 5 Free', name: 'concierge-bell', glyph: '' },
	{ class: 'fas fa-cookie', font: 'Font Awesome 5 Free', name: 'cookie', glyph: '' },
	{ class: 'fas fa-copy', font: 'Font Awesome 5 Free', name: 'copy', glyph: '' },
	{ class: 'fas fa-couch', font: 'Font Awesome 5 Free', name: 'couch', glyph: '' },
	{ class: 'fas fa-cross', font: 'Font Awesome 5 Free', name: 'cross', glyph: '' },
	{ class: 'fas fa-crosshairs', font: 'Font Awesome 5 Free', name: 'crosshairs', glyph: '' },
	{ class: 'fas fa-crow', font: 'Font Awesome 5 Free', name: 'crow', glyph: '' },
	{ class: 'fas fa-crown', font: 'Font Awesome 5 Free', name: 'crown', glyph: '' },
	{ class: 'fas fa-crutch', font: 'Font Awesome 5 Free', name: 'crutch', glyph: '' },
	{ class: 'fas fa-cube', font: 'Font Awesome 5 Free', name: 'cube', glyph: '' },
	{ class: 'fas fa-cubes', font: 'Font Awesome 5 Free', name: 'cubes', glyph: '' },
	{ class: 'fas fa-cut', font: 'Font Awesome 5 Free', name: 'cut', glyph: '' },
	{ class: 'fas fa-database', font: 'Font Awesome 5 Free', name: 'database', glyph: '' },
	{ class: 'fas fa-dharmachakra', font: 'Font Awesome 5 Free', name: 'dharmachakra', glyph: '' },
	{ class: 'fas fa-dice-d6', font: 'Font Awesome 5 Free', name: 'dice-d6', glyph: '' },
	{ class: 'fas fa-dna', font: 'Font Awesome 5 Free', name: 'dna', glyph: '' },
	{ class: 'fas fa-dog', font: 'Font Awesome 5 Free', name: 'dog', glyph: '' },
	{ class: 'fas fa-dollar-sign', font: 'Font Awesome 5 Free', name: 'dollar-sign', glyph: '' },
	{ class: 'fas fa-dove', font: 'Font Awesome 5 Free', name: 'dove', glyph: '' },
	{ class: 'fas fa-dumbbell', font: 'Font Awesome 5 Free', name: 'dumbbell', glyph: '' },
	{ class: 'fas fa-dungeon', font: 'Font Awesome 5 Free', name: 'dungeon', glyph: '' },
	{ class: 'fas fa-egg', font: 'Font Awesome 5 Free', name: 'egg', glyph: '' },
	{ class: 'fas fa-envelope', font: 'Font Awesome 5 Free', name: 'envelope', glyph: '' },
	{ class: 'fas fa-eraser', font: 'Font Awesome 5 Free', name: 'eraser', glyph: '' },
	{ class: 'fas fa-fan', font: 'Font Awesome 5 Free', name: 'fan', glyph: '' },
	{ class: 'fas fa-feather-alt', font: 'Font Awesome 5 Free', name: 'feather-alt', glyph: '' },
	{ class: 'fas fa-filter', font: 'Font Awesome 5 Free', name: 'filter', glyph: '' },
	{ class: 'fas fa-fire', font: 'Font Awesome 5 Free', name: 'fire', glyph: '' },
	{ class: 'fas fa-fish', font: 'Font Awesome 5 Free', name: 'fish', glyph: '' },
	{ class: 'fas fa-forward', font: 'Font Awesome 5 Free', name: 'forward', glyph: '' },
	{ class: 'fas fa-frog', font: 'Font Awesome 5 Free', name: 'frog', glyph: '' },
	{ class: 'fas fa-gem', font: 'Font Awesome 5 Free', name: 'gem', glyph: '' },
	{ class: 'fas fa-genderless', font: 'Font Awesome 5 Free', name: 'genderless', glyph: '' },
	{ class: 'fas fa-ghost', font: 'Font Awesome 5 Free', name: 'ghost', glyph: '' },
	{ class: 'fas fa-glass-martini', font: 'Font Awesome 5 Free', name: 'glass-martini', glyph: '' },
	{ class: 'fas fa-globe', font: 'Font Awesome 5 Free', name: 'globe', glyph: '' },
	{ class: 'fas fa-greater-than', font: 'Font Awesome 5 Free', name: 'greater-than', glyph: '' },
	{ class: 'fas fa-guitar', font: 'Font Awesome 5 Free', name: 'guitar', glyph: '' },
	{ class: 'fas fa-hamburger', font: 'Font Awesome 5 Free', name: 'hamburger', glyph: '' },
	{ class: 'fas fa-hammer', font: 'Font Awesome 5 Free', name: 'hammer', glyph: '' },
	{ class: 'fas fa-hat-cowboy', font: 'Font Awesome 5 Free', name: 'hat-cowboy', glyph: '' },
	{ class: 'fas fa-hat-wizard', font: 'Font Awesome 5 Free', name: 'hat-wizard', glyph: '' },
	{ class: 'fas fa-hippo', font: 'Font Awesome 5 Free', name: 'hippo', glyph: '' },
	{ class: 'fas fa-hockey-puck', font: 'Font Awesome 5 Free', name: 'hockey-puck', glyph: '' },
	{ class: 'fas fa-home', font: 'Font Awesome 5 Free', name: 'home', glyph: '' },
	{ class: 'fas fa-horse', font: 'Font Awesome 5 Free', name: 'horse', glyph: '' },
	{ class: 'fas fa-hourglass', font: 'Font Awesome 5 Free', name: 'hourglass', glyph: '' },
	{ class: 'fas fa-ice-cream', font: 'Font Awesome 5 Free', name: 'ice-cream', glyph: '' },
	{ class: 'fas fa-igloo', font: 'Font Awesome 5 Free', name: 'igloo', glyph: '' },
	{ class: 'fas fa-infinity', font: 'Font Awesome 5 Free', name: 'infinity', glyph: '' },
	{ class: 'fas fa-italic', font: 'Font Awesome 5 Free', name: 'italic', glyph: '' },
	{ class: 'fas fa-key', font: 'Font Awesome 5 Free', name: 'key', glyph: '' },
	{ class: 'fas fa-kiwi-bird', font: 'Font Awesome 5 Free', name: 'kiwi-bird', glyph: '' },
	{ class: 'fas fa-leaf', font: 'Font Awesome 5 Free', name: 'leaf', glyph: '' },
	{ class: 'fas fa-lemon', font: 'Font Awesome 5 Free', name: 'lemon', glyph: '' },
	{ class: 'fas fa-less-than', font: 'Font Awesome 5 Free', name: 'less-than', glyph: '' },
	{ class: 'fas fa-lightbulb', font: 'Font Awesome 5 Free', name: 'lightbulb', glyph: '' },
	{ class: 'fas fa-location-arrow', font: 'Font Awesome 5 Free', name: 'location-arrow', glyph: '' },
	{ class: 'fas fa-lock', font: 'Font Awesome 5 Free', name: 'lock', glyph: '' },
	{ class: 'fas fa-magnet', font: 'Font Awesome 5 Free', name: 'magnet', glyph: '' },
	{ class: 'fas fa-map', font: 'Font Awesome 5 Free', name: 'map', glyph: '' },
	{ class: 'fas fa-map-marked', font: 'Font Awesome 5 Free', name: 'map-marked', glyph: '' },
	{ class: 'fas fa-map-marker', font: 'Font Awesome 5 Free', name: 'map-marker', glyph: '' },
	{ class: 'fas fa-map-marker-alt', font: 'Font Awesome 5 Free', name: 'map-marker-alt', glyph: '' },
	{ class: 'fas fa-map-pin', font: 'Font Awesome 5 Free', name: 'map-pin', glyph: '' },
	{ class: 'fas fa-mask', font: 'Font Awesome 5 Free', name: 'mask', glyph: '' },
	{ class: 'fas fa-meteor', font: 'Font Awesome 5 Free', name: 'meteor', glyph: '' },
	{ class: 'fas fa-microphone', font: 'Font Awesome 5 Free', name: 'microphone', glyph: '' },
	{ class: 'fas fa-moon', font: 'Font Awesome 5 Free', name: 'moon', glyph: '' },
	{ class: 'fas fa-mountain', font: 'Font Awesome 5 Free', name: 'mountain', glyph: '' },
	{ class: 'fas fa-mouse-pointer', font: 'Font Awesome 5 Free', name: 'mouse-pointer', glyph: '' },
	{ class: 'fas fa-otter', font: 'Font Awesome 5 Free', name: 'otter', glyph: '' },
	{ class: 'fas fa-paint-brush', font: 'Font Awesome 5 Free', name: 'paint-brush', glyph: '' },
	{ class: 'fas fa-paper-plane', font: 'Font Awesome 5 Free', name: 'paper-plane', glyph: '' },
	{ class: 'fas fa-pause', font: 'Font Awesome 5 Free', name: 'pause', glyph: '' },
	{ class: 'fas fa-paw', font: 'Font Awesome 5 Free', name: 'paw', glyph: '' },
	{ class: 'fas fa-pen', font: 'Font Awesome 5 Free', name: 'pen', glyph: '' },
	{ class: 'fas fa-pizza-slice', font: 'Font Awesome 5 Free', name: 'pizza-slice', glyph: '' },
	{ class: 'fas fa-plane', font: 'Font Awesome 5 Free', name: 'plane', glyph: '' },
	{ class: 'fas fa-quidditch', font: 'Font Awesome 5 Free', name: 'quidditch', glyph: '' },
	{ class: 'fas fa-radiation', font: 'Font Awesome 5 Free', name: 'radiation', glyph: '' },
	{ class: 'fas fa-recycle', font: 'Font Awesome 5 Free', name: 'recycle', glyph: '' },
	{ class: 'fas fa-seedling', font: 'Font Awesome 5 Free', name: 'seedling', glyph: '' },
	{ class: 'fas fa-shield-alt', font: 'Font Awesome 5 Free', name: 'shield-alt', glyph: '' },
	{ class: 'fas fa-ship', font: 'Font Awesome 5 Free', name: 'ship', glyph: '' },
	{ class: 'fas fa-skull', font: 'Font Awesome 5 Free', name: 'skull', glyph: '' },
	{ class: 'fas fa-snowflake', font: 'Font Awesome 5 Free', name: 'snowflake', glyph: '' },
	{ class: 'fas fa-sort', font: 'Font Awesome 5 Free', name: 'sort', glyph: '' },
	{ class: 'fas fa-sort-down', font: 'Font Awesome 5 Free', name: 'sort-down', glyph: '' },
	{ class: 'fas fa-sort-up', font: 'Font Awesome 5 Free', name: 'sort-up', glyph: '' },
	{ class: 'fas fa-spa', font: 'Font Awesome 5 Free', name: 'spa', glyph: '' },
	{ class: 'fas fa-spider', font: 'Font Awesome 5 Free', name: 'spider', glyph: '' },
	{ class: 'fas fa-star-half-alt', font: 'Font Awesome 5 Free', name: 'star-half-alt', glyph: '' },
	{ class: 'fas fa-star-of-david', font: 'Font Awesome 5 Free', name: 'star-of-david', glyph: '' },
	{ class: 'fas fa-step-backward', font: 'Font Awesome 5 Free', name: 'step-backward', glyph: '' },
	{ class: 'fas fa-step-forward', font: 'Font Awesome 5 Free', name: 'step-forward', glyph: '' },
	{ class: 'fas fa-stroopwafel', font: 'Font Awesome 5 Free', name: 'stroopwafel', glyph: '' },
	{ class: 'fas fa-sun', font: 'Font Awesome 5 Free', name: 'sun', glyph: '' },
	{ class: 'fas fa-tag', font: 'Font Awesome 5 Free', name: 'tag', glyph: '' },
	{ class: 'fas fa-thumbtack', font: 'Font Awesome 5 Free', name: 'thumbtack', glyph: '' },
	{ class: 'fas fa-tint', font: 'Font Awesome 5 Free', name: 'tint', glyph: '' },
	{ class: 'fas fa-tooth', font: 'Font Awesome 5 Free', name: 'tooth', glyph: '' },
	{ class: 'fas fa-tractor', font: 'Font Awesome 5 Free', name: 'tractor', glyph: '' },
	{ class: 'fas fa-trash', font: 'Font Awesome 5 Free', name: 'trash', glyph: '' },
	{ class: 'fas fa-tree', font: 'Font Awesome 5 Free', name: 'tree', glyph: '' },
	{ class: 'fas fa-university', font: 'Font Awesome 5 Free', name: 'university', glyph: '' },
	{ class: 'fas fa-user', font: 'Font Awesome 5 Free', name: 'user', glyph: '' },
	{ class: 'fas fa-venus', font: 'Font Awesome 5 Free', name: 'venus', glyph: '' },
	{ class: 'fas fa-vial', font: 'Font Awesome 5 Free', name: 'vial', glyph: '' },
	{ class: 'fas fa-vihara', font: 'Font Awesome 5 Free', name: 'vihara', glyph: '' },
	{ class: 'fas fa-volleyball-ball', font: 'Font Awesome 5 Free', name: 'volleyball-ball', glyph: '' },
	{ class: 'fas fa-wind', font: 'Font Awesome 5 Free', name: 'wind', glyph: '' },
	{ class: 'fas fa-wine-glass', font: 'Font Awesome 5 Free', name: 'wine-glass', glyph: '' },
	{ class: 'fas fa-wrench', font: 'Font Awesome 5 Free', name: 'wrench', glyph: '' },
	{ class: 'fas fa-yen-sign', font: 'Font Awesome 5 Free', name: 'yen-sign', glyph: '' },
	{ class: 'fas fa-yin-yang', font: 'Font Awesome 5 Free', name: 'yin-yang', glyph: '' },
	{ class: 'fas fa-poop', font: 'Font Awesome 5 Free', name: 'poop', glyph: '' }
];

// sort the array based on unicode, for use in icon select picker lists
export const FAGlyphsArraySorted = FAGlyphsArray.concat().sort((a, b) => a.font === b.font ? a.glyph.charCodeAt(0) - b.glyph.charCodeAt(0) : a.font.localeCompare(b.font));

// and once more as a map, for query access when we only have a name.
export const FAGlyphs = mapReduce(FAGlyphsArray, 'name');

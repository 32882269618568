// Polyfills, before we do anything
import '@/polyfills';

import Vue from 'vue';
import BootstrapVue, { TabsPlugin } from 'bootstrap-vue';
// @ts-ignore
import VueScrollactive from 'vue-scrollactive';
import { Location } from 'vue-router';
import VueGtag from 'vue-gtag';
import { VuePlausible, PlausibleModuleOptions } from 'vue-plausible';
// @ts-ignore
import VueInputAutoWidth from 'vue-input-autowidth';


import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';

import 'vue-slider-component/theme/default.css';

// bootstrap imported through here
import '@/style/index.scss';

import App from '@/App.vue';
import { router, copyLocationProperties } from '@/router';
import vFocusList from '@/directives/vFocusList';
import i18n from '@/i18n';

// Initialize google analytics - if configured
if (dsddconfig.ga != null) {
	Vue.use(VueGtag, {
		config: { 
			id: dsddconfig.ga,
		},
		pageTrackerUseFullPath: true, // also send query parameters
		bootstrap: false // don't start tracking until we obtain user permission - see App.vue
	}, router);
}



// Initialize plausible - if configured
if (dsddconfig.plausible_apihost && dsddconfig.plausible_domain) {
	const plausibleSettings: PlausibleModuleOptions = {
		domain: dsddconfig.plausible_domain,
		trackLocalhost: true,
		apiHost: dsddconfig.plausible_apihost,
		enableAutoPageviews: true,	
	}

	Vue.use(VuePlausible, plausibleSettings, router);
	// no need to trackPageview, it happens by default.
}

Vue.use(VueScrollactive);
Vue.use(BootstrapVue);
Vue.use(TabsPlugin);
Vue.use(vFocusList);
Vue.use(VueInputAutoWidth);
Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV === 'development';
Vue.config.devtools = process.env.NODE_ENV === 'development';

Vue.mixin({
	methods: {
		// tslint:disable-next-line
		t(l: Location, br: boolean): Location { if (br) { debugger; } return copyLocationProperties(l, this.$route); },
		// tslint:disable-next-line
		br() { debugger; },
		log() { console.log.call(this, ...arguments); },
		mark(name?: string) {
			const s = this as any;
			s._mark = s._mark || 0;
			++s._mark;
			if (s._mark % 2 === 0) {
				console.timeEnd(`Rendering ${this.$options.name} ${name}`);
			} else {
				console.time(`Rendering ${this.$options.name} ${name}`);
			}
		},
	},
});

new Vue({
	i18n,
	router,
	render: (h) => h(App),
}).$mount('#app');

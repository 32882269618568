var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-white p-3"},[_c('div',{staticClass:"float-right small text-muted"},[_c('router-link',{attrs:{"title":_vm.$t(`results.view_map_for_concept`, [_vm.concept.display]),"to":_vm.t({
				name: 'map',
				query: {
					filter: null,
					word: [_vm.concept.display.toLowerCase()],
					area: null,
					dir: '0',
					page: null,
					id: _vm.concept.id
				}
			})}},[_vm._v(" "+_vm._s(_vm.$t('common.Concept'))+" "),_c('span',{staticClass:"fas fa-map"})]),(_vm.searchFilters)?[_c('span',{staticClass:"text-muted"},[_vm._v(" | ")]),_c('router-link',{attrs:{"title":_vm.$t(`results.view_map_for_concept_with_filters_help`, [_vm.concept.display]),"to":_vm.t({
					name: 'map',
					query: {
						filter: _vm.searchFilters,
						word: [_vm.concept.display.toLowerCase()],
						area: _vm.searchArea,
						dir: '0',
						page: null,
						id: _vm.concept.id
					}
				})}},[_vm._v(" "+_vm._s(_vm.$t('results.view_map_for_concept_with_filters_label'))+" "),_c('span',{staticClass:"fas fa-map"}),_vm._v(_vm._s(' ')),_c('span',{staticClass:"fas fa-filter"})])]:_vm._e()],2),_c('h3',{staticClass:"text-dark",staticStyle:{"opacity":"1"}},[_vm._v(_vm._s(_vm.concept.display))]),_c('LongList',{attrs:{"list":_vm.keywords,"keyProp":"id"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.$tc('common.keywords', _vm.keywords.length))+" — ")]},proxy:true},{key:"default",fn:function({item: keyword}){return [_c('a',{attrs:{"href":`#${keyword.id}`}},[_vm._v(_vm._s(keyword.keyword_display))])]}}])}),(_vm.concept.concept_definition)?_c('p',{staticClass:"text-muted small definition"},[_c('small',{directives:[{name:"t",rawName:"v-t",value:('results.definition'),expression:"'results.definition'"}],staticClass:"font-italic d-block"}),_c('LongString',{attrs:{"string":_vm.concept.concept_definition,"l":350}})],1):_vm._e(),(!_vm.showDetails)?_c('button',{staticClass:"btn btn-link btn-sm inline",attrs:{"type":"button"},on:{"click":function($event){_vm.showDetails=true}}},[_c('span',{staticClass:"fas fa-book fa-sm"}),_vm._v(" "+_vm._s(_vm.$tc('results.original_definitions', _vm.concept.definitions.length))+" ")]):_vm._l((_vm.concept.definitions),function([dict, definition],i){return _c('p',{key:i,staticClass:"text-muted small definition"},[_c('small',{staticClass:"font-italic d-block"},[_vm._v(_vm._s(dict))]),_c('LongString',{attrs:{"string":definition,"l":350}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }

import Vue from 'vue';

export default Vue.extend({
	props: {
		asc: String,
		desc: String,
		initial: String, // either the value of asc or desc

		value: String
	},
});


import Vue from 'vue';
// object-hash typings don't work with ts 3.9
// @ts-ignore
import objectHash from 'object-hash';

import Results from './Results.vue';
import { SearchScopeChildProps } from '@/scopes/SearchScope.vue';
import { MapOf } from '@/Util';
import Facets from '@/components/Facets.vue';
import MultiInput from '@/components/MultiInput.vue';
import SelectPicker from '@/components/SelectPicker.vue';
import { SearchDirection } from '@/DSDDQuery';

import { facetResults, suggestionResults, PAGE_SIZE } from '@/scopes/SearchScopeGetResults';

// @ts-ignore
import { saveAs } from 'file-saver';

export default Vue.extend({
	components: {
		Results,
		Facets,
		MultiInput,
		SelectPicker
	},
	props: {...SearchScopeChildProps},
	data: () => ({
		modelArea: null as null|L.LatLngBounds,
		modelFacets: {} as MapOf<string[]>,
		facetData: facetResults,

		search_input: {
			set: new Set<string>(),
			input: ''
		},
		modelSearchDirection: SearchDirection.s2d,

		suggestionResults,

		lastRouteWasMap: false,
		PAGE_SIZE,

		downloadMode: 'excel',
		isDownloading: false
	}),
	computed: {
		canSearch(): boolean { return !this.loading && !!(this.searchParams.query.word || this.searchParams.query.filter); },
		searchParams(): any {
			const dir = this.modelSearchDirection;
			let terms: string[]|null = [...this.search_input.set.values(), this.search_input.input].filter(v => !!v);
			terms = terms.length ? terms : null; // clear if nothing entered

			return {
				query: {
					filter: Object.values(this.modelFacets).filter(v => !!v && v.length).length ? this.modelFacets : null,
					word: terms,
					dir,
					page: 1,
					area: this.modelArea
				}
			};
		},
		facetsChanged(): boolean { return ((this.searchArea == null) !== (this.modelArea == null)) || objectHash(this.modelFacets || {}) !== objectHash(this.searchFilters || {}); },
		hasModelFacets(): boolean { return Object.values(this.modelFacets).flat().length > 0; }
	},
	methods: {
		onVisibleConceptChanged(_: Event, currentItem?: HTMLAnchorElement, lastItem?: HTMLAnchorElement) {
			if (currentItem) {
				currentItem.classList.add('active');
			}
			if (lastItem) {
				lastItem.classList.remove('active');
			}
		},
		handleDownload(mode: string) {
			if (!dsddconfig.downloadPath) { // downloadpath configured whether it's enabled or not. Server should default to current host.
				this.$bvToast.toast(this.$t('results.download_not_enabled').toString(), {variant: 'info'});
				return;
			}

			if (this.isDownloading) { return; }
			this.isDownloading = true;

			const loc = `${dsddconfig.downloadServerHost || `${window.location.protocol}//${window.location.host}`}/${dsddconfig.downloadPath}/convert`;

			// TODO
			const path = 'concepts';
			const query = {
				search_in: this.searchDirection === SearchDirection.s2d ? 'concepts' : 'keywords',
				word: this.searchWords,
				...Object
					.entries(this.searchFilters || {})
					.map(([key, value]) => ['filter.'+key, value] as [string, string[]])
					.reduce((acc, [key, value]) => {acc[key] = value; return acc;}, {} as MapOf<string[]>),
				include_data: true,
				include_facets: false,
				rows: Math.pow(2,31)-1,
			};

			let url = `${dsddconfig.downloadSource || dsddconfig.serverHost || `${window.location.protocol}//${window.location.host}`}/${dsddconfig.serverPath}/${path}`;
			const querystring =
				Object
				.entries(query)
				.flatMap(([k, v]) => {
					let vs: string[]|undefined;
					if (Array.isArray(v) && v.length) { vs = v.map(vv => encodeURIComponent(vv)); }
					else if (typeof v === 'string' && v.length) { vs = [encodeURIComponent(v)]; }
					else if (typeof v === 'number') { vs = [encodeURIComponent(v)]; }
					else if (v === true) { vs = ['true']; }

					if (vs != null && vs.length === 0) { return encodeURIComponent(k); }
					else if (vs != null) { return vs.map(ve => `${encodeURIComponent(k)}=${ve}`); }
					return [];
				})
				.join('&');

			if (querystring) { url += '?' + querystring; }



			let extension: string;
			switch (mode) {
				case 'excel': extension = 'xls'; break;
				case 'tsv':
				case 'xml':
				case 'csv': extension = mode; break;
				default: extension = 'txt'; break;
			}
			const fileName = ((this.searchWords && this.searchWords.length) ? this.searchWords[0] : 'download') + '.' + extension;
			const params = {
				url,
				type: 'dsdd-json-to-tsv',
				outputtype: mode,
				'url.accept': 'application/json',
				filename: fileName
			};
			const downloadUrl = loc + '?' + Object.entries(params).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&');

			const mime = {type: ''};
			switch (mode) {
				case 'excel': mime.type = 'application/vnd.ms-excel'; break;
				case 'tsv':
				case 'csv': mime.type = 'text/csv;charset=utf-8'; break;
				case 'xml': mime.type = 'text/xml;charset=utf8'; break;
				default: mime.type = 'text/plain;charset=utf-8'; break;
			}

			fetch(downloadUrl)
			.then(r => {
				return new Promise<typeof r>((resolve, reject) => {
					if (!r.ok) {
						if (r.status === 404) { reject(new Error(r.statusText)); }
						else { r.text().then(t => {console.log(t); reject(new Error(t)); }); }
					} else {
						resolve(r);
					}
				});
			})
			.then(r => r.blob())
			.then(r => saveAs(r, fileName))
			.catch(e => {
				const translation = this.$t('results.download_error', [e.message]);
				this.$bvToast.toast(translation.toString(), {variant: 'danger'});
				return;
			})
			.finally(() => this.isDownloading = false);

			Vue.$plausible?.trackEvent('export', { props: { what: mode }});
		},
		cssescape(s: string): string {
			return CSS.escape(s);
		}
	},
	watch: {
		searchWords: {
			immediate: true,
			handler() {
				if (!this.searchWords || this.searchWords.length === 0) {
					this.search_input = { set: new Set(), input: '' };
					return;
				}
				this.search_input = {
					set: new Set(this.searchWords.slice(0, this.searchWords.length - 1)),
					input: this.searchWords[this.searchWords.length - 1]
				};
			}
		},
		search_input: {
			immediate: true,
			handler() {
				this.suggestionResults.next({
					term: this.search_input.input,
					dir: this.modelSearchDirection
				});
			}
		},
		modelSearchDirection() {
			this.suggestionResults.next({
				term: this.search_input.input,
				dir: this.modelSearchDirection
			});
		},
		modelFacets() { this.facetData.next({filters: this.modelFacets, dir: this.searchDirection, terms: this.searchWords}); },
		searchFilters: {
			immediate: true,
			handler() { this.modelFacets = this.searchFilters as any || {}; }
		},
		searchArea: {
			immediate: true,
			handler() { this.modelArea = this.searchArea || null; }
		},
		resultKeywords() {
			Vue.nextTick(() => {
				if (this.hasResults) {
					if (location.hash) {
						try {
							const id = location.hash.substring(1);
							document.getElementById(id)!.scrollIntoView({behavior: 'smooth'});
						} catch (e) { /* ignore */ }
					} else {
						(this.$refs.results as HTMLElement).scrollIntoView({behavior: 'smooth'});
					}
				}
			});
		}
	},
	beforeRouteEnter(_cur, prev, next) {
		next(advancedFormView => advancedFormView.$data.lastRouteWasMap = prev != null && prev.name === 'map' && prev.query && prev.query.word);
	}
});


import Vue from 'vue';

export default Vue.extend({
	props: {
		list: { type: Array as () => any[], default: () => [] as any[], },
		initial: { type: Number, default: 10, },
		increment: { type: Number, default: Number.MAX_SAFE_INTEGER },
		inline: Boolean,
		keyProp: String
	},
	data: () => ({ show: 0, }),
	computed: {
		truncate(): boolean { return this.show < this.list.length; },
		// Subtract one from the truncated list, as the (more) button takes up the last spot
		display(): any[] { return this.truncate ? this.list.slice(0, this.show - 1) : this.list; },
	},
	created() { this.show = this.initial; }
});



import Vue from 'vue';
import tinycolor from 'tinycolor2';

import { mapReduce, MapOf, FAGlyphsArray } from '@/Util';
import { ResultKeyword, ResultVisual } from '@/scopes/SearchScopeTypes';

export default Vue.extend({
	props: {
		index: Number,
		group: String,
		data: Array as () => Array<ResultKeyword&ResultVisual&(typeof FAGlyphsArray)[number]>,
		hideHeader: Boolean
	},
	computed: {
		id(): string { return (this.group || 'unknown_group').replace(/[^\w]/g, '_') + '_id'; },
		isVisible(): boolean { return this.data.some(v => v.isVisible); },
		isHighlighted(): boolean { return this.data.some(v => v.isHighlighted); },
		keywordSizes(): MapOf<number> {
			return mapReduce(this.data, 'id', kw => Math.min(Math.max(8	, Math.log(kw.count) * 3), 20));
		},
		cssColors(): string {
			const colorsInGroup = Array.from(new Set(this.data.map(d => d.color))).map(c => tinycolor(c)).sort((a, b) => a.toHsv().h - b.toHsv().h);
			if (colorsInGroup.length === 1) {
				colorsInGroup.push(colorsInGroup[0]);
			}
			return `linear-gradient(to bottom, ${colorsInGroup.map(c => c.toHex8String()).join(',')}) 100% 0`;
		},
	},
});
